import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';

export interface IRefAdtEkip extends IBaseModel {
  realId?: any;
  adiTr?: string;
  adiEn?: string;
  soyadi?: string;
  ekip?: IRefAdtEkip;
}

export interface IRefAdtEkipParams extends IQueryParams {
  entity?: IRefAdtEkip;
}

export const defaultValue: Readonly<IRefAdtEkip> = {};
