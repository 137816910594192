import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IKrizEkKisi, defaultValue } from 'app/shared/model/kriz-ek-kisi.model';

const initialState: EntityState<IKrizEkKisi> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/kriz-ek-kisis';

interface IKrizEkKisiQueryParams extends IQueryParams {
  krizId: string | number;
  filterType?: string | number;
  kisiId?: number[];
}

// Actions
export const getTotalEntities = createAsyncThunk(
  'krizEkKisis/fetch_entity_total_list',
  async ({ size, sort, query, krizId, filterType }: IKrizEkKisiQueryParams) => {
    const queryParams = new URLSearchParams();
    if (sort) {
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
      queryParams.set('filterType.equals', filterType.toString());
    }
    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }
    if (krizId) {
      queryParams.set('krizId.equals', krizId.toString());
    }
    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `${apiUrl}/export${queryString ? `?${queryString}` : ''}`;

    return axios.get<IKrizEkKisi[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getEntities = createAsyncThunk(
  'krizEkKisis/fetch_entity_kriz_list',
  async ({ page, size, sort, query, krizId }: IKrizEkKisiQueryParams) => {
    const queryParams = new URLSearchParams();

    if (krizId) {
      queryParams.set('krizId.equals', krizId.toString());
    }
    if (sort) {
      queryParams.set('page', page.toString());
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
    }

    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }
    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `${apiUrl}${queryString ? `?${queryString}` : ''}`;

    return axios.get<IKrizEkKisi[]>(requestUrl);
  }
);

export const getKisiEntities = createAsyncThunk(
  'krizEkKisis/fetch_entity_kriz_list',
  async ({ krizId, filterType, page, size, sort, query }: IKrizEkKisiQueryParams) => {
    const queryParams = new URLSearchParams();
    if (sort) {
      queryParams.set('page', page.toString());
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
      queryParams.set('krizId.equals', krizId.toString());
      queryParams.set('filterType.equals', filterType.toString());
    }

    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }
    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `${apiUrl}${queryString ? `?${queryString}` : ''}`;

    return axios.get<IKrizEkKisi[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  'krizEkKisis/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IKrizEkKisi>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'krizEkKisis/create_entity',
  async (entity: IKrizEkKisi, thunkAPI) => {
    const result = await axios.post<IKrizEkKisi>(apiUrl, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'krizEkKisis/update_entity',
  async (entity: IKrizEkKisi, thunkAPI) => {
    const result = await axios.patch<IKrizEkKisi>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'krizEkKisis/partial_update_entity',
  async (entity: IKrizEkKisi, thunkAPI) => {
    const result = await axios.patch<IKrizEkKisi>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({ krizId: entity.kriz.id, size: 2000, page: 0, sort: 'id,desc' }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'krizEkKisis/delete_entity',
  async (entity: IKrizEkKisi, thunkAPI) => {
    const requestUrl = `${apiUrl}/${entity.id}`;
    const result = await axios.delete<IKrizEkKisi>(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const KrizEkKisiSlice = createEntitySlice({
  name: 'krizEkKisis',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getTotalEntities), (state, action) => {
        return {
          ...state,
          totalEntities: action.payload.data,
          loading: false,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getEntities, getKisiEntities), (state, action) => {
        return {
          ...state,
          entities: action.payload.data,
          loading: false,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getTotalEntities, getKisiEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = KrizEkKisiSlice.actions;

// Reducer
export default KrizEkKisiSlice.reducer;
