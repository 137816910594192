import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Input } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { getEntities, getTotalEntities, updateEntity } from './ref-islem-kodu.reducer';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { CustomSaveEntityConfirm } from 'app/shared/util/confirm-utils';
import { RefIslemKoduUpdate } from './ref-islem-kodu-update';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import CustomPagination from 'app/component/CustomPagination';
import { IRefIslemKodu } from 'app/shared/model/ref-islem-kodu.model';
import GrantedButton from 'app/component/GrantedButton';
import { SERVER_URL } from 'app/config/constants';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import SortIcon from 'app/component/SortIcon';

export const RefIslemKodu = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();
  const refIslemKoduList = useAppSelector(state => state.refIslemKodu.entities);
  const loading = useAppSelector(state => state.refIslemKodu.loading);
  const updating = useAppSelector(state => state.refIslemKodu.updating);
  const totalItem = useAppSelector(state => state.refIslemKodu.totalItems);
  const [isIslemKoduUpdateModal, setIsIslemKoduUpdateModal] = useState(false);
  const [selectedIslemKodu, setSelectedIslemKodu] = useState(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [search, setSearch] = useState('');
  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const ExcelSampleData = [
    { key: 'adiTr', titleContentKey: 'global.languageGlobal.adiTr' },
    { key: 'adiEn', titleContentKey: 'global.languageGlobal.adiEn' },
  ];
  const getAllEntities = () => {
    dispatch(getEntities(pageObject));
  };

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject({ ...pageObject, page: 0, query: `globalFilter=${search}` });
    } else if (search.length === 0) {
      setPageObject({ ...pageObject, page: 0, query: '' });
    }
  }, [search]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleCloseUpdateForm = () => {
    if (isIslemKoduUpdateModal) {
      setIsIslemKoduUpdateModal(false);
    }
    setSelectedIslemKodu(null);
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveNewIslemKodu = async e => {
    e.preventDefault();

    setIsIslemKoduUpdateModal(false);

    const isSave = await showSaveConfirmModal();
    if (isSave) {
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const refIslemKoduEntity = {
        ...selectedIslemKodu,
        ...formDataObject,
      };

      if (selectedIslemKodu !== null) {
        await dispatch(updateEntity({ ...pageObject, entity: refIslemKoduEntity }));
      }

      handleCloseUpdateForm();
    } else {
      setIsIslemKoduUpdateModal(true);
    }
  };

  const fetchTotalIslemKoduData = async (): Promise<IRefIslemKodu[]> => {
    try {
      const response = (await dispatch(getTotalEntities({ ...pageObject, size: totalItem }))) as { payload: { data: IRefIslemKodu[] } };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const RefIslemKoduUpdateModal = () => {
    return (
      <RefIslemKoduUpdate
        saveNewIslemKodu={saveNewIslemKodu}
        handleCloseForm={handleCloseUpdateForm}
        formValues={selectedIslemKodu}
        isOpen={isIslemKoduUpdateModal}
      ></RefIslemKoduUpdate>
    );
  };

  return (
    <div>
      {(loading || updating) && <LoadingSpinner />}
      <CustomSaveEntityConfirm entity={selectedIslemKodu} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />
      {isIslemKoduUpdateModal && RefIslemKoduUpdateModal()}

      <div style={{ display: 'flex', gap: 8, textAlign: 'center' }}>
        <p className="icon-text">
          <a className="link-a-text" href={`${SERVER_URL}`}>
            <img width={13} height={13} src={`${SERVER_URL}/content/images/icon/faHouse.svg`} />
          </a>
        </p>
        <p className="separator"> / </p>
        <p className="link-text">
          {' '}
          <Translate contentKey="tk24AdysApp.refIslemKodu.home.title" />{' '}
        </p>
      </div>

      <h2 id="ref-islem-kodu-heading" data-cy="RefIslemKoduHeading" className="title-frame">
        <a className="link-a-text" id={'goBack'} onClick={() => props.history.goBack()}>
          <img width={27.5} height={22.5} src={`${SERVER_URL}/content/images/icon/faArrowLeft.svg`} />{' '}
        </a>
        <p className="title-text">
          <Translate contentKey="tk24AdysApp.refIslemKodu.home.mainTitle" />
        </p>
      </h2>

      <div className="content-area">
        <div className="search-frame">
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={translate('entity.action.search')}
              className="search-input"
            />
          </div>
          <div className="create-area">
            <ExcelDownloadButton
              excelSampleData={ExcelSampleData}
              grantedAuthorites={['ref_islem_kodu_export']}
              fetchData={fetchTotalIslemKoduData}
              className={'download-button'}
              exportName={translate('tk24AdysApp.refIslemKodu.home.title')}
            />
          </div>
        </div>
        <div className="table-responsive" style={{ marginTop: 5 }}>
          <TableContainer className="content-table">
            {refIslemKoduList && refIslemKoduList.length > 0 ? (
              <Table aria-label="a dense table">
                <TableHead className="content-table-title-area">
                  <TableRow className="table-head-row">
                    <TableCell className="table-title-cell hand" align="left" onClick={sort('adiTr')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="global.languageGlobal.adiTrTitle">Adi Tr</Translate>&nbsp;
                        <SortIcon column="adiTr" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="table-title-cell hand" align="left" onClick={sort('adiEn')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="global.languageGlobal.adiEnTitle">Adi En</Translate>&nbsp;
                        <SortIcon column="adiEn" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="sticky-right-header"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {refIslemKoduList.map(refIslemKodu => (
                    <TableRow key={refIslemKodu.id} className="table-cell-row">
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {refIslemKodu.adiTr}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {refIslemKodu.adiEn}
                      </TableCell>
                      <TableCell className="sticky-right" align="right">
                        <div className="btn-group flex-btn-group-container">
                          <GrantedButton
                            grantedAuthorites={['ref_islem_kodu_edit']}
                            className="edit-button"
                            style={{ marginLeft: 2 }}
                            onClick={() => {
                              setSelectedIslemKodu(refIslemKodu);
                              setIsIslemKoduUpdateModal(true);
                            }}
                            size="sm"
                            data-cy="entityEditButton"
                          >
                            <Tooltip title={<Translate contentKey="entity.action.edit">Edit</Translate>}>
                              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                            </Tooltip>
                            <span className="show-on-lg">
                              <Translate contentKey="entity.action.edit">Edit</Translate>
                            </span>
                          </GrantedButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              !loading &&
              !updating && (
                <div className="alert alert-warning">
                  <Translate contentKey="tk24AdysApp.refIslemKodu.home.notFound">No Ref Duyuru Tipis found</Translate>
                </div>
              )
            )}
          </TableContainer>
        </div>
        {totalItem ? (
          <div className={refIslemKoduList && refIslemKoduList.length > 0 ? '' : 'd-none'}>
            <CustomPagination
              currentPage={pageObject.page + 1}
              currentPageSize={pageObject.size}
              totalItems={totalItem}
              handlePageChange={handlePageChange}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default RefIslemKodu;
