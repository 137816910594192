import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { KeyboardArrowDownOutlined } from '@mui/icons-material';
import { Translate } from 'app/component/jhipster';
import PersonelImage from 'app/component/PersonelImage';

const circleStyle = {
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  backgroundColor: '#49586A',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  marginRight: '10px',
};

export const NavDropdown = props => (
  <UncontrolledDropdown nav inNavbar id={props.id} data-cy={props['data-cy']}>
    <DropdownToggle nav className="d-flex align-items-center">
      {props.isAuthenticated ? (
        <>
          <PersonelImage contentkey={props.kullanici} designKey={true} />

          <span style={{ fontSize: '14px' }}>{props.lastName ? `${props.firstName} ${props.lastName}` : props.firstName}</span>
        </>
      ) : (
        <>
          <div style={circleStyle}>
            <span style={{ fontSize: '14px' }}>
              {' '}
              <Translate contentKey="global.menu.account.mainHarf"></Translate>
            </span>
          </div>
          <span>
            <Translate contentKey="global.menu.account.main">Hesap</Translate>
          </span>
        </>
      )}

      <KeyboardArrowDownOutlined />
    </DropdownToggle>
    <DropdownMenu right style={props.style}>
      {props.children}
    </DropdownMenu>
  </UncontrolledDropdown>
);
