import { IKrizOzetRaporu } from 'app/shared/model/kriz-ozet-raporu.model';
import React, { useState } from 'react';
import { createEntity, updateEntity } from './kriz-ozet-rapor.reducer';
import { CustomSaveEntityConfirm } from 'app/shared/util/confirm-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { FormItem } from 'app/component/FormItem';
import { Translate, translate } from 'app/component/jhipster';
import { Button, Form, ModalFooter, ModalBody, Modal, ModalHeader } from 'reactstrap';

interface KrizOzetRaporUpdateProp {
  krizId: string;
  krizOzetRapor?: IKrizOzetRaporu;
  isOpen: boolean;
  handleCloseModal: () => void;
}

const KrizOzetRaporUpdate: React.FC<KrizOzetRaporUpdateProp> = ({ krizId, krizOzetRapor, isOpen, handleCloseModal }) => {
  const dispatch = useAppDispatch();
  const kriz = useAppSelector(state => state.kriz.entity);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveEntity = async values => {
    values.preventDefault();
    const isSave = await showSaveConfirmModal();
    if (isSave) {
      const formDataObject = {};
      for (const field of values.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const entity = {
        ...krizOzetRapor,
        ...formDataObject,
        kriz,
      };
      if (krizOzetRapor) {
        await dispatch(updateEntity(entity));
        handleCloseModal();
      } else {
        await dispatch(createEntity(entity));
        handleCloseModal();
      }
    }
  };

  return (
    <>
      <CustomSaveEntityConfirm entity={krizOzetRapor} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

      <Modal isOpen={isOpen} toggle={handleCloseModal} style={{ maxWidth: '1200px' }} centered>
        <ModalHeader toggle={handleCloseModal}>
          {krizOzetRapor ? (
            <Translate contentKey="tk24AdysApp.krizOzetRaporu.home.editLabel" />
          ) : (
            <Translate contentKey="tk24AdysApp.krizOzetRaporu.home.createLabel" />
          )}
        </ModalHeader>
        <Form onSubmit={saveEntity}>
          <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
            <FormItem
              name="hasarBilgisiTr"
              id="hasarBilgisiTr"
              label={translate('tk24AdysApp.krizOzetRaporu..hasarBilgisiTr')}
              defaultValue={krizOzetRapor && krizOzetRapor.hasarBilgisiTr}
              type="textarea"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            />
            <FormItem
              name="hasarBilgisiEn"
              id="hasarBilgisiEn"
              label={translate('tk24AdysApp.krizOzetRaporu..hasarBilgisiEn')}
              defaultValue={krizOzetRapor && krizOzetRapor.hasarBilgisiEn}
              type="textarea"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            />

            <p>
              <strong>
                <Translate contentKey="tk24AdysApp.krizOzetRaporu.depIletisim" />
              </strong>
              <hr style={{ marginBottom: 0, marginTop: 0, borderBottom: '1px solid #212529' }} />
            </p>
            <FormItem
              name="depIletisimAdSoyad"
              id="depIletisimAdSoyad"
              label={translate('tk24AdysApp.krizOzetRaporu.depIletisimAdSoyad')}
              defaultValue={krizOzetRapor && krizOzetRapor.depIletisimAdSoyad}
              type="text"
            />
            <FormItem
              name="depIletisimTelNo"
              id="depIletisimTelNo"
              label={translate('tk24AdysApp.krizOzetRaporu.depIletisimTelNo')}
              defaultValue={krizOzetRapor && krizOzetRapor.depIletisimTelNo}
              type="text"
            />
            <p>
              <strong>
                <Translate contentKey="tk24AdysApp.krizOzetRaporu.enYakinIstIletisim" />
              </strong>
              <hr style={{ marginBottom: 0, marginTop: 0, borderBottom: '1px solid #212529' }} />
            </p>
            <FormItem
              name="enYakinIstIletisimAdSoyad"
              id="enYakinIstIletisimAdSoyad"
              label={translate('tk24AdysApp.krizOzetRaporu.enYakinIstIletisimAdSoyad')}
              defaultValue={krizOzetRapor && krizOzetRapor.enYakinIstIletisimAdSoyad}
              type="text"
            />
            <FormItem
              name="enYakinIstIletisimTelNo"
              id="enYakinIstIletisimTelNo"
              label={translate('tk24AdysApp.krizOzetRaporu.enYakinIstIletisimTelNo')}
              defaultValue={krizOzetRapor && krizOzetRapor.enYakinIstIletisimTelNo}
              type="text"
            />
            <p>
              <strong>
                <Translate contentKey="tk24AdysApp.krizOzetRaporu.arrIletisim" />
              </strong>
              <hr style={{ marginBottom: 0, marginTop: 0, borderBottom: '1px solid #212529' }} />
            </p>
            <FormItem
              name="arrIletisimAdSoyad"
              id="arrIletisimAdSoyad"
              label={translate('tk24AdysApp.krizOzetRaporu.arrIletisimAdSoyad')}
              defaultValue={krizOzetRapor && krizOzetRapor.arrIletisimAdSoyad}
              type="text"
            />
            <FormItem
              name="arrIletisimTelNo"
              id="arrIletisimTelNo"
              label={translate('tk24AdysApp.krizOzetRaporu.arrIletisimTelNo')}
              defaultValue={krizOzetRapor && krizOzetRapor.arrIletisimTelNo}
              type="text"
            />
          </ModalBody>
          <ModalFooter className="modal-footer">
            <Button aria-label="cancel" className="cancel-button" onClick={handleCloseModal}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button
              aria-label="kaydet"
              className="save-button"
              id="jhi-confirm-delete-refAdtForm"
              type="submit"
              data-cy="entityConfirmButton"
            >
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default KrizOzetRaporUpdate;
