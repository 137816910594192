import React from 'react';
import { isObjectEmpty } from 'app/shared/util/confirm-utils';
import { hasAnyAuthority } from 'app/config/store';
import { MenuItem } from '@mui/material';
import { IGrantedMenuItemProps, IGrantedMenuItemState } from 'app/shared/model/granted-menu-item-model';

export default class GrantedMenuItem extends React.Component<IGrantedMenuItemProps, IGrantedMenuItemState> {
  constructor(props: IGrantedMenuItemProps) {
    super(props);

    this.state = {
      nonMatchingRecord: null,
    };
  }

  componentDidMount() {
    const { comparison } = this.props;
    if (comparison) {
      this.setState({ nonMatchingRecord: comparison.find(item => item.value !== item.checkValue) });
    }
  }

  render() {
    const { nonMatchingRecord } = this.state;
    const { grantedAuthorites, children } = this.props;

    return hasAnyAuthority(grantedAuthorites) ? (
      !isObjectEmpty(nonMatchingRecord) ? (
        <>
          <MenuItem disabled={true} {...this.props}>
            {children}
          </MenuItem>
        </>
      ) : (
        <MenuItem {...this.props}>{children}</MenuItem>
      )
    ) : null;
  }
}
