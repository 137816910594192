import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { Storage, TextFormat, Translate, translate } from 'app/component/jhipster';
import { APP_DATETIME_FORMAT, APP_TIMESTAMP_FORMAT_A, SERVER_URL } from 'app/config/constants';
import InfoTable from '../infoTable';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { KrizMain } from 'app/component/KrizMain';
import { getEntity as getKrizEntity } from 'app/entities/kriz/kriz.reducer';
import GrantedDisableButton from 'app/GrantedDisableButton';
import { isNullOrEmpty } from 'app/shared/util/confirm-utils';
import {
  getEntityBySicilId,
  getEntities as getKatilimciEntities,
  getTotalEntities,
} from 'app/entities/kriz-katilimci/kriz-katilimci.reducer';
import axios from 'axios';
import { setAuthenticationGrants } from 'app/shared/reducers/authentication';
import { arrayUnique } from 'app/shared/util/util';
import { isNull } from 'lodash';
import { toast } from 'react-toastify';
import { IKrizKartDetail, IKrizKartList } from 'app/shared/model/kriz.model';
import { Accordion, AccordionDetails, AccordionSummary } from 'app/component/Accordion';
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { closeDrawer, toggleDrawer } from '../kriz-chat/kriz-chat-slice.reducer';
import KrizChatNew from '../kriz-chat/kriz-chat-new';
import { ReferansEnum } from 'app/constants/ReferansEnum';

const KrizDetay = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const isDrawer = useAppSelector(state => state.krizChat.isDrawer);
  const [dashboardLoading, setDashboardLoading] = useState(false);
  const [krizId, setKrizId] = useState(Storage.session.get('kriz'));
  const sicilNo = useAppSelector(state => state.authentication.account.sicilNo);
  const [loadingKrizDetail, setLoadingKrizDetail] = useState(false);
  const [krizKart, setKrizKart] = useState<IKrizKartList>(null);
  const [krizKartDetail, setKrizKartDetail] = useState<IKrizKartDetail>(null);
  const krizEntity = useAppSelector(state => state.kriz.entity);

  const handleDrawerOpen = () => {
    dispatch(toggleDrawer());
  };

  const handleDrawerClose = () => {
    dispatch(closeDrawer());
  };

  const onLoad = async () => {
    setLoadingKrizDetail(true);
    try {
      const resp = await axios.get(`api/krizs/kart-list?guid.equals=${krizId}`);
      setKrizKart(resp.data[0]);
    } finally {
      setLoadingKrizDetail(false);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    if (krizId) {
      dispatch(getKrizEntity(krizId)).then(() => {
        getGrant();
      });
      dispatch(getEntityBySicilId({ krizId, sicilId: sicilNo }));
      dispatch(getKatilimciEntities({ size: 100, page: 0, sort: 'adi,asc', query: `krizId.equals=${krizId}` }));
      dispatch(getTotalEntities({ size: 9999, sort: 'adi,asc', query: `krizId.equals=${krizId}` }));
    }
  }, [krizId]);

  const getGrant = async () => {
    try {
      setDashboardLoading(true);
      const entryCheck = (await axios.get(`api/kriz-katilimcis/crisis-entry-check/${krizId}`)).data;
      Storage.session.set('isAdmin', entryCheck?.isAdmin);

      if (entryCheck.isKrizKatilimci || entryCheck.canAccess) {
        if (!isNull(entryCheck.authorities)) {
          dispatch(setAuthenticationGrants(arrayUnique(entryCheck.authorities)));
        } else {
          dispatch(setAuthenticationGrants([]));
        }
        setDashboardLoading(false);
      } else if (!entryCheck.canAccess) {
        toast.error(translate('error.noAccessPermission'));
        setDashboardLoading(false);
      }
    } catch (error) {
      toast.error(translate(`${error.response.data.message}`));
      setDashboardLoading(false);
    }
  };

  const [expanded, setExpanded] = useState(false);

  const handleChangeAccordion = () => async (event: React.SyntheticEvent, newExpanded: boolean) => {
    if (!expanded) {
      setLoadingKrizDetail(true);
      await axios.get(`api/krizs/kart-detay/${krizId}`).then(resp => {
        setKrizKartDetail(resp.data);
      });
      setLoadingKrizDetail(false);
    }

    setExpanded(!expanded);
  };

  const getName = name => {
    if (!name) return '';

    const [fullName] = name.split('/').map(part => part.trim());

    if (!fullName) return '';

    const nameParts = fullName.split(' ').filter(Boolean);

    let initials = '';

    if (nameParts.length === 1) {
      initials = nameParts[0][0];
    } else if (nameParts.length >= 2) {
      const firstNameInitial = nameParts[0][0];
      const lastNameInitial = nameParts[nameParts.length - 1][0];
      initials = firstNameInitial + lastNameInitial;
    }

    return initials.toUpperCase();
  };

  const NameCircle = ({ name }) => {
    if (!isNullOrEmpty(name)) {
      const initials = getName(name);
      return (
        <div
          style={{
            height: '24px',
            width: '24px',
            backgroundColor: '#79889A',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            fontSize: '12px',
            marginRight: '10px',
            flexShrink: 0,
            fontFamily: 'Geologica',
          }}
        >
          {initials}
        </div>
      );
    }

    return <></>;
  };

  const KrizKart = () => {
    return (
      <Accordion
        expanded={expanded}
        onChange={handleChangeAccordion()}
        sx={{
          borderLeft: `4px solid ${krizKart.krizSeviyeCssBorderColor}`,
          borderRight: `4px solid ${krizKart.krizSeviyeCssBorderColor}`,
          borderRadius: 8,
          marginBottom: 3,
        }}
        className="acordionRadius mt-3"
        style={{ marginBottom: 250 }}
      >
        <AccordionSummary
          aria-controls="panel-content"
          id="panel-header"
          className="accordionTitle"
          sx={{
            borderLeft: `0px solid ${krizKart.krizSeviyeCssBorderColor}`,
            borderRight: `0px solid ${krizKart.krizSeviyeCssBorderColor}`,
            borderRadius: expanded ? '5px 5px 0px 0px' : '5px',
          }}
        >
          <Row style={{ width: '100%' }} className="homeRow">
            <Col xs={2} style={{ padding: '10px', display: 'flex', flexDirection: 'row', gap: '5px' }}>
              <div className="homeMainKey">
                <Translate contentKey="tk24AdysApp.kriz.olayTuru" />:
              </div>
              <div className="homeMainValue">{krizKart.acilDurumTuruAdi}</div>
            </Col>
            <Col xs={3} style={{ padding: '10px', display: 'flex', flexDirection: 'row', gap: '5px' }}>
              <div className="homeMainKey">
                <Translate contentKey="tk24AdysApp.kriz.acilDurumTuruTitle" />:
              </div>
              <div className="homeMainValue">{krizKart.krizTuruAdi}</div>
            </Col>
            <Col xs={4} style={{ padding: '10px', display: 'flex', flexDirection: 'row', gap: '5px' }}>
              <div className="homeMainKey">
                <Translate contentKey="tk24AdysApp.kriz.gerceklesenYerTitle" />:
              </div>
              <div className="homeMainValue">
                {krizKart.havalimaniAdiList.length > 0
                  ? krizKart.havalimaniAdiList.join(' / ')
                  : krizKart.krizYeriKonumX && krizKart.krizYeriKonumY
                  ? `${krizKart.krizYeriKonumX}, ${krizKart.krizYeriKonumY}`
                  : translate('entity.action.konumYok')}
              </div>
            </Col>
            <Col xs={3} style={{ padding: '10px', display: 'flex', flexDirection: 'row', gap: '5px' }}>
              <div className="homeMainKey">
                <Translate contentKey="tk24AdysApp.kriz.krizOlusmaZamaniTitle" />:
              </div>
              <div className="homeMainValue">
                {krizKart.krizOlusmaZamani ? <TextFormat type="date" value={krizKart.krizOlusmaZamani} format={APP_DATETIME_FORMAT} /> : ''}
              </div>
            </Col>
            <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }} className="arrowHomeKriz">
              {expanded === true ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
            </Col>
          </Row>
        </AccordionSummary>
        <AccordionDetails style={{ padding: '5px 16px', marginTop: '10px', borderTop: '1px solid rgba(0, 0, 0, .125)' }}>
          <>
            {krizKartDetail && (
              <>
                <div className="mt-3" style={{ margin: '15px' }}>
                  <Row>
                    <Col md={6}>
                      <Row>
                        <Col md={6}>
                          <p className="homeCardKey">
                            <Translate contentKey="tk24AdysApp.kriz.olayTuru" />:
                          </p>
                        </Col>
                        <Col md={6}>
                          <p className="homeCardValue">{krizKart.acilDurumTuruAdi}</p>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={6}>
                          <p className="homeCardKey">
                            <Translate contentKey="tk24AdysApp.kriz.krizTuru" />:
                          </p>
                        </Col>
                        <Col md={6}>
                          <p className="homeCardValue">{krizKart.krizTuruAdi}</p>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={6}>
                          <p className="homeCardKey">
                            <Translate contentKey="tk24AdysApp.kriz.krizSeviye" />:
                          </p>
                        </Col>
                        <Col md={6}>
                          <p
                            className="homeCardValue"
                            style={{
                              backgroundColor: krizEntity?.krizSeviye?.cssBorderColor,
                              color: krizEntity?.krizSeviye?.cssFontColor,
                              borderRadius: 8,
                              width: 80,
                              textAlign: 'center',
                            }}
                          >
                            {krizKartDetail.krizSeviyeAdi}
                          </p>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={6}>
                          <p className="homeCardKey">
                            <Translate contentKey="tk24AdysApp.kriz.krizOlusmaZamani" />:
                          </p>
                        </Col>
                        <Col md={6}>
                          <p className="homeCardValue">
                            {krizKart.krizOlusmaZamani ? (
                              <TextFormat type="date" value={krizKart.krizOlusmaZamani} format={APP_TIMESTAMP_FORMAT_A} />
                            ) : (
                              ''
                            )}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <p className="homeCardKey">
                            <Translate contentKey="tk24AdysApp.kriz.konumBilgileri" />:
                          </p>
                        </Col>
                        <Col md={6}>
                          <p className="homeCardValue">{krizKartDetail?.krizYeriAdi} </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <p className="homeCardKey">
                            <Translate contentKey="tk24AdysApp.kriz.krizBildiren" />:
                          </p>
                        </Col>
                        <Col md={6} style={{ display: 'flex', alignItems: 'center', marginBottom: '11px' }}>
                          <NameCircle name={krizEntity?.kriziBildiren} />
                          <p className="homeCardValue" style={{ margin: '0px' }}>
                            {krizKartDetail.kriziBildiren}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <p className="homeCardKey">
                            <Translate contentKey="tk24AdysApp.kriz.krizBildirilmeZamani" />:
                          </p>
                        </Col>
                        <Col md={6}>
                          <p className="homeCardValue">
                            {krizKartDetail.krizinBildirilmeZamani ? (
                              <TextFormat type="date" value={krizKartDetail.krizinBildirilmeZamani} format={APP_TIMESTAMP_FORMAT_A} />
                            ) : (
                              ''
                            )}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col md={6}>
                          <p className="homeCardKey">
                            <Translate contentKey="tk24AdysApp.kriz.toplantiTipi" />:
                          </p>
                        </Col>
                        <Col md={6}>
                          <p className="homeCardValue">{krizKartDetail.toplantiTipiAdi}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <p className="homeCardKey">
                            <Translate contentKey="tk24AdysApp.kriz.krizDurumu" />:
                          </p>
                        </Col>
                        <Col md={6}>
                          <div
                            className={
                              krizEntity?.krizDurumu?.id === ReferansEnum.KrizDurumu.Aktif ? 'homeCardActiveValue' : 'homeCardValue'
                            }
                          >
                            <p style={krizEntity?.krizDurumu?.id === ReferansEnum.KrizDurumu.Aktif ? { margin: 5 } : {}}>
                              {krizKartDetail.krizDurumuAdi}
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <p className="homeCardKey">
                            <Translate contentKey="tk24AdysApp.kriz.kymDurumu" />:
                          </p>
                        </Col>
                        <Col md={6}>
                          <div
                            className={
                              krizEntity?.kymKrizDurumu?.id === ReferansEnum.KrizDurumu.Aktif ? 'homeCardActiveValue' : 'homeCardValue'
                            }
                          >
                            <p style={krizEntity?.kymKrizDurumu?.id === ReferansEnum.KrizDurumu.Aktif ? { margin: 5 } : {}}>
                              {krizKartDetail.kymDurumuAdi}
                            </p>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={6}>
                          <p className="homeCardKey">
                            <Translate contentKey="tk24AdysApp.kriz.adtDurumu" />:
                          </p>
                        </Col>
                        <Col md={6}>
                          <div
                            className={
                              krizEntity?.adtKrizDurumu?.id === ReferansEnum.KrizDurumu.Aktif ? 'homeCardActiveValue' : 'homeCardValue'
                            }
                          >
                            <p style={krizEntity?.adtKrizDurumu?.id === ReferansEnum.KrizDurumu.Aktif ? { margin: 5 } : {}}>
                              {krizKartDetail.adtDurumuAdi}
                            </p>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={6}>
                          <p className="homeCardKey">
                            <Translate contentKey="tk24AdysApp.kriz.istasyonDurumu" />:
                          </p>
                        </Col>
                        <Col md={6}>
                          <div
                            className={
                              krizEntity?.istasyonKrizDurumuId?.id === ReferansEnum.KrizDurumu.Aktif
                                ? 'homeCardActiveValue'
                                : 'homeCardValue'
                            }
                          >
                            <p style={krizEntity?.istasyonKrizDurumuId?.id === ReferansEnum.KrizDurumu.Aktif ? { margin: 5 } : {}}>
                              {' '}
                              {krizKartDetail?.istasyonDurumuAdi}{' '}
                            </p>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={6}>
                          <p className="homeCardKey">
                            <Translate contentKey="tk24AdysApp.kriz.krizKapanmaTipi" />:
                          </p>
                        </Col>
                        <Col md={6}>
                          <p className="homeCardValue">{krizKartDetail.krizKapanmaTipiAdi}</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <p className="homeCardKey">
                        <Translate contentKey="tk24AdysApp.kriz.krizYeriAciklama" />:
                      </p>
                    </Col>
                    <Col md={9}>
                      <p className="homeCardValue">{krizKartDetail.krizYeriAciklama}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <p className="homeCardKey">
                        <Translate contentKey="tk24AdysApp.kriz.aciklama" />:
                      </p>
                    </Col>
                    <Col md={9}>
                      <p className="homeCardValue">{krizKartDetail.aciklama}</p>
                    </Col>
                  </Row>
                </div>
              </>
            )}
          </>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <KrizMain isDrawer={isDrawer}>
      {(loadingKrizDetail || dashboardLoading) && <LoadingSpinner />}
      <div style={{ marginBottom: 5 }}>
        <InfoTable id={krizId} />

        {krizKart && <KrizKart />}
      </div>
      <div className="mt-2 krizDetayBtn">
        <Row className="krizDetayButtonsRow">
          <Col className="conditional-col">
            <GrantedDisableButton
              id={'dashboard'}
              grantedAuthorites={['view_dashboard']}
              className="krizDetayButtons"
              tag={Link}
              to={`/dashboard/${Storage.session.get('kriz')}`}
            >
              <div className="krizdetay-image">
                <img id={'dashboard-icon'} className="krizdetay-icon" src={`${SERVER_URL}/content/images/kriz-detay/dashboard.svg`} />
                <img
                  id={'dashboard-icon'}
                  className="krizdetay-icon-hover"
                  src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/dashboard.svg`}
                />
              </div>
              <p>Dashboard</p>
            </GrantedDisableButton>
          </Col>

          <Col className="conditional-col">
            <GrantedDisableButton
              id={'krizAkisListe'}
              grantedAuthorites={['view_kriz_akis_listesi']}
              className="krizDetayButtons"
              tag={Link}
              to={`/kriz-akis-liste/${krizId}`}
            >
              <div className="krizdetay-image">
                <img
                  id={'krizAkisListe-icon'}
                  className="krizdetay-icon"
                  src={`${SERVER_URL}/content/images/kriz-detay/krizAkisListe.svg`}
                />
                <img
                  id={'krizAkisListe-icon'}
                  className="krizdetay-icon-hover"
                  src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/AkisListesi.svg`}
                />
              </div>
              <p>
                <Translate contentKey="dashboard.krizAkisListe" />
              </p>
            </GrantedDisableButton>
          </Col>

          <Col className="conditional-col">
            <GrantedDisableButton
              id={'tumGorevler'}
              grantedAuthorites={['view_tum_gorevler']}
              className="krizDetayButtons"
              tag={Link}
              to={`/kym-gorevler/${krizId}`}
            >
              <div className="krizdetay-image">
                <img id={'tumGorevler-icon'} className="krizdetay-icon" src={`${SERVER_URL}/content/images/kriz-detay/tumGorevler.svg`} />
                <img
                  id={'tumGorevler-icon'}
                  className="krizdetay-icon-hover"
                  src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/tumGorevler.svg`}
                />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.adymTumGorevler">ADYM Tüm Görevler</Translate>
              </p>
            </GrantedDisableButton>
          </Col>

          <Col className="conditional-col">
            <GrantedDisableButton
              id={'gorevlerim'}
              grantedAuthorites={['view_gorevlerim']}
              className="krizDetayButtons"
              tag={Link}
              to={`/kym-gorev/${krizId}`}
            >
              <div className="krizdetay-image">
                <img id={'gorevlerim-icon'} className="krizdetay-icon" src={`${SERVER_URL}/content/images/kriz-detay/gorevlerim.svg`} />
                <img
                  id={'gorevlerim-icon'}
                  className="krizdetay-icon-hover"
                  src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/gorevlerim.svg`}
                />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.adymGorevlerim">ADYM Görevlerim</Translate>
              </p>
            </GrantedDisableButton>
          </Col>

          <Col className="conditional-col">
            <GrantedDisableButton
              id={'talimatYonetimi'}
              grantedAuthorites={['view_talimat_ekrani']}
              className="krizDetayButtons"
              tag={Link}
              to={`/kriz-talimat-takip/${krizId}`}
            >
              <div className="krizdetay-image">
                <img
                  id={'talimatYonetimi-icon'}
                  className="krizdetay-icon"
                  src={`${SERVER_URL}/content/images/kriz-detay/talimatYonetimi.svg`}
                />
                <img
                  id={'talimatYonetimi-icon'}
                  className="krizdetay-icon-hover"
                  src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/talimatlar.svg`}
                />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.talimatTakip">Talimatlar</Translate>
              </p>
            </GrantedDisableButton>
          </Col>
        </Row>

        <Row className="krizDetayButtonsRow">
          <Col className="conditional-col">
            <GrantedDisableButton
              id={'seferBilgileri'}
              grantedAuthorites={['view_sefer_bilgileri']}
              className="krizDetayButtons"
              tag={Link}
              to={`/kriz-ucus/${krizId}`}
            >
              <div className="krizdetay-image">
                <img
                  id={'seferBilgileri-icon'}
                  className="krizdetay-icon"
                  src={`${SERVER_URL}/content/images/kriz-detay/seferBilgileri.svg`}
                />
                <img
                  id={'seferBilgileri-icon'}
                  className="krizdetay-icon-hover"
                  src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/seferBilgileri.svg`}
                />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.ucusBilgileri">Uçuş Bilgileri</Translate>
              </p>
            </GrantedDisableButton>
          </Col>

          <Col className="conditional-col">
            <GrantedDisableButton
              id={'ucakBilgileri'}
              grantedAuthorites={['view_ucak_bilgileri']}
              className="krizDetayButtons"
              tag={Link}
              to={`/kriz-ucak/${krizId}`}
            >
              <div className="krizdetay-image">
                <img
                  id={'ucakBilgileri-icon'}
                  className="krizdetay-icon"
                  src={`${SERVER_URL}/content/images/kriz-detay/ucakBilgileri.svg`}
                />
                <img
                  id={'ucakBilgileri-icon'}
                  className="krizdetay-icon-hover"
                  src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/ucakBilgileri.svg`}
                />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.ucakBilgileri">Uçak Bilgileri</Translate>
              </p>
            </GrantedDisableButton>
          </Col>

          <Col className="conditional-col">
            <GrantedDisableButton
              id={'etkilenenKisiler'}
              grantedAuthorites={['view_etkilenen_kisi']}
              className="krizDetayButtons"
              tag={Link}
              to={`/etkilenen-kisi/${krizId}`}
            >
              <div className="krizdetay-image">
                <img
                  id={'etkilenenKisiler-icon'}
                  className="krizdetay-icon"
                  src={`${SERVER_URL}/content/images/kriz-detay/etkilenenKisiler.svg`}
                />
                <img
                  id={'etkilenenKisiler-icon'}
                  className="krizdetay-icon-hover"
                  src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/etkilenenKisiler.svg`}
                />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.kisiBilgileri">Etkilenen Kişi Bilgileri</Translate>
              </p>
            </GrantedDisableButton>
          </Col>

          <Col className="conditional-col">
            <GrantedDisableButton
              id={'cerideListesi'}
              grantedAuthorites={['view_ceride_liste']}
              className="krizDetayButtons"
              tag={Link}
              to={`/kriz-ceride/${krizId}`}
            >
              <div className="krizdetay-image">
                <img
                  id={'cerideListesi-icon'}
                  className="krizdetay-icon"
                  src={`${SERVER_URL}/content/images/kriz-detay/cerideListesi.svg`}
                />
                <img
                  id={'cerideListesi-icon'}
                  className="krizdetay-icon-hover"
                  src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/ceride.svg`}
                />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.cerideListe" />
              </p>
            </GrantedDisableButton>
          </Col>

          <Col className="conditional-col">
            <GrantedDisableButton
              id={'raporlar'}
              grantedAuthorites={['view_raporlar']}
              className="krizDetayButtons"
              tag={Link}
              to={`/kriz-rapor/${krizId}`}
            >
              <div className="krizdetay-image">
                <img id={'raporlar-icon'} className="krizdetay-icon" src={`${SERVER_URL}/content/images/kriz-detay/raporlar.svg`} />
                <img
                  id={'raporlar-icon'}
                  className="krizdetay-icon-hover"
                  src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/raporlar.svg`}
                />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.raporlar">Raporlar</Translate>
              </p>
            </GrantedDisableButton>
          </Col>
        </Row>

        <Row className="krizDetayButtonsRow">
          <Col className="conditional-col">
            <GrantedDisableButton
              id={'vekaletYonetimi'}
              grantedAuthorites={['view_vekalet_yonetimi']}
              className="krizDetayButtons"
              tag={Link}
              to={`/vekalet/${krizId}`}
            >
              <div className="krizdetay-image">
                <img
                  id={'vekaletYonetimi-icon'}
                  className="krizdetay-icon"
                  src={`${SERVER_URL}/content/images/kriz-detay/vekaletYonetimi.svg`}
                />
                <img
                  id={'vekaletYonetimi-icon'}
                  className="krizdetay-icon-hover"
                  src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/vekaletYonetimi.svg`}
                />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.vekalet">Vekalet</Translate>
              </p>
            </GrantedDisableButton>
          </Col>

          <Col className="conditional-col">
            <GrantedDisableButton
              id={'dokumanlar'}
              grantedAuthorites={['view_dokumanlar']}
              className="krizDetayButtons"
              tag={Link}
              to={`/kriz-medya/${krizId}`}
            >
              <div className="krizdetay-image">
                <img id={'dokumanlar-icon'} className="krizdetay-icon" src={`${SERVER_URL}/content/images/kriz-detay/dokumanlar.svg`} />
                <img
                  id={'dokumanlar-icon'}
                  className="krizdetay-icon-hover"
                  src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/dokumanMedya.svg`}
                />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.medya">Medya & Doküman & Form & DDMS</Translate>
              </p>
            </GrantedDisableButton>
          </Col>

          <Col className="conditional-col">
            <GrantedDisableButton id="mesajlar" grantedAuthorites={['dashboard_mesaj']} className="krizDetayButtons" tag={Link}>
              <div className="krizdetay-image" onClick={handleDrawerOpen}>
                <img id="mesajlar-icon" className="mesaj-icon" src={`${SERVER_URL}/content/images/kriz-detay/newMessage.svg`} />
                <img id="mesajlar-icon-hover" className="mesaj-icon-hover" src={`${SERVER_URL}/content/images/kriz-detay/newMessage.svg`} />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.message">Mesajlar</Translate>
              </p>
              {isDrawer && <KrizChatNew krizId={krizId} open={isDrawer} onClose={handleDrawerClose} />}
            </GrantedDisableButton>
          </Col>

          <Col className="conditional-col">
            <GrantedDisableButton
              id={'kymDuyurular'}
              grantedAuthorites={['view_duyurular']}
              className="krizDetayButtons"
              tag={Link}
              to={`/kym-duyuru/${krizId}`}
            >
              <div className="krizdetay-image">
                <img id={'kymDuyurular-icon'} className="krizdetay-icon" src={`${SERVER_URL}/content/images/kriz-detay/duyurular.svg`} />
                <img
                  id={'kymDuyurular-icon'}
                  className="krizdetay-icon-hover"
                  src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/duyuruYonetimi.svg`}
                />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.adymMesajDuyuru">ADYM Duyuru</Translate>
              </p>
            </GrantedDisableButton>
          </Col>

          <Col className="conditional-col">
            <GrantedDisableButton
              id={'duyurular'}
              grantedAuthorites={['kriz_duyuru_read']}
              className="krizDetayButtons"
              tag={Link}
              to={`/kriz-duyurular/${krizId}`}
            >
              <div className="krizdetay-image">
                <img id={'duyurular-icon'} className="krizdetay-icon" src={`${SERVER_URL}/content/images/kriz-detay/duyurular.svg`} />
                <img
                  id={'duyurular-icon'}
                  className="krizdetay-icon-hover"
                  src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/duyuruYonetimi.svg`}
                />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.duyurular">Duyurular</Translate>
              </p>
            </GrantedDisableButton>
          </Col>
        </Row>

        <Row className="krizDetayButtonsRow">
          <Col className="conditional-col">
            <GrantedDisableButton
              id={'krizDetay'}
              grantedAuthorites={['view_kriz_detay']}
              className="krizDetayButtons"
              tag={Link}
              to={`/kriz-edit/${krizId}`}
            >
              <div className="krizdetay-image">
                <img id={'krizDetay-icon'} className="krizdetay-icon" src={`${SERVER_URL}/content/images/kriz-detay/krizdetay.svg`} />
                <img
                  id={'krizDetay-icon'}
                  className="krizdetay-icon-hover"
                  src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/krizDetay.svg`}
                />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.krizDetay">Kriz Detay</Translate>
              </p>
            </GrantedDisableButton>
          </Col>

          <Col className="conditional-col">
            <GrantedDisableButton
              id={'adtTumGorevler'}
              grantedAuthorites={['view_adt_gorevler']}
              className="krizDetayButtons"
              tag={Link}
              to={`/adt-gorevler/${krizId}`}
            >
              <div className="krizdetay-image">
                <img
                  id={'adtTumGorevler-icon'}
                  className="krizdetay-icon"
                  src={`${SERVER_URL}/content/images/kriz-detay/adtTumGorevler.svg`}
                />
                <img
                  id={'adtTumGorevler-icon'}
                  className="krizdetay-icon-hover"
                  src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/adtTumGorevler.svg`}
                />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.adtTumGorevler">ADT Tüm Görevler</Translate>
              </p>
            </GrantedDisableButton>
          </Col>

          <Col className="conditional-col">
            <GrantedDisableButton
              id={'adtGorevlerim'}
              grantedAuthorites={['view_adt_gorevlerim']}
              className="krizDetayButtons"
              tag={Link}
              to={`/adt-gorev/${krizId}`}
            >
              <div className="krizdetay-image">
                <img
                  id={'adtGorevlerim-icon'}
                  className="krizdetay-icon"
                  src={`${SERVER_URL}/content/images/kriz-detay/adtGorevlerim.svg`}
                />
                <img
                  id={'adtGorevlerim-icon'}
                  className="krizdetay-icon-hover"
                  src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/adtGorevlerim.svg`}
                />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.adtGorevlerim">ADT Görevlerim</Translate>
              </p>
            </GrantedDisableButton>
          </Col>

          <Col className="conditional-col">
            <GrantedDisableButton
              id={'istasyonGorevleri'}
              grantedAuthorites={['view_istasyon_gorevleri']}
              className="krizDetayButtons"
              tag={Link}
              to={`/kriz-istasyon-gorev/${krizId}`}
            >
              <div className="krizdetay-image">
                <img
                  id={'istasyonGorevleri-icon'}
                  className="krizdetay-icon"
                  src={`${SERVER_URL}/content/images/kriz-detay/istasyonGorevleri.svg`}
                />
                <img
                  id={'istasyonGorevleri-icon'}
                  className="krizdetay-icon-hover"
                  src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/istasyonGorevleri.svg`}
                />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.istasyonGorevleri" />
              </p>
            </GrantedDisableButton>
          </Col>
          <Col className="conditional-col">
            <GrantedDisableButton
              id={'irtibat'}
              grantedAuthorites={['view_iletisim']}
              className="krizDetayButtons"
              tag={Link}
              to={`/irtibat/${krizId}`}
            >
              <div className="krizdetay-image">
                <img id={'irtibat-icon'} className="krizdetay-icon" src={`${SERVER_URL}/content/images/kriz-detay/iletisim.svg`} />
                <img
                  id={'irtibat-icon'}
                  className="krizdetay-icon-hover"
                  src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/iletisim.svg`}
                />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.irtibatBilgileri">İrtibat</Translate>
              </p>
            </GrantedDisableButton>
          </Col>
        </Row>
      </div>
    </KrizMain>
  );
};

export default KrizDetay;
