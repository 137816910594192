import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';
import { IRefAdtEkip } from './ref-adt-ekip.model';

export interface IRefAdtAltEkip extends IBaseModel {
  adt_ekip_id?: number;
  ekip?: IRefAdtEkip;
  adiTr?: string;
  adiEn?: string;
  realId?: any;
}

export interface IRefAdtAltEkipParams extends IQueryParams {
  entity?: IRefAdtAltEkip;
  adt_ekip_id?: number;
}

export const defaultValue: Readonly<IRefAdtAltEkip> = {};
