import React, { useLayoutEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { logout } from 'app/shared/reducers/authentication';
import { SERVER_URL } from 'app/config/constants';

export const Logout = () => {
  const logoutUrl = useAppSelector(state => state.authentication.logoutUrl);
  const idToken = useAppSelector(state => state.authentication.idToken);
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    dispatch(logout());
    // window.location.href = SERVER_URL
    if (logoutUrl) {
      // if Keycloak, logoutUrl has protocol/openid-connect in it
      window.location.href = logoutUrl.includes('/protocol')
        ? logoutUrl + '?redirect_uri=' + SERVER_URL
        : logoutUrl + '?id_token_hint=' + idToken + '&post_logout_redirect_uri=' + SERVER_URL;
    }
  });

  return (
    <div className="p-5">
      <h4>Logged out successfully!</h4>
    </div>
  );
};

export default Logout;
