import { useAppDispatch, useAppSelector } from 'app/config/store';
import { KrizTalimatUpdateModal } from 'app/shared/model/kriz-talimat';
import { CustomSaveEntityConfirm, deleteEntityConfirm, isNullOrEmpty } from 'app/shared/util/confirm-utils';
import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Col, Form, FormGroup, Input, Label, ModalBody, Row, ModalFooter } from 'reactstrap';
import { createEntity, updateEntity } from './kriz-talimat.reducer';
import { FormItem } from 'app/component/FormItem';
import { TextFormat, Translate, translate } from 'app/component/jhipster';
import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { convertDateTimeZoneAppDate } from 'app/shared/util/date-utils';
import { IKrizKatilimci } from 'app/shared/model/kriz-katilimci.model';
import axios from 'axios';
import TableNameEnum from 'app/constants/TableNameEnum';
import { KatilimciRolMap, ReferansEnum } from 'app/constants/ReferansEnum';
import { RefEkDosyaTipiCodeEnum } from 'app/constants/RefEkDosyaTipiCodeEnum';
import { GetBase64PromiseString } from 'app/shared/util/util';
import {
  uploadEntity,
  getFilteredEntities as getFilterDuyuruDosyaEntities,
  deleteEntity as deleteEkDosyaEntity,
} from 'app/entities/ek-dosya/ek-dosya.reducer';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';

const KrizTalimatUpdate: React.FC<KrizTalimatUpdateModal> = ({ pageObject, krizId, talimat, handleClose }) => {
  const dispatch = useAppDispatch();
  const [sorumluKatilimcis, setSorumluKatilimcis] = useState<IKrizKatilimci[]>(
    talimat?.krizTalimatSorumlus?.map(item => item.krizKatilimci) || []
  );
  const krizKatilimcis = useAppSelector(state => state.krizKatilimci.entities).filter(item =>
    KatilimciRolMap.KYM.includes(item.katilimciRolu?.id)
  );
  const talimatDosyaList = useAppSelector(state => state.ekDosya.entities);
  const talimatDosyaLoading = useAppSelector(state => state.ekDosya.loading);
  const kriz = useAppSelector(state => state.kriz.entity);
  const refEkDosyaTipis = useAppSelector(state => state.refEkDosyaTipi.entities);
  const [sorumluRadio, setSorumluRadio] = useState(null);
  const [talimatRadio, setTalimatRadio] = useState(null);
  const [alertMessage, setAlertMessage] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [fileNames, setFileNames] = useState({});
  const [ekDosyaAciklama, setEkDosyaAciklama] = useState('');
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);

  useEffect(() => {
    if (talimat) {
      if (!isNullOrEmpty(talimat.sorumluDiger)) {
        setSorumluRadio('diger');
      } else {
        setSorumluRadio('katilimci');
      }

      if (!isNullOrEmpty(talimat.talimatiVerenDiger)) {
        setTalimatRadio('diger');
      } else {
        setTalimatRadio('katilimci');
      }
    } else {
      setSorumluRadio('katilimci');
      setTalimatRadio('katilimci');
    }
  }, [talimat]);

  function talimatDatetimeKontrol(talimatVerilmeZamani, talimatDeadline) {
    if (talimatDeadline !== null) {
      if (talimatVerilmeZamani > talimatDeadline) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  const checkFieldRule = formDataObject => {
    const isDatetimeKontrol = talimatDatetimeKontrol(formDataObject['talimatVerilmeZamani'], formDataObject['talimatDeadline']);

    const errorMessage = [];
    if (!isDatetimeKontrol) {
      const message = {
        visible: true,
        code: 'talimatDeadline',
        message: translate('entity.validation.lessThanField', { field: translate('tk24AdysApp.krizTalimat.talimatVerilmeZamani') }),
      };
      errorMessage.push(message);
    }

    if (sorumluRadio === 'diger' && isNullOrEmpty(formDataObject['sorumluDiger'])) {
      const message = {
        visible: true,
        code: 'sorumlu',
        message: translate('entity.validation.requiredField', { field: translate('tk24AdysApp.krizTalimat.sorumluDiger') }),
      };
      errorMessage.push(message);
    }

    if (sorumluRadio === 'katilimci' && sorumluKatilimcis.length === 0) {
      const message = {
        visible: true,
        code: 'sorumlu',
        message: translate('entity.validation.requiredField', { field: translate('tk24AdysApp.krizTalimat.sorumluKrizKatilimci') }),
      };
      errorMessage.push(message);
    }

    if (
      (talimatRadio === 'diger' && isNullOrEmpty(formDataObject['talimatiVerenDiger'])) ||
      (talimatRadio === 'katilimci' && isNullOrEmpty(formDataObject['talimatiVerenKrizKatilimciId']))
    ) {
      const fieldKey = talimatRadio === 'diger' ? 'talimatiVerenDiger' : 'talimatiVerenKrizKatilimci';
      const message = {
        visible: true,
        code: 'talimat',
        message: translate('entity.validation.requiredField', { field: translate(`tk24AdysApp.krizTalimat.${fieldKey}`) }),
      };

      errorMessage.push(message);
    }
    setAlertMessage(errorMessage);

    return errorMessage.length === 0;
  };

  const handleDrop = event => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const getFileNameAndExtension = filename => {
    const dotIndex = filename.lastIndexOf('.');
    if (dotIndex === -1) {
      return { name: filename, extension: '' };
    }
    return { name: filename.substring(0, dotIndex), extension: filename.substring(dotIndex) };
  };

  const handleFileNameChange = (index, newName) => {
    setFileNames(prevNames => ({
      ...prevNames,
      [index]: newName,
    }));

    const updatedFiles = selectedFiles.map((file, fileIndex) => {
      if (fileIndex === index) {
        const { extension } = getFileNameAndExtension(file.name);
        return new File([file], `${newName}${extension}`, { type: file.type });
      }
      return file;
    });

    setSelectedFiles(updatedFiles);
  };

  const handleEkDosyaAciklamaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEkDosyaAciklama(event.target.value);
  };

  const fileUpload = async ilgiliTabloKayitId => {
    if (selectedFiles.length > 0) {
      const dosya = {
        ilgiliTablo: TableNameEnum.KrizTalimat,
        ilgiliTabloKayitId,
        onaylandiMi: ReferansEnum.EvetHayir.Hayir,
        ekDosyaTipi: refEkDosyaTipis.find(res => res.code === RefEkDosyaTipiCodeEnum.KrizTalimatDefaultDokuman),
      };
      const fileInfoArray = await GetBase64PromiseString(selectedFiles);
      const entity = {
        ...dosya,
        fileList: fileInfoArray.map((file, index) => {
          const { extension } = getFileNameAndExtension(file.fileName);
          const updatedName = fileNames[index] || getFileNameAndExtension(file.fileName).name;
          return {
            ...file,
            fileName: `${updatedName}${extension}`,
            aciklama: ekDosyaAciklama,
          };
        }),
      };
      dispatch(uploadEntity(entity));
    }
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setSelectedFiles([]);
    setFileNames({});
    setEkDosyaAciklama('');
    setIsSaveModalOpen(false);
  };

  const saveEntity = async event => {
    event.preventDefault();

    const formDataObject = {};

    for (const field of event.target.elements) {
      if (field.name) {
        switch (field.name) {
          case 'talimatVerilmeZamani':
          case 'talimatDeadline':
            formDataObject[field.name] = convertDateTimeZoneAppDate(field.value);
            break;
          default:
            formDataObject[field.name] = field.value;
            break;
        }
      }
    }

    const isRule = checkFieldRule(formDataObject);
    if (isRule) {
      const isSave = await showSaveConfirmModal();

      if (isSave) {
        const entity = { ...talimat, ...formDataObject };

        const talimatEntity = {
          ...pageObject,
          entity: {
            ...entity,
            kriz,
            krizTalimatSorumlus:
              sorumluRadio === 'diger' ? [] : sorumluKatilimcis && sorumluKatilimcis.map(katilimci => ({ krizKatilimci: katilimci })),
            sorumluDiger: sorumluRadio === 'diger' ? entity['sorumluDiger'] : null,
            talimatiVerenKrizKatilimci:
              talimatRadio === 'diger'
                ? null
                : formDataObject['talimatiVerenKrizKatilimciId'] &&
                  krizKatilimcis.find(item => item.id.toString() === formDataObject['talimatiVerenKrizKatilimciId'].toString()),
            talimatiVerenDiger: talimatRadio === 'diger' ? entity['talimatiVerenDiger'] : null,
          },
        };

        if (talimat) {
          await dispatch(updateEntity(talimatEntity));
          fileUpload(talimatEntity.entity.realId);
        } else {
          const createEntityAction: any = await dispatch(createEntity(talimatEntity));
          const createdEntity: { realId: number } = createEntityAction.payload;
          fileUpload(createdEntity.realId);
        }

        handleClose();
      }
    }
  };

  const handleAllToggle = () => {
    if (krizKatilimcis && sorumluKatilimcis.length === krizKatilimcis.length) {
      setSorumluKatilimcis([]);
    } else {
      const katilimcis = [...krizKatilimcis];
      setSorumluKatilimcis(katilimcis);
    }
  };

  const handleToggle = (value: any) => () => {
    const currentIndex = sorumluKatilimcis.findIndex(val => val.id === value.id);
    const newsorumluKatilimcis = [...sorumluKatilimcis];

    if (currentIndex === -1) {
      newsorumluKatilimcis.push(value);
    } else {
      newsorumluKatilimcis.splice(currentIndex, 1);
    }

    setSorumluKatilimcis(newsorumluKatilimcis);
  };

  const getAlertMessage = alertCode => {
    const foundItem = alertMessage.find(item => item.code === alertCode);
    return foundItem;
  };

  const onAlertMessageDismiss = code => {
    const updatedAlertMessage = alertMessage.filter(item => item.code !== code);

    setAlertMessage(updatedAlertMessage);
  };

  const handleFileChange = e => {
    const files = e.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleRemoveFile = index => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };

  const handleDownloadSavedFile = async file => {
    setLoading(true);
    try {
      const response = await axios.get(`api/ek-dosyas/download/${file.id}`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${file.dosyaAdi}`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Dosya indirme hatası:', error);
    }
    setLoading(false);
  };

  const handleRemoveSavedFile = async file => {
    const talimatId = file?.ilgiliTabloKayitId;
    const isDel = await deleteEntityConfirm(file.id, 'ekDosya');

    if (isDel) {
      setLoading(true);
      await dispatch(deleteEkDosyaEntity(file.id));
      dispatch(
        getFilterDuyuruDosyaEntities({
          ilgiliTabloKayitId: talimatId,
          ilgiliTablo: TableNameEnum.KrizTalimat,
        })
      );
      setLoading(false);
    }
  };

  return (
    <div>
      <CustomSaveEntityConfirm entity={talimat} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />
      <Form onSubmit={saveEntity}>
        <ModalBody className="modalTalimatText" style={{ maxHeight: '500px', overflowY: 'auto' }}>
          <Row>
            <Col md={6}>
              <FormItem
                name="adiTr"
                id="kriz-talimat-adiTr"
                label={translate('tk24AdysApp.krizTalimat.adiTr')}
                defaultValue={talimat && talimat['adiTr']}
                type="textarea"
                validation={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 2000, message: translate('entity.validation.maxlength', { max: 2000 }) },
                }}
              />
              <FormItem
                name="adiEn"
                id="kriz-talimat-adiEn"
                label={translate('tk24AdysApp.krizTalimat.adiEn')}
                defaultValue={talimat && talimat['adiEn']}
                type="textarea"
                validation={{
                  maxLength: { value: 2000, message: translate('entity.validation.maxlength', { max: 2000 }) },
                }}
              />
              <FormItem
                type="datetime"
                label={translate('tk24AdysApp.krizTalimat.talimatVerilmeZamani')}
                name="talimatVerilmeZamani"
                id="kriz-talimat-talimatVerilmeZamani"
                disableFuture={false}
                disablePast={false}
                defaultValue={talimat ? talimat['talimatVerilmeZamani'] : new Date().toLocaleString()}
                validation={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <FormItem
                type="datetime"
                label={translate('tk24AdysApp.krizTalimat.talimatDeadline')}
                name="talimatDeadline"
                id="kriz-talimat-talimatDeadline"
                defaultValue={talimat && talimat['talimatDeadline']}
                disableFuture={false}
                disablePast={false}
              />

              {getAlertMessage('talimatDeadline') && (
                <div>
                  <Alert
                    color="danger"
                    isOpen={getAlertMessage('talimatDeadline').visible}
                    toggle={() => onAlertMessageDismiss('talimatDeadline')}
                  >
                    {getAlertMessage('talimatDeadline').message}
                  </Alert>
                </div>
              )}

              <FormGroup tag="fieldset" style={{ display: 'flex', margin: 5 }}>
                <FormGroup check style={{ marginRight: '10%' }} onClick={() => setTalimatRadio('katilimci')}>
                  <Input
                    type="radio"
                    id="radio-talimat-katilimci"
                    name="radioTalimatKatilimci"
                    value="katilimci"
                    checked={talimatRadio === 'katilimci'}
                    onChange={() => setTalimatRadio('katilimci')}
                  />
                  <Label check for="radio-talimat-katilimci">
                    {translate('tk24AdysApp.krizTalimat.talimatiVerenKrizKatilimci')}
                  </Label>
                </FormGroup>

                <FormGroup
                  check
                  style={{ marginLeft: '10%' }}
                  onClick={() => {
                    setTalimatRadio('diger');
                  }}
                >
                  <Input
                    type="radio"
                    id="radio-talimat-diger"
                    name="radioTalimatDiger"
                    value="diger"
                    checked={talimatRadio === 'diger'}
                    onChange={() => {
                      setTalimatRadio('diger');
                    }}
                  />
                  <Label check for="radio-talimat-diger">
                    {translate('tk24AdysApp.krizTalimat.talimatiVerenDiger')}
                  </Label>
                </FormGroup>
              </FormGroup>

              {getAlertMessage('talimat') && (
                <div>
                  <Alert color="danger" isOpen={getAlertMessage('talimat').visible} toggle={() => onAlertMessageDismiss('talimat')}>
                    {getAlertMessage('talimat').message}
                  </Alert>
                </div>
              )}

              {talimatRadio === 'diger' && (
                <FormItem
                  name="talimatiVerenDiger"
                  id="kriz-talimat-talimat-diger"
                  defaultValue={talimat && talimat['talimatiVerenDiger']}
                  label={translate('tk24AdysApp.krizTalimat.talimatiVerenDiger')}
                  type="text"
                />
              )}

              {talimatRadio === 'katilimci' && (
                <FormItem
                  label={translate('tk24AdysApp.krizTalimat.talimatiVerenKrizKatilimci')}
                  id="kriz-talimat-talimat-kriz-katilimci"
                  name="talimatiVerenKrizKatilimciId"
                  type="select"
                  defaultValue={talimat && talimat.talimatiVerenKrizKatilimci?.id}
                >
                  <option value="" key="0" />
                  {krizKatilimcis &&
                    krizKatilimcis.length > 0 &&
                    krizKatilimcis.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.adi} {otherEntity.soyadi} / {otherEntity.unvanAdi}
                      </option>
                    ))}
                </FormItem>
              )}

              <div>
                <Row>
                  {(loading || talimatDosyaLoading) && <LoadingSpinner />}
                  <Col>
                    <FormItem
                      label={translate('tk24AdysApp.ekDosya.ekDosyaAciklama')}
                      id="ek-dosya-aciklama"
                      name="aciklama"
                      defaultValue={ekDosyaAciklama}
                      onChange={handleEkDosyaAciklamaChange}
                      type="textarea"
                      validation={{
                        maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
                      }}
                    />

                    <div className="modal-file-area">
                      <div className="modal-dashed-file-area" onDrop={handleDrop} onDragOver={e => e.preventDefault()}>
                        <img
                          width={16}
                          height={20}
                          style={{ marginBottom: '20px' }}
                          src={`${SERVER_URL}/content/images/icon/downloadIcon.svg`}
                        />
                        <span className="modal-file-text">
                          <span className="modal-upload-file-text" onClick={() => fileInputRef.current.click()}>
                            <Translate contentKey="tk24AdysApp.ekDosya.yukle" />
                          </span>
                          <span style={{ color: 'rgba(73, 88, 106, 1)' }}>
                            {' '}
                            <Translate contentKey="tk24AdysApp.ekDosya.surukle" />
                          </span>
                          <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} multiple />
                        </span>
                      </div>
                      <div className="modal-file-list-area">
                        {selectedFiles.length > 0 &&
                          selectedFiles.map((file, index) => {
                            const { name, extension } = getFileNameAndExtension(file.name);
                            return (
                              <div key={file.id} className="modal-file-list-row">
                                <div
                                  style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', padding: '0px', width: '50px' }}
                                >
                                  <img width={16} height={20} src={`${SERVER_URL}/content/images/icon/downloadIcon.svg`} />
                                </div>
                                <div style={{ width: '100%' }}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'start',
                                      justifyContent: 'center',
                                      margin: 'auto',
                                    }}
                                  >
                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                      <input
                                        type="text"
                                        value={fileNames[index] || name}
                                        onChange={e => handleFileNameChange(index, e.target.value)}
                                        className="form-control"
                                        maxLength={950}
                                      />
                                      <span className="ml-2">{extension}</span>
                                    </div>
                                    <span className="modal-file-size">{`${(file.size / 1024).toFixed(2)}kb`}</span>
                                  </div>
                                </div>
                                <div
                                  style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', padding: '0px', width: '50px' }}
                                >
                                  <Button className="delete-file-button" onClick={() => handleRemoveFile(index)}>
                                    <img width={12} height={15} src={`${SERVER_URL}/content/images/icon/deleteFileIcon.svg`} />
                                  </Button>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col md={6}>
              <FormGroup tag="fieldset" style={{ display: 'flex', margin: 5 }}>
                <FormGroup check style={{ marginRight: '10%' }} onClick={() => setSorumluRadio('katilimci')}>
                  <Input
                    type="radio"
                    id="radio-sorumlu-katilimci"
                    name="radioSorumluKatilimci"
                    value="katilimci"
                    checked={sorumluRadio === 'katilimci'}
                    onChange={() => setSorumluRadio('katilimci')}
                  />
                  <Label check for="radio-sorumlu-katilimci">
                    {translate('tk24AdysApp.krizTalimat.sorumluKrizKatilimci')}
                  </Label>
                </FormGroup>

                <FormGroup
                  check
                  style={{ marginLeft: '10%' }}
                  onClick={() => {
                    setSorumluRadio('diger');
                    setSorumluKatilimcis([]);
                  }}
                >
                  <Input
                    type="radio"
                    id="radio-sorumlu-diger"
                    name="radioSorumluDiger"
                    value="diger"
                    checked={sorumluRadio === 'diger'}
                    onChange={() => {
                      setSorumluRadio('diger');
                      setSorumluKatilimcis([]);
                    }}
                  />
                  <Label check for="radio-soeumlu-diger">
                    {translate('tk24AdysApp.krizTalimat.sorumluDiger')}
                  </Label>
                </FormGroup>
              </FormGroup>

              {getAlertMessage('sorumlu') && (
                <div>
                  <Alert color="danger" isOpen={getAlertMessage('sorumlu').visible} toggle={() => onAlertMessageDismiss('sorumlu')}>
                    {getAlertMessage('sorumlu').message}
                  </Alert>
                </div>
              )}

              {sorumluRadio === 'diger' && (
                <FormItem
                  name="sorumluDiger"
                  id="kriz-talimat-sorumlu-diger"
                  label={translate('tk24AdysApp.krizTalimat.sorumluDiger')}
                  type="text"
                  defaultValue={talimat && talimat['sorumluDiger']}
                />
              )}

              {sorumluRadio === 'katilimci' && (
                <>
                  <Paper sx={{ width: '100%', height: 530, overflow: 'auto' }}>
                    <List dense component="div" role="list">
                      <ListItem key={0} role="listitem" onClick={handleAllToggle}>
                        <ListItemIcon>
                          <Checkbox
                            checked={sorumluKatilimcis.length === krizKatilimcis.length}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{
                              'aria-labelledby': `transfer-list-item-all-label`,
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText id={`transfer-list-item-all-label`} primary={translate('entity.action.selectAll')} />
                      </ListItem>

                      {krizKatilimcis &&
                        krizKatilimcis.map((value: any) => {
                          const labelId = `transfer-list-item-${value.adi}-label`;

                          return (
                            <ListItem key={value.id} role="listitem" onClick={handleToggle(value)}>
                              <ListItemIcon>
                                <Checkbox
                                  checked={sorumluKatilimcis.findIndex(val => val.id === value.id) !== -1}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{
                                    'aria-labelledby': labelId,
                                  }}
                                />
                              </ListItemIcon>
                              <ListItemText
                                id={labelId}
                                primary={`${value.adi ?? ''} ${value.soyadi ?? ''}${value.unvanAdi ? ` / ${value.unvanAdi}` : ''}`}
                              />
                            </ListItem>
                          );
                        })}
                    </List>
                  </Paper>
                </>
              )}
            </Col>
          </Row>

          <hr style={{ marginBottom: 20, marginTop: 20 }} />
          {talimat && (
            <Row>
              <Col sm="12">
                <Row className="modal-content-title" style={{ margin: '0px 0px 10px 0px' }}>
                  <Translate contentKey="tk24AdysApp.ekDosya.savedFiles" />
                </Row>

                <TableContainer className="content-table">
                  {talimatDosyaList && talimatDosyaList.length > 0 ? (
                    <Table aria-label="a dense table">
                      <TableHead className="content-table-title-area">
                        <TableRow className="table-head-row">
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.ekDosya.dosyaAdi">dosyaAdi</Translate>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.ekDosya.aciklamaTitle">aciklama</Translate>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.ekDosya.fileLanguageTitle">dil</Translate>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.ekDosya.fileExtentionTitle">fileExtention</Translate>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.ekDosya.ekleyenKullanici">ekleyenKullanici</Translate>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.ekDosya.cDateTitle">CDate</Translate>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {talimatDosyaList.map(ekDosya => (
                          <TableRow key={ekDosya.id} className="table-cell-row">
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {ekDosya?.dosyaAdi}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {ekDosya?.aciklama}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {ekDosya?.dil?.adi}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {ekDosya?.fileExtention}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {ekDosya?.ekleyenKullanici?.adi} {ekDosya?.ekleyenKullanici?.soyadi}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              <TextFormat type="date" value={ekDosya.cDate} format={APP_DATE_FORMAT} />
                            </TableCell>
                            <TableCell className="table-content-cell" align="right">
                              <div className="btn-group flex-btn-group-container">
                                <Button className="edit-button" style={{ marginLeft: 5 }} onClick={() => handleDownloadSavedFile(ekDosya)}>
                                  <img src={`${SERVER_URL}/content/images/icon/faArrowDownToLine.svg`} />
                                  <Translate contentKey="entity.action.download">İndir</Translate>
                                </Button>
                                <Button className="delete-button" style={{ marginLeft: 5 }} onClick={() => handleRemoveSavedFile(ekDosya)}>
                                  <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                                  <span>
                                    <Translate contentKey="entity.action.delete">Sil</Translate>
                                  </span>
                                </Button>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : (
                    <div className="alert alert-warning m-2">
                      <Translate contentKey="tk24AdysApp.ekDosya.home.notFound">No Ek Dosya found</Translate>
                    </div>
                  )}
                </TableContainer>
              </Col>
            </Row>
          )}
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Button className="cancel-button" onClick={handleClose} replace>
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          &nbsp;
          <Button className="save-button" id="jhi-confirm-delete-Talimat" type="submit" data-cy="entityConfirmButton">
            <Translate contentKey="entity.action.save">Kaydet</Translate>
          </Button>
        </ModalFooter>
      </Form>
    </div>
  );
};

export default KrizTalimatUpdate;
