import React, { useEffect, useState } from 'react';
import { TextFormat, Translate } from 'app/component/jhipster';
import { IRefMurettebat } from 'app/shared/model/ref-murettebat.model';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { filterBilgiFields } from 'app/constants/RefMurettebatDetayEnum';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { LoadingSpinner } from 'app/component/LoadingSpinner';

interface EtkilenenKisiMurettebatBossTabProps {
  refMurettebat: IRefMurettebat;
  tab: number;
}

const EtkilenenKisiMurettebatBossTab: React.FC<EtkilenenKisiMurettebatBossTabProps> = ({ refMurettebat, tab }) => {
  const [bossLoading, setBossLoading] = useState(false);
  const [murettebatDetayBilgileri, setMurettebatDetayBilgileri] = useState([]);

  useEffect(() => {
    if (tab === 3) {
      getDetayBilgileri(refMurettebat);
    }
  }, [tab]);

  const getDetayBilgileri = async murettebat => {
    setBossLoading(true);
    const murettebatIdUrl = `api/ref-murettebat-detays?refMurettebat.equals=${murettebat?.id}&size=2000`;
    try {
      const response = await axios.get(murettebatIdUrl);
      setMurettebatDetayBilgileri(response.data);
    } catch (error) {
      console.error('Error fetching detay bilgileri:', error);
    } finally {
      setBossLoading(false);
    }
  };

  const parseBilgi = bilgi => {
    try {
      return JSON.parse(bilgi);
    } catch (error) {
      console.error('Failed to parse bilgi:', error);
      return {};
    }
  };

  const groupByBilgiTipi = data => {
    return data.reduce((acc, item) => {
      const key = item.bilgiTipi;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});
  };

  const isValidDate = date => {
    const parsedDate = Date.parse(date);
    return !isNaN(parsedDate);
  };

  const isNullLike = value => {
    if (typeof value === 'string') {
      const lowerValue = value.toLowerCase();
      return lowerValue === 'null' || lowerValue === 'undefined' || lowerValue.trim() === '';
    }
    return value === null || value === undefined;
  };

  const renderCellValue = value => {
    if (isNullLike(value)) {
      return '';
    }

    if (typeof value === 'boolean') {
      return value ? (
        <Translate contentKey="entity.action.evet">Evet</Translate>
      ) : (
        <Translate contentKey="entity.action.hayir">Hayır</Translate>
      );
    }

    if (typeof value === 'string') {
      if (isValidDate(value)) {
        return <TextFormat type="date" value={value} format={APP_LOCAL_DATE_FORMAT} />;
      }
    }

    return value?.toString() || '';
  };

  const groupedData = groupByBilgiTipi(murettebatDetayBilgileri);

  return (
    <>
      {bossLoading && <LoadingSpinner />}
      {!bossLoading ? (
        <>
          {Object.keys(groupedData).map(bilgiTipi => (
            <React.Fragment key={bilgiTipi}>
              <Typography className="accordion-typography mt-3" gutterBottom component="div">
                <Translate contentKey={`tk24AdysApp.refMurettebat.detayTab.${bilgiTipi}`}></Translate>
              </Typography>
              <TableContainer className="content-table mb-4" style={{ backgroundColor: '#ffffff' }}>
                <Table className="accordion-table">
                  <TableHead>
                    <TableRow className="accordion-table-title-row">
                      {Object.keys(filterBilgiFields(parseBilgi(groupedData[bilgiTipi][0].bilgi), bilgiTipi)).map(key => (
                        <TableCell className="accordion-table-title-cell" align="left" key={key}>
                          <Translate contentKey={`tk24AdysApp.refMurettebat.detayTab.${key}`}>detayTab başlık</Translate>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {groupedData[bilgiTipi].map((murettebat, index) => {
                      const bilgiData = filterBilgiFields(parseBilgi(murettebat.bilgi), bilgiTipi);
                      return (
                        <TableRow key={index} className="accordion-table-content-row">
                          {Object.entries(bilgiData).map(([key, value], i) => (
                            <TableCell className="accordion-table-content-cell" component="th" scope="row" key={i}>
                              {renderCellValue(value)}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </React.Fragment>
          ))}
          {!bossLoading && murettebatDetayBilgileri.length === 0 && (
            <div className="alert alert-warning">
              <Translate contentKey="tk24AdysApp.refMurettebat.home.detailNotFound">No refMurettebat found</Translate>
            </div>
          )}
        </>
      ) : null}
    </>
  );
};

export default EtkilenenKisiMurettebatBossTab;
