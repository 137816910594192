import { IRefKatilimci } from './ref-katilimci.model';
import { IRefDDMSDokuman } from './ref-ddms-dokuman.model';
import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';

export interface IRefKatilimciDdmsDokuman extends IBaseModel {
  katilimci?: IRefKatilimci;
  ddmsDokuman?: IRefDDMSDokuman;
}

export interface IRefKatilimciDdmsDokumanParams extends IQueryParams {
  entity?: IRefKatilimciDdmsDokuman;
  ddmsDokumanId?: number;
  katilimciId?: number;
}

export const defaultValue: Readonly<IRefKatilimciDdmsDokuman> = {};
