import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IKrizKatilimcVekiliParams, IKrizKatilimci, IKrizKatilimciParams, defaultValue } from 'app/shared/model/kriz-katilimci.model';

const initialState: EntityState<IKrizKatilimci> = {
  loading: false,
  errorMessage: null,
  entities: [],
  totalEntities: [],
  exportEntities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

interface KrizKatilimciProp extends IQueryParams {
  krizId: number | string;
  sicilId?: string;
}

const apiUrl = 'api/kriz-katilimcis';
const apiVekilUrl = 'api/kriz-katilimcis/vekils';

// Actions

export const getEntities = createAsyncThunk('krizKatilimci/fetch_entity_list', async ({ page, size, sort, query }: IQueryParams) => {
  const queryParams = new URLSearchParams();
  if (sort) {
    queryParams.set('page', page.toString());
    queryParams.set('size', size.toString());
    queryParams.set('sort', sort);
  }

  if (query) {
    const parsedQueryParams = new URLSearchParams(query);
    parsedQueryParams.forEach((value, key) => {
      queryParams.set(key, value);
    });
  }
  queryParams.set('cacheBuster', new Date().getTime().toString());
  const queryString = queryParams.toString();
  const requestUrl = `${apiUrl}${queryString ? `?${queryString}` : ''}`;
  return axios.get<IKrizKatilimci[]>(requestUrl);
});

export const getEntitiesVekil = createAsyncThunk(
  'krizKatilimciVekil/fetch_vekil_entity_list',
  async ({ page, size, sort, query, katilimciId, krizId }: IKrizKatilimcVekiliParams) => {
    const queryParams = new URLSearchParams();
    if (sort) {
      queryParams.set('page', page.toString());
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
    }
    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }
    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `${apiVekilUrl}/${krizId}/${katilimciId}${queryString ? `?${queryString}` : ''}`;

    return axios.get<IKrizKatilimci[]>(requestUrl);
  }
);

export const getEntitiesTotalVekil = createAsyncThunk(
  'krizKatilimciVekil/fetch_total_vekil_entity_list',
  async ({ page, size, sort, query, katilimciId, krizId }: IKrizKatilimcVekiliParams) => {
    const queryParams = new URLSearchParams();
    if (sort) {
      queryParams.set('page', page.toString());
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
    }
    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }
    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `${apiVekilUrl}/export/${krizId}/${katilimciId}${queryString ? `?${queryString}` : ''}`;

    return axios.get<IKrizKatilimci[]>(requestUrl);
  }
);

export const getTotalEntities = createAsyncThunk(
  'krizKatilimci/fetch_entity_total_list',
  async ({ size, sort, query }: IKrizKatilimciParams) => {
    const queryParams = new URLSearchParams();
    if (sort) {
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
    }
    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }
    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `${apiUrl}${queryString ? `?${queryString}` : ''}`;

    return axios.get<IKrizKatilimci[]>(requestUrl);
  }
);

export const getExportEntities = createAsyncThunk(
  'krizKatilimci/fetch_entity_export_list',
  async ({ size, sort, query }: IKrizKatilimciParams) => {
    const queryParams = new URLSearchParams();
    if (sort) {
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
    }
    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }
    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `${apiUrl}/export${queryString ? `?${queryString}` : ''}`;

    return axios.get<IKrizKatilimci[]>(requestUrl);
  }
);

export const getEntitiesByKymKatilimci = createAsyncThunk(
  'krizKatilimci/fetch_entity_kym_katilimci_list',
  async ({ krizId, katilimciRoluIds, page, size, sort, query }: IKrizKatilimciParams) => {
    const queryParams = new URLSearchParams();
    if (sort) {
      queryParams.set('page', page.toString());
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
    }

    if (krizId) {
      queryParams.set('krizId.equals', krizId.toString());
    }

    if (katilimciRoluIds) {
      queryParams.set('katilimciRoluId.in', katilimciRoluIds.toString());
    }

    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }
    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `${apiUrl}${queryString ? `?${queryString}` : ''}`;
    return axios.get<IKrizKatilimci[]>(requestUrl);
  }
);

export const getEntitiesByKrizId = createAsyncThunk(
  'krizKatilimci/fetch_entity_list',
  async ({ krizId, page, size, sort }: KrizKatilimciProp) => {
    const requestUrl = `${apiUrl}${
      sort ? `?krizId.equals=${krizId}&page=${page}&size=${size}&sort=${sort}&` : '?'
    }cacheBuster=${new Date().getTime()}`;
    return axios.get<IKrizKatilimci[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  'krizKatilimci/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IKrizKatilimci>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getEntityBySicilId = createAsyncThunk(
  'krizKatilimci/fetch_sicil_entity',
  async ({ krizId, sicilId }: KrizKatilimciProp) => {
    const requestUrl = `${apiUrl}?sicil.equals=${sicilId}&krizId.equals=${krizId}`;
    return axios.get<IKrizKatilimci>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getDrawerEntityBySicilId = createAsyncThunk(
  'krizKatilimci/fetch_sicil_drawer_entity',
  async ({ krizId, sicilId }: KrizKatilimciProp) => {
    const requestUrl = `${apiUrl}?sicil.equals=${sicilId}&krizId.equals=${krizId}`;
    return axios.get<IKrizKatilimci>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'krizKatilimci/create_entity',
  async (entity: IKrizKatilimci, thunkAPI) => {
    const result = await axios.post<IKrizKatilimci>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'krizKatilimci/update_entity',
  async (entity: IKrizKatilimci, thunkAPI) => {
    const result = await axios.put<IKrizKatilimci>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateParamsEntity = createAsyncThunk(
  'krizKatilimci/update_entity',
  async (params: IKrizKatilimciParams, thunkAPI) => {
    const result = await axios.put<IKrizKatilimci>(`${apiUrl}/${params.entity.id}`, cleanEntity(params.entity));
    thunkAPI.dispatch(getEntities(params));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateKatilimciEntity = createAsyncThunk(
  'krizKatilimci/update_entity',
  async (params: IKrizKatilimciParams) => {
    const result = await axios.put<IKrizKatilimci>(`${apiUrl}/${params.entity.id}`, cleanEntity(params.entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'krizKatilimci/partial_update_entity',
  async (entity: IKrizKatilimci, thunkAPI) => {
    const result = await axios.patch<IKrizKatilimci>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'krizKatilimci/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IKrizKatilimci>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteKatilimciEntity = createAsyncThunk(
  'krizKatilimci/delete_entity',
  async (params: IKrizKatilimciParams, thunkAPI) => {
    const requestUrl = `${apiUrl}/${params.entity.id}`;
    const result = await axios.delete<IKrizKatilimci>(requestUrl);
    thunkAPI.dispatch(getEntitiesByKymKatilimci(params));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const KrizKatilimciSlice = createEntitySlice({
  name: 'krizKatilimci',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntity, getEntityBySicilId), (state, action) => {
        state.loading = false;
        state.entity = action.payload.data[0];
      })
      .addMatcher(isFulfilled(getDrawerEntityBySicilId), (state, action) => {
        state.loading = false;
        state.drawerEntity = action.payload.data[0];
      })
      .addMatcher(isFulfilled(getTotalEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          totalEntities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getEntitiesTotalVekil), (state, action) => {
        return {
          ...state,
          loading: false,
          totalEntities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getExportEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          exportEntities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getEntities, getEntitiesByKrizId, getEntitiesVekil, getEntitiesByKymKatilimci), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(
        isFulfilled(createEntity, updateEntity, updateParamsEntity, updateKatilimciEntity, partialUpdateEntity),
        (state, action) => {
          state.updating = false;
          state.loading = false;
          state.updateSuccess = true;
          state.entity = action.payload.data;
        }
      )
      .addMatcher(
        isPending(
          getEntities,
          getEntitiesByKrizId,
          getEntitiesByKymKatilimci,
          getEntitiesVekil,
          getEntity,
          getEntityBySicilId,
          getDrawerEntityBySicilId,
          getTotalEntities,
          getEntitiesTotalVekil,
          getExportEntities
        ),
        state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.loading = true;
        }
      )
      .addMatcher(
        isPending(
          createEntity,
          updateEntity,
          updateParamsEntity,
          updateKatilimciEntity,
          partialUpdateEntity,
          deleteEntity,
          deleteKatilimciEntity
        ),
        state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.updating = true;
        }
      );
  },
});

export const { reset } = KrizKatilimciSlice.actions;

// Reducer
export default KrizKatilimciSlice.reducer;
