import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Card, CardText, Container, Input } from 'reactstrap';
import axios from 'axios';
import { TextFormat, Translate, translate } from 'app/component/jhipster';
import { APP_DATETIME_FORMAT, SERVER_URL } from 'app/config/constants';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Search } from '@mui/icons-material';
import KrizChatDetail from 'app/entities/kriz-chat/kriz-chat-adt/kriz-chat-detail';
import { DrawerLoadingSpinner } from 'app/component/LoadingSpinner';
import { messageReplace } from 'app/shared/util/confirm-utils';
import { ADTDmMessageComponent, KrizADTTimComponent } from '../kriz-chat-component';
import { setNewMessage } from 'app/entities/notification/notification.reducer';

const KrizChatAdt = ({ krizId }) => {
  const newMessage = useAppSelector(state => state.notification.newMessage);
  const dispatch = useAppDispatch();
  const [messageList, setMessageList] = useState([]);
  const [loading, setLoading] = useState(false);
  const krizAdtEkipEntities = useAppSelector(state => state.krizAdtEkip.entities);
  const krizKatilimciEntities = useAppSelector(state => state.krizKatilimci.entities);
  const kriz = useAppSelector(state => state.kriz.entity);
  const [selectedMessageKullanici, setSelectedMessageKullanici] = useState(null);
  const [isOpenChatDetail, setIsOpenChatDetail] = useState(false);
  const [isOpenNewChat, setIsOpenNewChat] = useState(false);
  const [selectedAliciTipi, setSelectedAliciTipi] = useState(null);
  const [selectedGonderenSicil, setSelectedGonderenSicil] = useState(null);
  const [selectedGonderenTim, setSelectedGonderenTim] = useState(null);
  const [selectedMesajlasmaTipi, setSelectedMesajlasmaTipi] = useState(null);
  const [search, setSearch] = useState('');
  const [tumAdtMessage, setTumAdtMessage] = useState(null);
  const [yonetimMessage, setYonetimMessage] = useState(null);
  const [adtTimMessage, setAdtTimMessage] = useState([]);
  const [combinedChats, setCombinedChats] = useState([]);
  const sicilNo = useAppSelector(state => state.authentication.account.sicilNo);

  const filterTimMessagesForParticipant = () => {
    if (!krizKatilimciEntities || !krizAdtEkipEntities) {
      return [];
    }

    const participantEkipIds = krizKatilimciEntities.map(katilimci => katilimci.adtEkip?.id).filter(Boolean);

    const filteredTimMessages = adtTimMessage.filter(message => {
      const ekip = krizAdtEkipEntities.find(ekip1 => ekip1.adtEkip?.id === message.krizAdtEkip.adtEkip.id);

      return ekip && participantEkipIds.includes(ekip.adtEkip?.id);
    });

    return filteredTimMessages;
  };

  useEffect(() => {
    const allChats = [];

    const participantEkipIds = krizKatilimciEntities
      .filter(res => res.sicil === sicilNo)
      .map(katilimci => katilimci.adtEkip?.id)
      .filter(Boolean);

    allChats.push({
      type: 'tumADT',
      data: tumAdtMessage,
      date: tumAdtMessage ? new Date(tumAdtMessage.cDate) : null,
    });

    allChats.push({
      type: 'yonetim',
      data: yonetimMessage,
      date: yonetimMessage ? new Date(yonetimMessage.cDate) : null,
    });

    messageList.forEach(message => {
      allChats.push({
        type: 'dm',
        data: message,
        date: new Date(message?.cDate),
      });
    });

    const filteredTimMessages = filterTimMessagesForParticipant();

    if (!filteredTimMessages || filteredTimMessages.length === 0) {
      participantEkipIds.forEach(equipId => {
        const ekip = krizAdtEkipEntities.find(ekip1 => ekip1.adtEkip?.id === equipId);
        if (ekip) {
          allChats.push({
            type: 'adtTim',
            data: { krizAdtEkip: ekip, icerik: null, cDate: null },
            date: null,
          });
        }
      });
    } else {
      filteredTimMessages.forEach(message => {
        allChats.push({
          type: 'adtTim',
          data: message,
          date: message.cDate ? new Date(message.cDate) : null,
        });
      });
    }

    allChats.sort((a, b) => {
      if (a.date && b.date) {
        return b.date - a.date;
      }
      if (a.date) {
        return -1;
      }
      if (b.date) {
        return 1;
      }
      return 0;
    });

    setCombinedChats(allChats);
  }, [tumAdtMessage, yonetimMessage, messageList, adtTimMessage, krizKatilimciEntities, sicilNo, krizAdtEkipEntities]);

  useEffect(() => {
    getMessageList();
    getMessageCustomList();
  }, [kriz.realId]);

  useEffect(() => {
    if (!isOpenChatDetail || newMessage) {
      dispatch(setNewMessage(false));
      getMessageListReload();
      getMessageCustomListReload();
    }
  }, [isOpenChatDetail, newMessage]);

  const getMessageList = async () => {
    try {
      setLoading(true);
      const krizChatUrl = `api/kriz-chats/get-messages-dm?krizGuid=${kriz.id}`;
      await axios.get(krizChatUrl).then(response => {
        setMessageList(response.data);
        setLoading(false);
      });
    } catch {
      setLoading(false);
    }
  };

  const getMessageCustomList = async () => {
    try {
      setLoading(true);
      const krizChatUrl = `api/kriz-chats/get-messages-custom?krizId.equals=${kriz.id}`;

      await axios
        .get(
          `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Adt}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.TumAdt}`
        )
        .then(response => {
          setTumAdtMessage(response.data);
        });

      for (const krizAdtEkip of krizAdtEkipEntities) {
        await axios
          .get(
            `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Adt}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.BirAdtTimi}&aliciKrizAdtEkipId.equals=${krizAdtEkip.id}`
          )
          .then(response => {
            if (response.data) {
              const newData = { ...response.data, krizAdtEkipId: krizAdtEkip.id, krizAdtEkip };
              setAdtTimMessage(prevData => {
                const mevcutIndex = prevData.findIndex(item => item.krizAdtEkipId === newData.krizAdtEkipId);

                if (mevcutIndex !== -1) {
                  const yeniArray = [...prevData];
                  yeniArray[mevcutIndex] = { ...yeniArray[mevcutIndex], ...newData };
                  return yeniArray;
                } else {
                  return [...prevData, newData];
                }
              });
            }
          });
      }

      await axios
        .get(
          `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Adt}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.Yonetim}`
        )
        .then(response => {
          setYonetimMessage(response.data);
        });
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const getMessageListReload = () => {
    const krizChatUrl = `api/kriz-chats/get-messages-dm?krizGuid=${kriz.id}`;
    axios.get(krizChatUrl).then(response => {
      setMessageList(response.data);
    });
  };

  const getMessageCustomListReload = () => {
    const krizChatUrl = `api/kriz-chats/get-messages-custom?krizId.equals=${kriz.id}`;

    axios
      .get(
        `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Adt}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.TumAdt}`
      )
      .then(response => {
        setTumAdtMessage(response.data);
      });
    for (const krizAdtEkip of krizAdtEkipEntities) {
      axios
        .get(
          `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Adt}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.BirAdtTimi}&aliciKrizAdtEkipId.equals=${krizAdtEkip.id}`
        )
        .then(response => {
          if (response.data) {
            const newData = { ...response.data, krizAdtEkipId: krizAdtEkip.id, krizAdtEkip };
            setAdtTimMessage(prevData => {
              const mevcutIndex = prevData.findIndex(item => item.krizAdtEkipId === newData.krizAdtEkipId);

              if (mevcutIndex !== -1) {
                const yeniArray = [...prevData];
                yeniArray[mevcutIndex] = { ...yeniArray[mevcutIndex], ...newData };
                return yeniArray;
              } else {
                return [...prevData, newData];
              }
            });
          }
        });
    }

    axios
      .get(
        `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Adt}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.Yonetim}`
      )
      .then(response => {
        setYonetimMessage(response.data);
      });
  };

  const chatDetail = (mesajlasmaTipi, messages, mesajAliciTipi, gonderenAdtTim, gonderenSicil) => {
    setSelectedMessageKullanici(messages);
    setSelectedAliciTipi(mesajAliciTipi);
    setSelectedGonderenSicil(gonderenSicil);
    setSelectedGonderenTim(gonderenAdtTim);
    setSelectedMesajlasmaTipi(mesajlasmaTipi);
    setIsOpenChatDetail(true);
    setIsOpenNewChat(false);
  };

  const chatDetailNew = () => {
    setIsOpenChatDetail(true);
    setIsOpenNewChat(true);
    setSelectedAliciTipi(ReferansEnum.MesajAliciTipi.Dm);
  };

  const closeDetailModal = () => {
    setIsOpenChatDetail(false);
  };

  const renderChatItem = (chat, index) => {
    switch (chat.type) {
      case 'tumADT':
        return <TumADT key={`tumADT-${index}`} message={chat.data} />;
      case 'yonetim':
        return <YonetimChat key={`yonetim-${index}`} message={chat.data} />;
      case 'dm':
        return (
          <ADTDmMessageComponent
            krizKatilimciEntities={krizKatilimciEntities}
            key={`dm-${index}`}
            message={chat.data}
            chatDetail={chatDetail}
          />
        );
      case 'adtTim':
        return (
          <KrizADTTimComponent
            krizKatilimciEntities={krizKatilimciEntities}
            krizAdtMessage={chat.data}
            item={chat.data.krizAdtEkip}
            chatDetail={chatDetail}
            key={`adtTim-${index}`}
          />
        );
      default:
        return null;
    }
  };

  const TumADT = ({ message }) => {
    return (
      <Card
        body
        className="cardChat"
        onClick={() => chatDetail(ReferansEnum.MesajlasmaTipi.Adt, message, ReferansEnum.MesajAliciTipi.TumAdt, null, null)}
        style={{
          backgroundColor: '#F2F3F580',
        }}
      >
        <div>
          <img width={40} height={40} style={{ marginRight: '10px' }} src={`${SERVER_URL}/content/images/icon/faUserOvers.svg`} />
        </div>
        <CardText style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <span style={{ fontSize: '20px', color: '#34404F', fontWeight: '500', maxWidth: '70%' }}>
              <Translate contentKey="tk24AdysApp.krizChat.tumADT" />
            </span>
            <span style={{ fontSize: '14px', color: '#919DAC', fontWeight: '400' }}>
              {message && <TextFormat value={message.cDate} type="date" format={APP_DATETIME_FORMAT} />}
            </span>
          </div>

          <span
            style={{
              color: '#919DAC',
              fontSize: '14px',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              WebkitLineClamp: 2,
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
            }}
          >
            {message && messageReplace(krizKatilimciEntities, message.icerik)}
          </span>
        </CardText>
      </Card>
    );
  };

  const YonetimChat = ({ message }) => {
    return (
      <Card
        body
        className="cardChat"
        onClick={() => chatDetail(ReferansEnum.MesajlasmaTipi.Adt, message, ReferansEnum.MesajAliciTipi.Yonetim, null, null)}
        style={{
          backgroundColor: '#F2F3F580',
        }}
      >
        <div>
          <img width={40} height={40} style={{ marginRight: '10px' }} src={`${SERVER_URL}/content/images/icon/faUserOvers.svg`} />
        </div>
        <CardText style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '10px' }}>
            <span style={{ fontSize: '20px', color: '#34404F', fontWeight: '500', maxWidth: '70%' }}>
              <Translate contentKey="tk24AdysApp.krizChat.yonetim" />
            </span>
            <span style={{ fontSize: '14px', color: '#919DAC', fontWeight: '400' }}>
              {message && <TextFormat value={message.cDate} type="date" format={APP_DATETIME_FORMAT} />}
            </span>
          </div>

          <span
            style={{
              color: '#919DAC',
              fontSize: '14px',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              WebkitLineClamp: 2,
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
            }}
          >
            {message && messageReplace(krizKatilimciEntities, message.icerik)}
          </span>
        </CardText>
      </Card>
    );
  };

  return (
    <Container
      className="container-chat-area"
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
      }}
    >
      {loading ? (
        <DrawerLoadingSpinner />
      ) : (
        <>
          {!isOpenChatDetail ? (
            <>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }} className="mb-4">
                <div className="seachiconposition" style={{ width: '100%' }}>
                  <Search className="seachicon" style={{ color: 'grey', marginLeft: '15px' }} />
                  <Input
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    placeholder={translate('entity.action.search')}
                    className="seachinput"
                  />
                </div>
                <div className="text-right chatNewButton">
                  <Button
                    style={{ marginLeft: 10 }}
                    className="create-button w-100"
                    size="sm"
                    data-cy="entityCreateButton"
                    onClick={chatDetailNew}
                  >
                    <FontAwesomeIcon icon="plus" />{' '}
                    <span className="d-none d-md-inline newChatAdd">
                      <Translate contentKey="tk24AdysApp.krizChat.home.createLabel" />
                    </span>
                  </Button>
                </div>
              </div>

              {search.length > 2
                ? combinedChats
                    .filter(chat => {
                      if (chat.type === 'tumADT' || chat.type === 'yonetim') {
                        return (
                          (chat.data && chat.data.icerik?.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr'))) ||
                          translate(`tk24AdysApp.krizChat.${chat.type}`).toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr'))
                        );
                      } else if (chat.type === 'dm') {
                        return (
                          chat.data.icerik?.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr')) ||
                          chat.data.adi?.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr')) ||
                          chat.data.soyadi?.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr')) ||
                          chat.data.unvan?.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr'))
                        );
                      } else if (chat.type === 'adtTim') {
                        return (
                          chat.data.icerik?.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr')) ||
                          chat.data.krizAdtEkip?.adtEkip?.adi?.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr'))
                        );
                      }
                      return false;
                    })
                    .map((chat, index) => renderChatItem(chat, index))
                : combinedChats.map((chat, index) => renderChatItem(chat, index))}
            </>
          ) : (
            <KrizChatDetail
              selectedMessageKullanici={selectedMessageKullanici}
              aliciTipi={selectedAliciTipi}
              isOpenNewChat={isOpenNewChat}
              onClose={closeDetailModal}
              krizAdtEkip={krizAdtEkipEntities}
              mesajlasmaSicil={selectedGonderenSicil}
              mesajlasmaTipi={selectedMesajlasmaTipi}
              selectedGonderenTim={selectedGonderenTim}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default KrizChatAdt;
