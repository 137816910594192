import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'app/component/jhipster';
import { Button, Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useAppSelector } from 'app/config/store';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
} from '@mui/material';
import { IKrizDoldurulanFormlar } from 'app/shared/model/kriz-doldurulan-formlar.model';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import axios from 'axios';
import { toast } from 'react-toastify';
import { IRefAdtFormDoldurulan } from 'app/shared/model/ref-adt-form-doldurulan.model';
import CustomPagination from 'app/component/CustomPagination';
import { SERVER_URL } from 'app/config/constants';
import { isNullOrEmpty } from 'app/shared/util/confirm-utils';

interface IEtkilenenKisiFormKlonProps {
  isKlonModal: boolean;
  handleCloseFormKlonModal: () => void;
  selectedForm?: IKrizDoldurulanFormlar;
  kisiTipiId: number;
  ucusId?: number;
}

const EtkilenenKisiFormKlon: React.FC<IEtkilenenKisiFormKlonProps> = ({
  isKlonModal,
  selectedForm,
  kisiTipiId,
  ucusId,
  handleCloseFormKlonModal,
}) => {
  const kriz = useAppSelector(state => state.kriz.entity);

  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const kisiAltTipiList = useAppSelector(state => state.refKisiAltTipi.entities);
  const [kisiLoading, setKisiLoading] = useState(false);
  const [etkilenenKisiList, setEtkilenenKisiList] = useState(null);
  const [totalItems, setTotalItems] = useState(null);
  const [formFields, setFormFields] = useState<IRefAdtFormDoldurulan>(null);
  const [search, setSearch] = useState('');

  const onLoad = async () => {
    setKisiLoading(true);
    let formDoldurulanUrl;
    if (search.length > 2) {
      formDoldurulanUrl = `api/ref-adt-form-doldurulans/${selectedForm?.guid}`;
    } else if (search.length === 0) {
      formDoldurulanUrl = `api/ref-adt-form-doldurulans/${selectedForm?.guid}`;
    }
    await axios
      .get(formDoldurulanUrl)
      .then(response => {
        setFormFields(response.data);
      })
      .catch(error => {});

    try {
      if (kisiTipiId === ReferansEnum.KisiTipi.Yolcu) {
        const yolcuUrl = `api/ref-yolcus?page=${pageObject.page}&size=${pageObject.size}&sort=${pageObject.sort}&filterType.equals=all&refUcusId.equals=${ucusId}${pageObject.query}`;

        await axios.get(yolcuUrl).then(resp => {
          setEtkilenenKisiList(resp.data);
          setTotalItems(parseInt(resp.headers['x-total-count'], 10));
          setKisiLoading(false);
        });
      } else if (kisiTipiId === ReferansEnum.KisiTipi.Murettebat) {
        let murettebatUrl;
        if (search.length > 2) {
          murettebatUrl = `api/ref-murettebats?page=${pageObject.page}&size=${pageObject.size}&sort=${pageObject.sort}&filterType.equals=all&refUcusId.equals=${ucusId}&globalFilter=${search}`;
        } else if (search.length === 0) {
          murettebatUrl = `api/ref-murettebats?page=${pageObject.page}&size=${pageObject.size}&sort=${pageObject.sort}&filterType.equals=all&refUcusId.equals=${ucusId}`;
        }
        await axios.get(murettebatUrl).then(resp => {
          setEtkilenenKisiList(resp.data);
          setTotalItems(parseInt(resp.headers['x-total-count'], 10));
          setKisiLoading(false);
        });
      } else if (kisiTipiId === ReferansEnum.KisiTipi.EkKisi) {
        let ekKisiUrl;
        if (search.length > 2) {
          ekKisiUrl = `api/kriz-ek-kisis?page=${pageObject.page}&size=${pageObject.size}&sort=${pageObject.sort}&filterType.equals=all&krizId.equals=${kriz?.id}&globalFilter=${search}`;
        } else if (search.length === 0) {
          ekKisiUrl = `api/kriz-ek-kisis?page=${pageObject.page}&size=${pageObject.size}&sort=${pageObject.sort}&filterType.equals=all&krizId.equals=${kriz?.id}`;
        }
        await axios.get(ekKisiUrl).then(resp => {
          setEtkilenenKisiList(resp.data);
          setTotalItems(parseInt(resp.headers['x-total-count'], 10));
          setKisiLoading(false);
        });
      }
    } catch (ex) {
      setKisiLoading(false);
    }
  };

  useEffect(() => {
    onLoad();
  }, [pageObject.page, pageObject.size, pageObject.sort, pageObject.query]);

  const checkFormFilled = kisi => {
    setKisiLoading(true);
    const formDoldurulanUrl = `api/ref-adt-form-doldurulans?krizId.equals=${kriz?.guid}&kisiId.equals=${kisi.guid}&orjFormId.equals=${formFields?.orjForm?.guid}`;
    axios
      .get(formDoldurulanUrl)
      .then(response => {
        if (response.data.length > 0) {
          toast.warning(translate('tk24AdysApp.refAdtForm.formKlonFilled', { params: formFields?.orjForm?.adi }));
          setKisiLoading(false);
        } else {
          const klonObject = { ...formFields, kisi };

          delete klonObject.id;
          delete klonObject.realId;
          delete klonObject.guid;
          // delete klonObject.dolduranKullanici;
          delete klonObject.dolduranKullaniciReferansi;
          delete klonObject.mDate;
          delete klonObject.cDate;
          delete klonObject.uygulama;

          axios
            .post('api/ref-adt-form-doldurulans', klonObject)
            .then(res => {
              toast.success(translate('tk24AdysApp.refAdtForm.doldurulanKlon', { params: formFields?.orjForm?.adi }));
              setKisiLoading(false);
              handleCloseFormKlonModal();
            })
            .catch(error => {
              toast.error(error.message);
              setKisiLoading(false);
            });
        }
      })
      .catch(error => {
        setKisiLoading(false);
      });
  };

  useEffect(() => {
    const keyToUpdate = 'globalFilter';

    if (search.length > 2) {
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => !param.startsWith(keyToUpdate))
        .concat(`${keyToUpdate}=${search}`)
        .join('&');

      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: updatedParams,
      }));
    } else if (search.length === 0 && !isNullOrEmpty(pageObject.query)) {
      const keyToDelete = 'globalFilter';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => !param.startsWith(keyToDelete))
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  }, [search]);

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleSearchChange = event => {
    const newValue = event.target.value;
    const keyToUpdate = 'kisiAltTipiId.equals';

    if (!isNullOrEmpty(newValue)) {
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => !param.startsWith(keyToUpdate))
        .concat(`${keyToUpdate}=${newValue}`)
        .join('&');

      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: updatedParams,
      }));
    } else {
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => !param.startsWith(keyToUpdate))
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  return (
    <Modal isOpen={isKlonModal} className="modal-style-lg" toggle={handleCloseFormKlonModal} centered>
      <ModalHeader data-cy="refKatilimciHeading" toggle={handleCloseFormKlonModal}>
        <p className="modal-title-info">{formFields?.orjForm?.adi}</p>
        <Translate contentKey="entity.action.klonla">Klonla</Translate>
      </ModalHeader>
      <ModalBody>
        <div className="table-responsive" style={{ maxHeight: '500px', minHeight: '500px', overflowY: 'auto', marginTop: 5 }}>
          {kisiLoading && <LoadingSpinner />}

          {etkilenenKisiList !== null && (
            <>
              <div className="search-frame">
                <div className="seachiconposition mt-2">
                  <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
                  <Input
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    placeholder={translate('entity.action.search')}
                    className="search-input"
                  />
                  <Box sx={{ minWidth: 250, marginLeft: 1 }}>
                    <FormControl fullWidth>
                      <InputLabel id="default-kisi-alt-tipi-label" className="select-input-label">
                        <Translate contentKey="tk24AdysApp.ucusKisiListesi.altTip" />
                      </InputLabel>
                      <Select
                        labelId="default-kisi-alt-tipi-label"
                        id="kisiAltTipiId"
                        label={translate('tk24AdysApp.ucusKisiListesi.altTip')}
                        onChange={handleSearchChange}
                        className="select-input"
                      >
                        <MenuItem value="">
                          <Translate contentKey="entity.action.all" />
                        </MenuItem>
                        {kisiAltTipiList &&
                          kisiAltTipiList.length > 0 &&
                          kisiAltTipiList
                            .filter(item => item.kisiTipi?.id === kisiTipiId)
                            .map(item => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.adi}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              </div>

              <TableContainer className="content-table">
                {etkilenenKisiList && etkilenenKisiList.length > 0 ? (
                  <Table aria-label="a dense table">
                    <TableHead className="content-table-title-area">
                      <TableRow className="table-head-row">
                        <TableCell className="table-title-cell hand" style={{ width: 570 }} align="left">
                          <Translate contentKey="tk24AdysApp.ucusKisiListesi.altTip">Alt Tip</Translate>&nbsp;
                        </TableCell>
                        <TableCell className="table-title-cell hand" style={{ width: 570 }} align="left">
                          <Translate contentKey="tk24AdysApp.ucusKisiListesi.adi">Adi</Translate>&nbsp;
                        </TableCell>
                        <TableCell className="table-title-cell hand" style={{ width: 570 }} align="left">
                          <Translate contentKey="tk24AdysApp.ucusKisiListesi.soyadi">Soyadi</Translate>&nbsp;
                        </TableCell>

                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {etkilenenKisiList.map(etkilenenKisi => (
                        <TableRow key={etkilenenKisi.id} className="table-cell-row">
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {etkilenenKisi.kisiAltTipi?.adi}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {etkilenenKisi.adi}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {etkilenenKisi.soyadi}
                          </TableCell>
                          <TableCell className="table-content-cell" align="right">
                            <div className="btn-group flex-btn-group-container">
                              <Button
                                className="edit-button"
                                style={{ marginLeft: 2 }}
                                onClick={() => {
                                  checkFormFilled(etkilenenKisi.kisi);
                                }}
                                size="sm"
                                data-cy="entityEditButton"
                              >
                                <span className="d-none d-md-inline">
                                  <Translate contentKey="entity.action.klonla">Klonla</Translate>
                                </span>
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  !kisiLoading && (
                    <div className="alert alert-warning">
                      <Translate contentKey="tk24AdysApp.refKatilimci.home.notFound">No found</Translate>
                    </div>
                  )
                )}
              </TableContainer>
              <CustomPagination
                currentPage={pageObject.page + 1}
                currentPageSize={pageObject.size}
                totalItems={totalItems}
                handlePageChange={handlePageChange}
              />
            </>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default EtkilenenKisiFormKlon;
