const config = {
  VERSION: process.env.VERSION,
};

export default config;

export const SERVER_API_URL = process.env.SERVER_API_URL;
export const SERVER_URL = process.env.SERVER_URL;
export const APP_ID = process.env.APP_ID;
export const CSRF_TOKEN_REGX = '(?:(?:^|"+".*;\\s*)XSRF-TOKEN"+"\\s*\\=\\s*([^;]*).*$)"+"|^.*$';
export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  ACCOUNT_SETTINGS: 'AYAR0',
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_DATETIME_FORMAT = 'DD/MM/YYYY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_TIMESTAMP_FORMAT_A = 'DD/MM/YYYY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_TIME_FORMAT = 'HH:mm';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_LOCAL_DATETIMEZONE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
export const APP_LOCAL_DATETIME_FORMAT_Z = 'YYYY-MM-DDTHH:mm Z';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';
export const APP_LOCAL_DATE_FORMAT_FOR_UCUS = 'YYYY-MM-DD';

export const modes = {
  VIEW: 0,
  INFO: 1,
  LIST: 2,
  MEASURE: 3,
  EXPORT: 4,
  POLYGON_QUERY: 6,
  POLYGON_QUERY_LIST: 7,
  TEMATIK_MARKER_CLUSTER: 10,
};
