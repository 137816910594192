import React, { useState, useEffect, useCallback } from 'react';
import { Translate, translate } from 'app/component/jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  createEntity,
  deleteEntity,
  getEntities,
  getTotalEntities,
  updateEntity as updateVekilEntity,
} from './ref-katilimci-vekil.reducer';
import { SERVER_URL } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { CustomDeleteEntityConfirm, CustomSaveEntityConfirm } from 'app/shared/util/confirm-utils';
import { IRefKatilimci } from 'app/shared/model/ref-katilimci.model';
import {
  Box,
  Collapse,
  IconButton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Typography,
  Tooltip,
} from '@mui/material';
import { KeyboardArrowDownOutlined, KeyboardArrowRight } from '@mui/icons-material';
import RefKatilimciIletisim from '../ref-katilimci-iletisim/ref-katilimci-iletisim';
import { IRefKatilimciVekil } from 'app/shared/model/ref-katilimci-vekil.model';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import GrantedButton from 'app/component/GrantedButton';
import PersonelSearch from '../personel/personel-search';
import { reset as resetPersonelEntities } from 'app/entities/personel/personel.reducer';
import MatModal from '@mui/material/Modal';
import { modalSortStyle } from 'app/shared/util/style-utils';
import SortList from 'app/component/SortList';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import SortIcon from 'app/component/SortIcon';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import { ModalHeader } from 'reactstrap';
import PersonelImage from 'app/component/PersonelImage';

interface RefKatilimciVekilProp {
  refKatilimci: IRefKatilimci;
  tabId?: number;
}

export const RefKatilimciVekil: React.FC<RefKatilimciVekilProp> = ({ refKatilimci, tabId }) => {
  const dispatch = useAppDispatch();
  const refKatilimciVekilList = useAppSelector(state => state.refKatilimciVekil.entities);
  const loading = useAppSelector(state => state.refKatilimciVekil.loading);
  const updating = useAppSelector(state => state.refKatilimciVekil.updating);
  const totalItems = useAppSelector(state => state.refKatilimciVekil.totalItems);
  const [isRefKatilimciVekilUpdateModal, setIsrefKatilimciVekilUpdateModal] = useState(false);
  const [selectedRefKatilimciVekil, setSelectedrefKatilimciVekil] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [openSortVekilModal, setOpenSortVekilModal] = React.useState(false);
  const [openItemId, setOpenItemId] = useState<number | null>(null);
  const pageVekil = useAppSelector(state => state.paginate.pageObject);
  const [pageObject, setPageObject] = useState<IQueryParams>({ ...pageVekil, sort: `sortIndex,${ASC}` });

  const ExcelSampleData = [
    { key: 'adi', titleContentKey: 'tk24AdysApp.refKatilimci.adi' },
    { key: 'soyadi', titleContentKey: 'tk24AdysApp.refKatilimci.soyadi' },
    { key: 'unvanAdi', titleContentKey: 'tk24AdysApp.refKatilimci.unvanAdi' },
    { key: 'org.adi', titleContentKey: 'tk24AdysApp.refKatilimci.unite' },
    {
      key: 'bilgiSonGuncellenmeZamani',
      format: 'date',
      titleContentKey: 'tk24AdysApp.refKatilimci.bilgiSonGuncellenmeZamani',
    },
    { key: 'org.sirket.adi', titleContentKey: 'tk24AdysApp.refKatilimci.sirket' },
  ];

  useEffect(() => {
    if (tabId === 2) {
      dispatch(
        getEntities({
          ...pageObject,
          query: `katilimciId.equals=${refKatilimci?.id}`,
          size: 1000,
        })
      );
    }
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort, tabId]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}`, size: 1000 });
  };

  const handleCloseUpdateForm = () => {
    if (isRefKatilimciVekilUpdateModal) {
      setIsrefKatilimciVekilUpdateModal(false);
    }
    dispatch(resetPersonelEntities());
  };

  const handleCloseSortVekilModal = () => {
    setOpenSortVekilModal(false);
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const savePersonelKatilimciVekilIslem = personel => {
    saveEntityKatilimciVekil(personel);
  };

  const saveEntityKatilimciVekil = async personel => {
    setIsrefKatilimciVekilUpdateModal(false);
    const isSave = await showSaveConfirmModal();
    if (isSave) {
      const entity = {
        sicil: personel.sicil,
        adi: personel.adi,
        soyadi: personel.soyadi,
        bilgiSonGuncellenmeZamani: personel.bilgiSonGuncellenmeZamani,
        unvanId: personel.unvanId,
        unvanAdi: personel.unvanAdi,
        durum: personel.durum,
        org: refKatilimci.org,
        katilimci: refKatilimci,
        sortIndex:
          selectedRefKatilimciVekil && selectedRefKatilimciVekil.id
            ? selectedRefKatilimciVekil.sortIndex
            : refKatilimciVekilList.length + 1,
      };

      await dispatch(createEntity({ ...pageObject, entity, query: `katilimciId.equals=${refKatilimci?.id}` }));

      handleCloseUpdateForm();
    } else {
      setIsrefKatilimciVekilUpdateModal(true);
    }
  };

  const saveTransfer = async (list: IRefKatilimciVekil[]) => {
    for (const entity of list) {
      await dispatch(
        updateVekilEntity({
          ...pageObject,
          entity,
          query: `katilimciId.equals=${refKatilimci?.id}`,
          sort: `sortIndex,asc`,
        })
      );
    }
    handleCloseSortVekilModal();
  };

  const confirmrefKatilimciVekilDelete = refKatilimciVekil => {
    setSelectedrefKatilimciVekil(refKatilimciVekil);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedRefKatilimciVekil) {
      const entity = { ...pageObject, entity: selectedRefKatilimciVekil, query: `katilimciId.equals=${refKatilimci?.id}` };

      await dispatch(deleteEntity(entity));
      handleDeleteCancel();
    }
  }, [selectedRefKatilimciVekil, dispatch]);

  const handleDeleteCancel = () => {
    setSelectedrefKatilimciVekil(null);
    setIsDeleteModalOpen(false);
  };

  const getKatilimciVekilAccordion = (refKatilimciVekil: IRefKatilimciVekil) => {
    if (openItemId === refKatilimciVekil?.id) {
      setOpenItemId(null);
      setSelectedrefKatilimciVekil(null);
    } else {
      setOpenItemId(refKatilimciVekil?.id);
      setSelectedrefKatilimciVekil(refKatilimciVekil);
    }
  };

  const fetchTotalRefKatilimciVekilData = async (): Promise<IRefKatilimci[]> => {
    try {
      const response = (await dispatch(
        getTotalEntities({
          ...pageObject,
          size: totalItems,
          katilimciId: refKatilimci.id.toString(),
          query: `katilimciId.equals=${refKatilimci?.id}`,
        })
      )) as {
        payload: { data: IRefKatilimci[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const SortModal = () => {
    return (
      <MatModal
        open={openSortVekilModal}
        onClose={handleCloseSortVekilModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="matSortingModal"
      >
        <>
          <Box sx={modalSortStyle}>
            <ModalHeader data-cy="refKatilimciHeading" toggle={handleCloseSortVekilModal}>
              {refKatilimci && (
                <p className="modal-title-info">
                  {refKatilimci?.sicil}
                  {' / '}
                  {refKatilimci?.adi}
                  {''}
                  {refKatilimci?.soyadi}
                </p>
              )}
              <Translate contentKey="tk24AdysApp.refKatilimciVekil.sortIndex" />
            </ModalHeader>
            {refKatilimciVekilList &&
              refKatilimciVekilList.length > 0 &&
              (refKatilimciVekilList !== null ? (
                <SortList
                  onlyForms={refKatilimciVekilList}
                  saveTransfer={saveTransfer}
                  closeModal={handleCloseSortVekilModal}
                  isSort={true}
                />
              ) : (
                <FontAwesomeIcon icon="sync" spin={true} />
              ))}
          </Box>
        </>
      </MatModal>
    );
  };

  function RefKatilimciVekilAccordion({ refKatilimciVekil, isOpen, openTable }) {
    return (
      <>
        <TableRow className="accordion-content-row" sx={{ '& > *': { borderBottom: 'unset' } }} onClick={openTable}>
          <TableCell style={{ width: 48 }}>
            <IconButton aria-label="expand row" size="small" className="accordion-icon">
              {isOpen ? <KeyboardArrowDownOutlined /> : <KeyboardArrowRight />}
            </IconButton>
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {refKatilimciVekil.sicil}
          </TableCell>

          <TableCell className="accordion-content-cell" component="th" scope="row">
            <PersonelImage contentkey={refKatilimciVekil} designKey={false} />
          </TableCell>

          <TableCell className="accordion-content-cell" component="th" scope="row">
            {refKatilimciVekil.adi}
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {refKatilimciVekil.soyadi}
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {refKatilimciVekil.personel ? refKatilimciVekil.personel.unvanAdi : '-'}
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {refKatilimciVekil.org?.adi}
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {refKatilimciVekil.org?.sirket?.adi}
          </TableCell>

          <TableCell align="right" className="sticky-right">
            <div className="btn-group flex-btn-group-container">
              <GrantedButton
                grantedAuthorites={['ref_katilimci_vekil_delete']}
                onClick={event => {
                  event.stopPropagation();
                  setOpenItemId(null);
                  confirmrefKatilimciVekilDelete(refKatilimciVekil);
                }}
                style={{ marginLeft: 10 }}
                className="delete-button"
                size="sm"
                data-cy="entityDeleteButton"
              >
                <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                  <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                </Tooltip>
                <span>
                  <Translate contentKey="entity.action.delete">Delete</Translate>
                </span>
              </GrantedButton>
            </div>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell className="accordion-content-area" colSpan={12}>
            <Collapse in={isOpen} unmountOnExit>
              <Box sx={{ width: '100%', marginTop: '20px' }} className="bgEtkilenenKisi">
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="boxBorder">
                  <RefKatilimciIletisim refKatilimci={refKatilimci} refKatilimciVekil={refKatilimciVekil} />
                </Box>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  return (
    <>
      {(loading || updating) && <LoadingSpinner />}

      <CustomSaveEntityConfirm
        entity={selectedRefKatilimciVekil}
        isOpen={isSaveModalOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />

      {selectedRefKatilimciVekil && (
        <CustomDeleteEntityConfirm
          entity={selectedRefKatilimciVekil}
          entityName="refKatilimciVekil"
          isOpen={isDeleteModalOpen}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}

      {isRefKatilimciVekilUpdateModal && (
        <PersonelSearch
          isPersonelModal={isRefKatilimciVekilUpdateModal}
          titleContentKey="tk24AdysApp.refKatilimciVekil.home.createLabel"
          handleClosePersonelForm={handleCloseUpdateForm}
          saveEntityPersonel={savePersonelKatilimciVekilIslem}
          dataToCompare={refKatilimciVekilList}
          havalimaniFilter={true}
          katilimci={refKatilimci}
        />
      )}

      {openSortVekilModal && SortModal()}

      {refKatilimciVekilList.length > 0 ? (
        <>
          <Typography className="item-total-count" gutterBottom component="div">
            <span className="totalListItemText">
              <Translate contentKey="global.item-total-count" interpolate={{ total: totalItems }} />
            </span>
            <ExcelDownloadButton
              excelSampleData={ExcelSampleData}
              grantedAuthorites={['ref_katilimci_vekil_export']}
              fetchData={fetchTotalRefKatilimciVekilData}
              className={'download-button'}
              exportName={`${refKatilimci?.adi + ' ' + refKatilimci?.soyadi} ${translate('tk24AdysApp.refKatilimciVekil.detail.title')}`}
            />
          </Typography>

          <TableContainer className="content-table">
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow className="accordion-title-row">
                  <TableCell className="accordion-title-cell hand" style={{ width: 48 }} />
                  <TableCell className="accordion-title-cell hand" align="left" onClick={sort('sicil')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refKatilimci.sicilTitle">sicil</Translate>
                      <SortIcon column="sicil" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell className="accordion-title-cell hand" align="left">
                    <Translate contentKey="tk24AdysApp.krizKatilimci.img">img</Translate>&nbsp;
                  </TableCell>
                  <TableCell className="accordion-title-cell hand" align="left" onClick={sort('adi')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refKatilimciVekil.adi">Adi</Translate>
                      <SortIcon column="adi" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell className="accordion-title-cell hand" align="left" onClick={sort('soyadi')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refKatilimciVekil.soyadi">Soyadi</Translate>
                      <SortIcon column="soyadi" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell className="accordion-title-cell hand" align="left" onClick={sort('unvanAdi')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refKatilimciVekil.unvanAdiTitle">Unvan</Translate>
                      <SortIcon column="unvanAdi" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell
                    className="accordion-title-cell hand"
                    align="left"
                    onClick={sort(`orgAdi${upperOnlyFirstLetter(localeLanguage)}`)}
                  >
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refKatilimci.uniteTitle">uniteTitle</Translate>&nbsp;
                      <SortIcon column={`orgAdi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell
                    className="accordion-title-cell hand"
                    align="left"
                    onClick={sort(`org.sirket.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                  >
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refKatilimci.sirket">sirket</Translate>&nbsp;
                      <SortIcon column={`org.sirket.adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell className="sticky-right-header-accordion" />
                </TableRow>
              </TableHead>
              <TableBody>
                {refKatilimciVekilList &&
                  refKatilimciVekilList?.map(refKatilimciVekil => (
                    <RefKatilimciVekilAccordion
                      key={refKatilimciVekil?.id}
                      refKatilimciVekil={refKatilimciVekil}
                      isOpen={openItemId === refKatilimciVekil?.id}
                      openTable={() => getKatilimciVekilAccordion(refKatilimciVekil)}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        !loading && (
          <div className="alert alert-warning">
            <Translate contentKey="tk24AdysApp.refKatilimciVekil.home.notFound">No refKatilimciVekil found</Translate>
          </div>
        )
      )}

      <div className="flex-start-frame">
        <GrantedButton
          grantedAuthorites={['ref_katilimci_vekil_edit']}
          onClick={event => {
            event.stopPropagation();
            setOpenItemId(null);
            setIsrefKatilimciVekilUpdateModal(true);
          }}
          size="sm"
          data-cy="entityCreateButton"
          className="accordion-create-button"
        >
          <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusBlue.svg`} />
          <span className="d-none d-md-inline">
            <Translate contentKey="tk24AdysApp.refKatilimciVekil.home.createLabel" />
          </span>
        </GrantedButton>
        {refKatilimciVekilList.length > 0 && (
          <GrantedButton
            grantedAuthorites={['ref_katilimci_vekil_edit']}
            style={{ marginLeft: '10px' }}
            onClick={event => {
              event.stopPropagation();
              setOpenItemId(null);
              setOpenSortVekilModal(true);
            }}
            size="sm"
            data-cy="entityCreateButton"
            className="accordion-create-button"
          >
            <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusBlue.svg`} />
            <span className="d-none d-md-inline">
              <Translate contentKey="tk24AdysApp.refKatilimciVekil.sortIndex" />
            </span>
          </GrantedButton>
        )}
      </div>
    </>
  );
};

export default RefKatilimciVekil;
