import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IRefKatilimci } from 'app/shared/model/ref-katilimci.model';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import React, { useCallback, useEffect, useState } from 'react';
import { createEntity, deleteEntity, getEntities, getTotalEntities } from './ref-katilimci-ddms-dokuman.reducer';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { HasAuthComponent } from 'app/component/HasAuthComponent';
import { Translate, translate } from 'app/component/jhipster';
import GrantedButton from 'app/component/GrantedButton';
import { CustomDeleteEntityConfirm } from 'app/shared/util/confirm-utils';
import { SERVER_URL } from 'app/config/constants';
import { IRefDDMSDokuman } from 'app/shared/model/ref-ddms-dokuman.model';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import RefKatilimciDdmsDokumanUpdate from './ref-katilimci-ddms-dokuman-update';
import { IRefKatilimciDdmsDokuman } from 'app/shared/model/ref-katilimci-ddms-dokuman.model';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import SortIcon from 'app/component/SortIcon';
import { ASC, DESC } from 'app/shared/util/pagination.constants';

interface RefKatilimciDdmsDokumanProp {
  refKatilimci: IRefKatilimci;
  tabId?: number;
}

const RefKatilimciDdmsDokuman: React.FC<RefKatilimciDdmsDokumanProp> = ({ refKatilimci, tabId }) => {
  const dispatch = useAppDispatch();
  const refDdmsDokumanList = useAppSelector(state => state.refKatilimciDdmsDokuman.entities);
  const totalItems = useAppSelector(state => state.refKatilimciDdmsDokuman.totalItems);
  const refDdmsDokumanLoading = useAppSelector(state => state.refKatilimciDdmsDokuman.loading);
  const refDdmsDokumanUpdating = useAppSelector(state => state.refKatilimciDdmsDokuman.updating);
  const [selectedRefKatilimciDdmsDokuman, setSelectedRefKatilimciDdmsDokuman] = useState(null);
  const [isRefKatilimciDdmsUpdateModal, setIsRefKatilimciDdmsUpdateModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const ExcelSampleData = [
    { key: 'ddmsDokuman.dokumanNo', titleContentKey: 'tk24AdysApp.refKatilimciDdmsDokuman.dokumanNo' },
    { key: 'ddmsDokuman.adi', titleContentKey: 'global.languageGlobal.adi' },
    { key: 'ddmsDokuman.refDdmsDokumanKategori.adi', titleContentKey: 'tk24AdysApp.refDdmsDokuman.kategori' },
    { key: 'ddmsDokuman.revizeNo', titleContentKey: 'tk24AdysApp.refKatilimciDdmsDokuman.revizeNo' },
  ];

  useEffect(() => {
    if (tabId === 3) {
      dispatch(
        getEntities({
          ...pageObject,
          query: `katilimciId.equals=${refKatilimci?.id}`,
          size: 2000,
        })
      );
    }
  }, [pageObject, tabId]);

  const handleCloseDdmsUpdateForm = () => {
    setIsRefKatilimciDdmsUpdateModal(false);
  };

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}`, size: 1000 });
  };

  const addDdmsDokumanList = async (dokuman: IRefDDMSDokuman) => {
    const params = {
      ...pageObject,
      query: `katilimciId.equals=${refKatilimci?.id}`,
      entity: {
        katilimci: refKatilimci,
        ddmsDokuman: dokuman,
      },
    };
    await dispatch(createEntity(params));

    handleCloseDdmsUpdateForm();
  };

  const RefKatilimciDdmsUpdateModal = () => {
    return (
      <Modal isOpen={isRefKatilimciDdmsUpdateModal} toggle={handleCloseDdmsUpdateForm} style={{ maxWidth: '1200px' }} size="lg" centered>
        <ModalHeader data-cy="refKatilimciHeading" toggle={handleCloseDdmsUpdateForm}>
          <p className="modal-title-info">
            {refKatilimci?.sicil}
            {' / '}
            {refKatilimci?.adi} {refKatilimci?.soyadi}
          </p>
          <Translate contentKey="tk24AdysApp.refKatilimciDdmsDokuman.home.createLabel">Create new Ref Ddms Document</Translate>
        </ModalHeader>
        <ModalBody style={{ maxHeight: '600px', height: '600px', color: '#9795B5' }}>
          <RefKatilimciDdmsDokumanUpdate addDdmsDokumanList={addDdmsDokumanList} />
        </ModalBody>
      </Modal>
    );
  };

  const confirmDdmsDokumanDelete = refDdmsDokuman => {
    setSelectedRefKatilimciDdmsDokuman(refDdmsDokuman);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedRefKatilimciDdmsDokuman) {
      const entity = {
        ...pageObject,
        query: `katilimciId.equals=${refKatilimci?.id}`,
        entity: selectedRefKatilimciDdmsDokuman,
      };
      await dispatch(deleteEntity(entity));
      handleDeleteCancel();
    }
  }, [selectedRefKatilimciDdmsDokuman, dispatch]);

  const handleDeleteCancel = () => {
    setSelectedRefKatilimciDdmsDokuman(null);
    setIsDeleteModalOpen(false);
  };

  const fetchTotalRefKatilimciDdmsDokumanData = async (): Promise<IRefKatilimciDdmsDokuman[]> => {
    try {
      const response = (await dispatch(
        getTotalEntities({
          ...pageObject,
          katilimciId: refKatilimci?.id,
          size: 2000,
        })
      )) as { payload: { data: IRefKatilimciDdmsDokuman[] } };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  return (
    <>
      {(refDdmsDokumanLoading || refDdmsDokumanUpdating) && <LoadingSpinner />}
      {selectedRefKatilimciDdmsDokuman && (
        <CustomDeleteEntityConfirm
          entity={selectedRefKatilimciDdmsDokuman}
          entityName="refKatilimciDdmsDokuman"
          isOpen={isDeleteModalOpen}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}
      {isRefKatilimciDdmsUpdateModal && RefKatilimciDdmsUpdateModal()}
      <Box className="accordion-box">
        <HasAuthComponent grantedAuthorites={['ref_katilimci_ddms_dokuman_read']}>
          {totalItems > 0 ? (
            <>
              <Typography className="item-total-count" gutterBottom component="div">
                <span className="totalListItemText">
                  <Translate contentKey="global.item-total-count" interpolate={{ total: totalItems }} />
                </span>
                <ExcelDownloadButton
                  excelSampleData={ExcelSampleData}
                  grantedAuthorites={['ref_katilimci_ddms_dokuman_export']}
                  fetchData={fetchTotalRefKatilimciDdmsDokumanData}
                  className={'download-button'}
                  exportName={`${refKatilimci?.adi + ' ' + refKatilimci?.soyadi} ${translate(
                    'tk24AdysApp.refKatilimciDdmsDokuman.detail.title'
                  )}`}
                />
              </Typography>
              <Table className="accordion-table">
                <TableHead>
                  <TableRow className="accordion-table-title-row">
                    <TableCell
                      className="accordion-table-title-cell"
                      style={{ width: '18%' }}
                      align="left"
                      onClick={sort('ddmsDokuman.kategori')}
                    >
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refDdmsDokuman.kategori">Kategori</Translate>
                        <SortIcon column="ddmsDokuman.kategori" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell
                      className="accordion-table-title-cell"
                      style={{ width: '18%' }}
                      align="left"
                      onClick={sort('ddmsDokuman.dokumanNo')}
                    >
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refKatilimciDdmsDokuman.dokumanNo">Dokuman No</Translate>
                        <SortIcon column="ddmsDokuman.dokumanNo" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell
                      className="accordion-table-title-cell"
                      style={{ width: '18%' }}
                      align="left"
                      onClick={sort('ddmsDokuman.revizeNo')}
                    >
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refKatilimciDdmsDokuman.revizeNoTitle">Revize No</Translate>
                        <SortIcon column="ddmsDokuman.revizeNo" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell
                      className="accordion-table-title-cell"
                      style={{ width: '18%' }}
                      align="left"
                      onClick={sort('ddmsDokuman.adi')}
                    >
                      <div className="tableHeaderContainer">
                        <Translate contentKey="global.languageGlobal.adi">Adi</Translate>
                        <SortIcon column="ddmsDokuman.adi" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>

                    <TableCell className="sticky-right-header-accordion-alt"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {refDdmsDokumanList?.map(refDdmsDokuman => (
                    <TableRow key={refDdmsDokuman.id} className="accordion-table-content-row">
                      <TableCell className="accordion-table-content-cell" component="th" scope="row">
                        {refDdmsDokuman.ddmsDokuman.refDdmsDokumanKategori?.adi}
                      </TableCell>
                      <TableCell className="accordion-table-content-cell" component="th" scope="row">
                        {refDdmsDokuman.ddmsDokuman?.dokumanNo}
                      </TableCell>
                      <TableCell className="accordion-table-content-cell" component="th" scope="row">
                        {refDdmsDokuman.ddmsDokuman?.revizeNo}
                      </TableCell>
                      <TableCell className="accordion-table-content-cell" component="th" scope="row">
                        {refDdmsDokuman.ddmsDokuman?.adi}
                      </TableCell>

                      <TableCell className="sticky-right" component="th" scope="row" align="right">
                        <div className="btn-group flex-btn-group-container">
                          <GrantedButton
                            grantedAuthorites={['ref_katilimci_ddms_dokuman_delete']}
                            onClick={event => {
                              event.stopPropagation();
                              confirmDdmsDokumanDelete(refDdmsDokuman);
                            }}
                            style={{ marginLeft: 5 }}
                            className="delete-button"
                            size="sm"
                            data-cy="entityDeleteButton"
                          >
                            <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                              <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                            </Tooltip>
                            <span className="show-on-lg">
                              <Translate contentKey="entity.action.delete">Delete</Translate>
                            </span>
                          </GrantedButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          ) : (
            !refDdmsDokumanLoading && (
              <div className="alert alert-warning">
                <Translate contentKey="tk24AdysApp.refKatilimciDdmsDokuman.home.notFound">No refKatilimciIletisim found</Translate>
              </div>
            )
          )}

          <div className="flex-start-frame">
            <GrantedButton
              grantedAuthorites={['ref_katilimci_ddms_dokuman_edit']}
              onClick={() => setIsRefKatilimciDdmsUpdateModal(true)}
              size="sm"
              data-cy="entityCreateButton"
              className="accordion-create-button"
            >
              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusBlue.svg`} />
              <span className="d-none d-md-inline">
                <Translate contentKey="tk24AdysApp.refKatilimciDdmsDokuman.home.createLabel" />
              </span>
            </GrantedButton>
          </div>
        </HasAuthComponent>
      </Box>
    </>
  );
};

export default RefKatilimciDdmsDokuman;
