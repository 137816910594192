import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Col, Input, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { getEntities, getTotalEntities } from './ref-ucak.reducer';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { getReferansByTip } from '../referans/referans.reducer';
import { isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { IRefUcak } from 'app/shared/model/ref-ucak.model';
import {
  Box,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import CustomPagination from 'app/component/CustomPagination';
import GrantedButton from 'app/component/GrantedButton';
import { SERVER_URL } from 'app/config/constants';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import RefUcakUpdate from './ref-ucak-update';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import SortIcon from 'app/component/SortIcon';
import classnames from 'classnames';
import { KeyboardArrowRight, KeyboardArrowDownOutlined } from '@mui/icons-material';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import { getFilteredEntities as getFilterUcakEkDosyaEntities } from 'app/entities/ek-dosya/ek-dosya.reducer';
import TableNameEnum from 'app/constants/TableNameEnum';
import axios from 'axios';
import { customFilterValues } from 'app/shared/util/get-custom-filter';
import ExcelSheetDownloadButton from 'app/component/ExcelSheetDownload';

export const RefUcak = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();
  const refUcakList = useAppSelector(state => state.refUcak.entities);
  const loading = useAppSelector(state => state.refUcak.loading);
  const updating = useAppSelector(state => state.refUcak.updating);
  const totalItem = useAppSelector(state => state.refUcak.totalItems);
  const refEvetHayirList = useAppSelector(state => state.referans.entities);
  const [isEntityUpdateModal, setIsEntityUpdateModal] = useState(false);
  const [search, setSearch] = useState('');
  const [openItemId, setOpenItemId] = useState<number | null>(null);
  const [activeTab, setActiveTab] = useState(1);
  const [selectedRefUcak, setSelectedRefUcak] = useState(null);
  const ucakEkDosyaLoading = useAppSelector(state => state.ekDosya.loading);
  const [selectedUcakInventory, setSelectedUcakInventory] = useState([]);
  const [selectedUcakBakim, setSelectedUcakBakim] = useState([]);
  const [selectedUcakAcMaster, setSelectedUcakAcMaster] = useState([]);
  const [selectedUcakDefect, setSelectedUcakDefect] = useState([]);
  const [filterLoading, setFilterLoading] = useState(false);
  const [ureticiList, setUreticiList] = useState<string[]>(null);
  const [anaTipiList, setAnaTipiList] = useState<string[]>(null);
  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const ExcelSampleData = [
    { key: 'kuyrukKodu', titleContentKey: 'tk24AdysApp.refUcak.kuyrukKodu' },
    { key: 'filo', titleContentKey: 'tk24AdysApp.refUcak.filo' },
    { key: 'anaTipi', titleContentKey: 'tk24AdysApp.refUcak.anaTipi' },
    { key: 'altTipi', titleContentKey: 'tk24AdysApp.refUcak.altTipi' },
    { key: 'detayTipi', titleContentKey: 'tk24AdysApp.refUcak.detayTipi' },
    { key: 'adi', titleContentKey: 'tk24AdysApp.refUcak.adi' },
    { key: 'uretici', titleContentKey: 'tk24AdysApp.refUcak.uretici' },
    { key: 'govdeTipi', titleContentKey: 'tk24AdysApp.refUcak.govdeTipi' },
    { key: 'starAllianceBoyasiVarMi.adi', titleContentKey: 'tk24AdysApp.refUcak.starAllianceBoyasiVarMi' },
  ];

  const getUcakDetail = async () => {
    if (selectedRefUcak) {
      const ucusDetailList = `api/ref-ucaks/export?id.equals=${selectedRefUcak.realId}`;
      const response = await axios.get(ucusDetailList);
      return response.data;
    } else {
      return null;
    }
  };

  const getUcakAcMaster = async () => {
    if (selectedRefUcak) {
      const ucusDetailList = `api/ref-ucak-ac-masters/export?ucakId.equals=${selectedRefUcak.realId}`;
      const response = await axios.get(ucusDetailList);
      return response.data;
    } else {
      return null;
    }
  };

  const getUcakInventory = async () => {
    if (selectedRefUcak) {
      const ucusDetailList = `api/ref-ucak-pn-inventory-details/export?ucakId.equals=${selectedRefUcak.realId}`;
      const response = await axios.get(ucusDetailList);
      return response.data;
    } else {
      return null;
    }
  };

  const getUcakBakim = async () => {
    if (selectedRefUcak) {
      const ucusDetailList = `api/ref-ucak-engineering-compliance/export?ucakId.equals=${selectedRefUcak.realId}`;
      const response = await axios.get(ucusDetailList);
      return response.data;
    } else {
      return null;
    }
  };

  const getUcakDefect = async () => {
    if (selectedRefUcak) {
      const ucusDetailList = `api/ref-ucak-defect-reports/export?ucakId.equals=${selectedRefUcak.realId}`;
      const response = await axios.get(ucusDetailList);
      return response.data;
    } else {
      return null;
    }
  };

  const ExcelDetailSheetData = [
    {
      name: translate('tk24AdysApp.refUcak.detay'),
      fetchData: getUcakDetail,
      excelSampleData: [
        { key: 'adi', titleContentKey: 'tk24AdysApp.refUcak.adi' },
        { key: 'anaTipi', titleContentKey: 'tk24AdysApp.refUcak.anaTipi' },
        { key: 'altTipi', titleContentKey: 'tk24AdysApp.refUcak.altTipi' },
        { key: 'detayTipi', titleContentKey: 'tk24AdysApp.refUcak.detayTipi' },
        { key: 'govdeTipi', titleContentKey: 'tk24AdysApp.refUcak.govdeTipi' },
        { key: 'uretici', titleContentKey: 'tk24AdysApp.refUcak.uretici' },
        { key: 'lopaImagePath', titleContentKey: 'tk24AdysApp.refUcak.lopaImagePath' },
      ],
    },
    {
      name: translate('tk24AdysApp.refUcak.planeInfo'),
      fetchData: getUcakAcMaster,
      excelSampleData: [
        { key: 'acSn', titleContentKey: 'tk24AdysApp.refUcak.acMaster.sn' },
        { key: 'acFlightHours', titleContentKey: 'tk24AdysApp.refUcak.acMaster.flightHours' },
        { key: 'acFlightMinutes', titleContentKey: 'tk24AdysApp.refUcak.acMaster.flightMins' },
        { key: 'acCycles', titleContentKey: 'tk24AdysApp.refUcak.acMaster.cycles' },
        { key: 'manufactureDate', titleContentKey: 'tk24AdysApp.refUcak.acMaster.manifactureDate' },
        { key: 'maxToWeight', titleContentKey: 'tk24AdysApp.refUcak.acMaster.maxToWeight' },
        { key: 'catRating', titleContentKey: 'tk24AdysApp.refUcak.acMaster.catRating' },
        { key: 'selcal', titleContentKey: 'tk24AdysApp.refUcak.acMaster.selcal' },
        { key: 'modeS', titleContentKey: 'tk24AdysApp.refUcak.acMaster.modeS' },
        { key: 'maxLandingWeight', titleContentKey: 'tk24AdysApp.refUcak.acMaster.maxLandingWeight' },
        { key: 'maxLandingWeightUnits', titleContentKey: 'tk24AdysApp.refUcak.acMaster.maxLandingWeightUnits' },
        { key: 'maxZeroFuelWeight', titleContentKey: 'tk24AdysApp.refUcak.acMaster.maxZeroFuelWeight' },
        { key: 'maxZeroFuelWeightUnits', titleContentKey: 'tk24AdysApp.refUcak.acMaster.maxZeroFuelWeightUnits' },
        { key: 'basicEmptyWeight', titleContentKey: 'tk24AdysApp.refUcak.acMaster.basicEmptyWeight' },
        { key: 'basicEmptyWeightUnits', titleContentKey: 'tk24AdysApp.refUcak.acMaster.basicEmptyWeightUnits' },
        { key: 'totalMoment', titleContentKey: 'tk24AdysApp.refUcak.acMaster.totalMoment' },
        { key: 'engineModel', titleContentKey: 'tk24AdysApp.refUcak.acMaster.engineModel' },
      ],
    },
    {
      name: translate('tk24AdysApp.refUcak.planeEngineInfo'),
      fetchData: getUcakInventory,
      excelSampleData: [
        { key: 'pn', titleContentKey: 'tk24AdysApp.refUcak.inventory.pn' },
        { key: 'sn', titleContentKey: 'tk24AdysApp.refUcak.inventory.sn' },
        { key: 'installedPosition', titleContentKey: 'tk24AdysApp.refUcak.inventory.pos' },
        { key: 'installedDate', format: 'date', titleContentKey: 'tk24AdysApp.refUcak.inventory.date' },
      ],
    },
    {
      name: translate('tk24AdysApp.refUcak.planeAllEngineInfo'),
      fetchData: getUcakBakim,
      excelSampleData: [
        { key: 'eo', titleContentKey: 'tk24AdysApp.refUcak.compliance.eo' },
        { key: 'resetDate', format: 'date', titleContentKey: 'tk24AdysApp.refUcak.compliance.date' },
      ],
    },
    {
      name: translate('tk24AdysApp.refUcak.planeDefectInfo'),
      fetchData: getUcakDefect,
      excelSampleData: [
        { key: 'defectType', titleContentKey: 'tk24AdysApp.refUcak.defect.type' },
        { key: 'defect', titleContentKey: 'tk24AdysApp.refUcak.defect.no' },
        { key: 'status', titleContentKey: 'tk24AdysApp.refUcak.defect.status' },
        { key: 'chapter', titleContentKey: 'tk24AdysApp.refUcak.defect.chapter' },
        { key: 'section', titleContentKey: 'tk24AdysApp.refUcak.defect.section' },
        { key: 'defectDescription', titleContentKey: 'tk24AdysApp.refUcak.defect.description' },
        { key: 'mel', titleContentKey: 'tk24AdysApp.refUcak.defect.mel' },
        { key: 'melNumber', titleContentKey: 'tk24AdysApp.refUcak.defect.melNumber' },
        { key: 'reportedDate', format: 'date', titleContentKey: 'tk24AdysApp.refUcak.defect.reportedDate' },
        { key: 'resolutionDescription', titleContentKey: 'tk24AdysApp.refUcak.defect.resolutionDescription' },
        { key: 'resolvedDate', format: 'date', titleContentKey: 'tk24AdysApp.refUcak.defect.resolvedDate' },
        { key: 'deferCategory', titleContentKey: 'tk24AdysApp.refUcak.defect.category' },
        { key: 'station', titleContentKey: 'tk24AdysApp.refUcak.defect.station' },
        { key: 'reliabilityControl', titleContentKey: 'tk24AdysApp.refUcak.defect.control' },
        { key: 'resolvedLocation', titleContentKey: 'tk24AdysApp.refUcak.defect.resolvedLocation' },
        { key: 'deferNotes', titleContentKey: 'tk24AdysApp.refUcak.defect.notes' },
      ],
    },
  ];

  const getAllEntities = () => {
    dispatch(getEntities(pageObject));
  };

  useEffect(() => {
    dispatch(getReferansByTip('EVET_HAYIR'));
  }, []);

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${search}`,
      }));
    } else if (search.length === 0 && !isNullOrEmpty(pageObject.query)) {
      const keyToDelete = 'globalFilter';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  }, [search]);

  useEffect(() => {
    getUreticiFilterData();
    getAnatipiFilterData();
  }, []);

  const getUreticiFilterData = async () => {
    setFilterLoading(false);
    const response = await customFilterValues('RefUcakUretici');
    setUreticiList(response);
    setFilterLoading(false);
  };

  const getAnatipiFilterData = async () => {
    setFilterLoading(false);
    const response = await customFilterValues('RefUcakAnaTipi');
    setAnaTipiList(response);
    setFilterLoading(false);
  };

  const getEkDosya = () => {
    dispatch(
      getFilterUcakEkDosyaEntities({
        ilgiliTabloKayitId: selectedRefUcak?.realId,
        ilgiliTablo: TableNameEnum.RefUcak,
      })
    );
  };

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const formatDate = (dateString: string | null) => {
    if (dateString === null) {
      return null;
    }

    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const ucakInventory = kuyrukKodu => {
    const ucusDetailList = `api/ref-ucak-pn-inventory-details?ucakId.equals=${kuyrukKodu}`;
    axios
      .get(ucusDetailList)
      .then(response => {
        setSelectedUcakInventory(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };
  const ucakBakim = kuyrukKodu => {
    const ucusDetailList = `api/ref-ucak-engineering-compliance?ucakId.equals=${kuyrukKodu}`;
    axios
      .get(ucusDetailList)
      .then(response => {
        setSelectedUcakBakim(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };
  const ucakAcMaster = kuyrukKodu => {
    const ucusDetailList = `api/ref-ucak-ac-masters?ucakId.equals=${kuyrukKodu}`;
    axios
      .get(ucusDetailList)
      .then(response => {
        setSelectedUcakAcMaster(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };
  const ucakDefect = kuyrukKodu => {
    const ucusDetailList = `api/ref-ucak-defect-reports?ucakId.equals=${kuyrukKodu}`;
    axios
      .get(ucusDetailList)
      .then(response => {
        setSelectedUcakDefect(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  const handleCloseUpdateForm = () => {
    if (isEntityUpdateModal) {
      setIsEntityUpdateModal(false);
    }
    setSelectedRefUcak(null);
  };

  const fetchTotalData = async (): Promise<IRefUcak[]> => {
    try {
      const response = (await dispatch(getTotalEntities({ ...pageObject, size: totalItem }))) as {
        payload: { data: IRefUcak[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const RefUcakUpdateModal = () => {
    return (
      <RefUcakUpdate
        formValues={selectedRefUcak}
        handleCloseForm={handleCloseUpdateForm}
        isOpen={isEntityUpdateModal}
        refEvetHayirList={refEvetHayirList}
        pageObject={pageObject}
      >
        {' '}
      </RefUcakUpdate>
    );
  };

  const handleUriticiChange = event => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&uretici.contains=${event.target.value}`,
      }));
    } else {
      const keyToDelete = 'uretici.contains';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  const handleAnaTipiChange = event => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&anaTipi.contains=${event.target.value}`,
      }));
    } else {
      const keyToDelete = 'anaTipi.contains';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  const handleStarAllianceChange = event => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&starAllianceBoyasiVarMi.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = 'starAllianceBoyasiVarMi.equals';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  const handleChangeAccordion = (refUcak: IRefUcak) => {
    if (openItemId === refUcak?.id) {
      setOpenItemId(null);
      setSelectedUcakInventory([]);
      setSelectedUcakBakim([]);
      setSelectedUcakDefect([]);
      setSelectedUcakAcMaster([]);
      setActiveTab(1);
    } else {
      setOpenItemId(refUcak?.id);
      setSelectedRefUcak(refUcak);
      setActiveTab(1);
    }
  };

  const Detay = () => {
    const fieldsToDisplay = ['adi', 'anaTipi', 'altTipi', 'detayTipi', 'govdeTipi', 'uretici', 'lopaImagePath'];

    return (
      <div style={{ backgroundColor: 'white' }}>
        {selectedRefUcak &&
          fieldsToDisplay.map(
            key =>
              !isNullOrEmpty(selectedRefUcak[key]) && (
                <Row style={{ margin: '15px 0px' }} key={key}>
                  <Col sm={3}>
                    <p className="detailKeyStyle">
                      <Translate contentKey={`tk24AdysApp.refUcak.${key}`} />
                    </p>
                  </Col>
                  <Col sm={9}>
                    <p className="detailValStyle">{selectedRefUcak[key]}</p>
                  </Col>
                </Row>
              )
          )}
      </div>
    );
  };

  const UcakListe = ({ selectedKrizUcakItem }) => {
    const filteredUcakInventory = selectedUcakInventory.filter(item => item.ucak?.kuyrukKodu === selectedKrizUcakItem.kuyrukKodu);
    return (
      <div style={{ backgroundColor: 'white' }}>
        <TableContainer className="content-table">
          <Table aria-label="collapsible table" className="tableListLower">
            <TableHead style={{ backgroundColor: '#dadee3' }}>
              <TableRow className="accordion-title-row">
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.inventory.pn">pn</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.inventory.sn">sn</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.inventory.pos">pos</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.inventory.date">date</Translate>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUcakInventory.length > 0 ? (
                filteredUcakInventory.map((item, index) => (
                  <TableRow key={index} className="accordion-content-row ">
                    <TableCell className="accordion-content-cell" component="th" scope="row">
                      {item.pn}
                    </TableCell>
                    <TableCell className="accordion-content-cell" component="th" scope="row">
                      {item.sn}
                    </TableCell>
                    <TableCell className="accordion-content-cell" component="th" scope="row">
                      {item.installedPosition}
                    </TableCell>
                    <TableCell className="accordion-content-cell" component="th" scope="row">
                      {formatDate(item.installedDate)}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} className="accordion-content-cell">
                    <div className="alert alert-warning" style={{ marginBottom: 0 }}>
                      <Translate contentKey="tk24AdysApp.refUcak.home.notPlaneEngineInfoFound">No data available</Translate>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  const UcakBakimListe = ({ selectedKrizUcakItem }) => {
    const filteredUcakInventory = selectedUcakBakim.filter(item => item.ucak?.kuyrukKodu === selectedKrizUcakItem.kuyrukKodu);
    return (
      <div style={{ backgroundColor: 'white' }}>
        <TableContainer className="content-table">
          <Table aria-label="collapsible table" className="tableListLower">
            <TableHead style={{ backgroundColor: '#dadee3' }}>
              <TableRow className="accordion-title-row">
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.compliance.eo">eo</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.compliance.date">date</Translate>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUcakInventory.length > 0 ? (
                filteredUcakInventory.map((item, index) => (
                  <TableRow key={index} className="accordion-content-row ">
                    <TableCell className="accordion-content-cell" component="th" scope="row">
                      {item.eo}
                    </TableCell>
                    <TableCell className="accordion-content-cell" component="th" scope="row">
                      {formatDate(item.resetDate)}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={2} className="accordion-content-cell">
                    <div className="alert alert-warning" style={{ marginBottom: 0 }}>
                      <Translate contentKey="tk24AdysApp.refUcak.home.notPlaneAllEngineInfoFound">No data available</Translate>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  const UcakAcMasterListe = ({ selectedKrizUcakItem }) => {
    const filteredUcakInventory = selectedUcakAcMaster.filter(item => item.ucak?.kuyrukKodu === selectedKrizUcakItem.kuyrukKodu);
    return (
      <div style={{ backgroundColor: 'white' }}>
        <TableContainer className="content-table">
          <Table aria-label="collapsible table" className="tableListLower">
            <TableHead style={{ backgroundColor: '#dadee3' }}>
              <TableRow className="accordion-title-row">
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.sn">Uçak MSN</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.flightHours">Uçuş Saati(saat)</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.flightMins">Uçuş Saati(dakika)</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.cycles">İniş Sayısı</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.manifactureDate">Uçak İmal Tarihi</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.maxToWeight">Maximum Kalkış Ağırlığı</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.catRating">Cat Oranı</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.selcal">SEL CODE</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.modeS">MODE S</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.maxLandingWeight">Maximum İniş Ağırlığı</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.maxLandingWeightUnits">Maximum İniş Ağırlığı Birimi</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.maxZeroFuelWeight">Maximum Yakıtsız Ağırlığı</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.maxZeroFuelWeightUnits">Maximum Yakıtsız Ağırlığı Birimi</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.basicEmptyWeight">Temel Boş Ağırlığı</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.basicEmptyWeightUnits">Temel Boş Ağırlığı Birimi</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.totalMoment">Toplam Moment</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.engineModel">Motor Tipi</Translate>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUcakInventory.length > 0 ? (
                filteredUcakInventory.map((item, index) => (
                  <TableRow key={index} className="accordion-content-row ">
                    <TableCell className="accordion-content-cell" component="th" scope="row">
                      {item.acSn}
                    </TableCell>
                    <TableCell className="accordion-content-cell">{item.acFlightHours}</TableCell>
                    <TableCell className="accordion-content-cell">{item.acFlightMinutes}</TableCell>
                    <TableCell className="accordion-content-cell">{item.acCycles}</TableCell>
                    <TableCell className="accordion-content-cell">{formatDate(item.manufactureDate)}</TableCell>
                    <TableCell className="accordion-content-cell">{item.maxToWeight}</TableCell>
                    <TableCell className="accordion-content-cell">{item.catRating}</TableCell>
                    <TableCell className="accordion-content-cell">{item.selcal}</TableCell>
                    <TableCell className="accordion-content-cell">{item.modeS}</TableCell>
                    <TableCell className="accordion-content-cell">{item.maxLandingWeight}</TableCell>
                    <TableCell className="accordion-content-cell">{item.maxLandingWeightUnits}</TableCell>
                    <TableCell className="accordion-content-cell">{item.maxZeroFuelWeight}</TableCell>
                    <TableCell className="accordion-content-cell">{item.maxZeroFuelWeightUnits}</TableCell>
                    <TableCell className="accordion-content-cell">{item.basicEmptyWeight}</TableCell>
                    <TableCell className="accordion-content-cell">{item.basicEmptyWeightUnits}</TableCell>
                    <TableCell className="accordion-content-cell">{item.totalMoment}</TableCell>
                    <TableCell className="accordion-content-cell">{item.engineModel}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={17} className="accordion-content-cell">
                    <div className="alert alert-warning" style={{ marginBottom: 0 }}>
                      <Translate contentKey="tk24AdysApp.refUcak.home.notPlaneInfoFound">No data available</Translate>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  const UcakDefectListe = ({ selectedKrizUcakItem }) => {
    const filteredUcakInventory = selectedUcakDefect.filter(item => item.ucak?.kuyrukKodu === selectedKrizUcakItem.kuyrukKodu);
    return (
      <div style={{ backgroundColor: 'white' }}>
        <TableContainer className="content-table">
          <Table aria-label="collapsible table" className="tableListLower">
            <TableHead style={{ backgroundColor: '#dadee3' }}>
              <TableRow className="accordion-title-row">
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.defect.type">Defect Tipi</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.defect.no">Defect No</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.defect.status">Durumu</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.defect.chapter">Chapter</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.defect.section">Section</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.defect.description">Açıklama</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.defect.mel">Mel Cat</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.defect.melNumber">Mel No</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.defect.reportedDate">Raporlanma Tarihi</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.defect.resolutionDescription">Çözüm Açıklama</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.defect.resolvedDate">Arızanın Giderilme Tarihi</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.defect.category">Kategori</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.defect.station">Arızanın Oluştuğu Meydan</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.defect.control">Güvenirlik Kontrol</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.defect.resolvedLocation">Arızanın Giderildiği Meydan</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.defect.notes">Not</Translate>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUcakInventory.length > 0 ? (
                filteredUcakInventory.map((item, index) => (
                  <TableRow key={index} className="accordion-content-row ">
                    <TableCell className="accordion-content-cell" component="th" scope="row">
                      {item.defectType}
                    </TableCell>
                    <TableCell className="accordion-content-cell">{item.defect}</TableCell>
                    <TableCell className="accordion-content-cell">{item.status}</TableCell>
                    <TableCell className="accordion-content-cell">{item.chapter}</TableCell>
                    <TableCell className="accordion-content-cell">{item.section}</TableCell>
                    <TableCell className="accordion-content-cell">{item.defectDescription}</TableCell>
                    <TableCell className="accordion-content-cell">{item.mel}</TableCell>
                    <TableCell className="accordion-content-cell">{item.melNumber}</TableCell>
                    <TableCell className="accordion-content-cell">{formatDate(item.reportedDate)}</TableCell>
                    <TableCell className="accordion-content-cell">{item.resolutionDescription}</TableCell>
                    <TableCell className="accordion-content-cell">{formatDate(item.resolvedDate)}</TableCell>
                    <TableCell className="accordion-content-cell">{item.deferCategory}</TableCell>
                    <TableCell className="accordion-content-cell">{item.station}</TableCell>
                    <TableCell className="accordion-content-cell">{item.reliabilityControl}</TableCell>
                    <TableCell className="accordion-content-cell">{item.resolvedLocation}</TableCell>
                    <TableCell className="accordion-content-cell">{item.deferNotes}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={16} className="accordion-content-cell">
                    <div className="alert alert-warning" style={{ marginBottom: 0 }}>
                      <Translate contentKey="tk24AdysApp.refUcak.home.notPlaneDefectFound">No data available</Translate>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  return (
    <div>
      {(loading || updating || ucakEkDosyaLoading || filterLoading) && <LoadingSpinner />}

      <div style={{ display: 'flex', gap: 8, textAlign: 'center' }}>
        <p className="icon-text">
          <a className="link-a-text" href={`${SERVER_URL}`}>
            <img width={13} height={13} src={`${SERVER_URL}/content/images/icon/faHouse.svg`} />
          </a>
        </p>
        <p className="separator"> / </p>
        <p className="link-text">
          {' '}
          <Translate contentKey="tk24AdysApp.refUcak.home.title" />{' '}
        </p>
      </div>

      <h2 id="ref-ucak-heading" data-cy="RefUcakHeading" className="title-frame">
        <a className="link-a-text" id={'goBack'} onClick={() => props.history.goBack()}>
          <img width={27.5} height={22.5} src={`${SERVER_URL}/content/images/icon/faArrowLeft.svg`} />{' '}
        </a>
        <p className="title-text">
          <Translate contentKey="tk24AdysApp.refUcak.home.title" />
        </p>
      </h2>

      <div className="content-area">
        <div className="search-frame">
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={translate('entity.action.search')}
              className="search-input"
              id={'global-search'}
            />
          </div>

          <div className="create-area">
            <Box sx={{ minWidth: 220 }}>
              <FormControl fullWidth>
                <InputLabel id="default-uretici-label" className="select-input-label">
                  <Translate contentKey="tk24AdysApp.krizUcak.uretici" />
                </InputLabel>
                <Select
                  labelId="uretici-label"
                  id="uretici"
                  label={translate('tk24AdysApp.krizUcak.uretici')}
                  onChange={handleUriticiChange}
                  className="select-input"
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {ureticiList &&
                    ureticiList.map(item => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ minWidth: 220 }}>
              <FormControl fullWidth>
                <InputLabel id="default-anaTipi-label" className="select-input-label">
                  <Translate contentKey="tk24AdysApp.krizUcak.anaTipi" />
                </InputLabel>
                <Select
                  labelId="anaTipi-label"
                  id="anaTipi"
                  label={translate('tk24AdysApp.krizUcak.anaTipi')}
                  onChange={handleAnaTipiChange}
                  className="select-input"
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {anaTipiList &&
                    anaTipiList.map(item => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ minWidth: 250 }}>
              <FormControl fullWidth>
                <InputLabel id="default-starAllianceBoyasiVarMi-label" className="select-input-label">
                  <Translate contentKey="tk24AdysApp.refUcak.starAllianceBoyasiVarMi" />
                </InputLabel>
                <Select
                  labelId="starAllianceBoyasiVarMi-label"
                  id="starAllianceBoyasiVarMi"
                  label={translate('tk24AdysApp.refUcak.starAllianceBoyasiVarMi')}
                  onChange={handleStarAllianceChange}
                  className="select-input"
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {refEvetHayirList &&
                    refEvetHayirList.length > 0 &&
                    refEvetHayirList.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.adi}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
            <ExcelDownloadButton
              excelSampleData={ExcelSampleData}
              fetchData={fetchTotalData}
              className={'download-button'}
              exportName={translate('tk24AdysApp.refUcak.home.title')}
              grantedAuthorites={['ref_ucak_export']}
            />
          </div>
        </div>

        <RefUcakUpdateModal />
        <div className="table-responsive" style={{ marginTop: 5 }}>
          <TableContainer className="content-table">
            {refUcakList && refUcakList.length > 0 ? (
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow className="accordion-title-row">
                    <TableCell className="accordion-title-cell hand" style={{ width: 48 }} />
                    <TableCell id={'global-sort'} align="left" className="accordion-title-cell hand" onClick={sort('kuyrukKodu')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refUcak.kuyrukKoduTitle">Kuyruk Kodu</Translate>&nbsp;
                        <SortIcon column="kuyrukKodu" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell align="left" className="accordion-title-cell hand" onClick={sort('filo')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refUcak.filo">Filo</Translate>&nbsp;
                        <SortIcon column="filo" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell align="left" className="accordion-title-cell hand" onClick={sort('anaTipi')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refUcak.anaTipiTitle">anaTipi</Translate>&nbsp;
                        <SortIcon column="anaTipi" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell align="left" className="accordion-title-cell hand" onClick={sort('altTipi')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refUcak.altTipi">altTipi</Translate>&nbsp;
                        <SortIcon column="altTipi" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell align="left" className="accordion-title-cell hand" onClick={sort('detayTipi')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refUcak.detayTipiTitle">detayTipi</Translate>&nbsp;
                        <SortIcon column="detayTipi" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell align="left" className="accordion-title-cell hand" onClick={sort('adi')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refUcak.adi">adi</Translate>&nbsp;
                        <SortIcon column="adi" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell align="left" className="accordion-title-cell hand" onClick={sort('uretici')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refUcak.uretici">uretici</Translate>&nbsp;
                        <SortIcon column="uretici" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell align="left" className="accordion-title-cell hand" onClick={sort('govdeTipi')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refUcak.govdeTipi">govdeTipi</Translate>&nbsp;
                        <SortIcon column="govdeTipi" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell
                      style={{ width: 200 }}
                      align="left"
                      className="accordion-title-cell hand"
                      onClick={sort(`starAllianceBoyasiVarMi.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                    >
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refUcak.starAllianceBoyasiVarMiTitle">star</Translate>&nbsp;
                        <SortIcon
                          column={`starAllianceBoyasiVarMi.adi${upperOnlyFirstLetter(localeLanguage)}`}
                          activeSortColumn={pageObject.sort}
                        />
                      </div>
                    </TableCell>
                    <TableCell className="sticky-right-header-accordion"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {refUcakList?.map(refUcak => (
                    <>
                      <TableRow
                        className="accordion-content-row"
                        sx={{ '& > *': { borderBottom: 'unset' } }}
                        onClick={() => {
                          handleChangeAccordion(refUcak);
                        }}
                      >
                        <TableCell className="accordion-content-cell" style={{ width: 48 }}>
                          <IconButton aria-label="expand row" size="small" className="accordion-icon">
                            {openItemId === refUcak?.id ? <KeyboardArrowDownOutlined /> : <KeyboardArrowRight />}
                          </IconButton>
                        </TableCell>
                        <TableCell component="th" scope="row" className="accordion-content-cell">
                          {refUcak.kuyrukKodu}
                        </TableCell>
                        <TableCell component="th" scope="row" className="accordion-content-cell">
                          {refUcak.filo}
                        </TableCell>
                        <TableCell component="th" scope="row" className="accordion-content-cell">
                          {refUcak.anaTipi}
                        </TableCell>
                        <TableCell component="th" scope="row" className="accordion-content-cell">
                          {refUcak.altTipi}
                        </TableCell>
                        <TableCell component="th" scope="row" className="accordion-content-cell">
                          {refUcak.detayTipi}
                        </TableCell>
                        <TableCell component="th" scope="row" className="accordion-content-cell">
                          {refUcak.adi}
                        </TableCell>
                        <TableCell component="th" scope="row" className="accordion-content-cell">
                          {refUcak.uretici}
                        </TableCell>
                        <TableCell component="th" scope="row" className="accordion-content-cell">
                          {refUcak.govdeTipi}
                        </TableCell>
                        <TableCell component="th" scope="row" className="accordion-content-cell">
                          {refUcak.starAllianceBoyasiVarMi ? refUcak.starAllianceBoyasiVarMi.adi : '-'}
                        </TableCell>
                        <TableCell align="right" className="sticky-right">
                          <div className="btn-group flex-btn-group-container">
                            <GrantedButton
                              grantedAuthorites={['ref_ucak_edit']}
                              className="edit-button"
                              onClick={event => {
                                event.stopPropagation();
                                setSelectedRefUcak(refUcak);
                                setIsEntityUpdateModal(true);
                              }}
                              size="sm"
                              data-cy="entityEditButton"
                            >
                              <Tooltip title={<Translate contentKey="entity.action.edit">Edit</Translate>}>
                                <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                              </Tooltip>
                              <span className="show-on-lg">
                                <Translate contentKey="entity.action.edit">Edit</Translate>
                              </span>
                            </GrantedButton>
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="accordion-content-area" colSpan={12}>
                          <Collapse in={openItemId === refUcak?.id} unmountOnExit>
                            <div>
                              <Nav className="custom-nav-tabs mt-3" tabs>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                  <div style={{ display: 'flex' }}>
                                    <NavItem className="custom-nav-item">
                                      <NavLink
                                        className={classnames('custom-nav-link', { active: activeTab === 1 })}
                                        onClick={() => {
                                          setActiveTab(1);
                                        }}
                                        id={'tabDetay'}
                                      >
                                        <Translate contentKey="tk24AdysApp.refUcak.detay"> Detay </Translate>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem className="custom-nav-item">
                                      <NavLink
                                        className={classnames('custom-nav-link', { active: activeTab === 2 })}
                                        onClick={() => {
                                          setActiveTab(2);
                                          if (selectedRefUcak) {
                                            ucakAcMaster(selectedRefUcak.realId);
                                          }
                                        }}
                                        id={'tabUcakBilgi'}
                                      >
                                        <Translate contentKey="tk24AdysApp.refUcak.planeInfo">Uçak Detay Bilgileri</Translate>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem className="custom-nav-item">
                                      <NavLink
                                        className={classnames('custom-nav-link', { active: activeTab === 3 })}
                                        onClick={() => {
                                          setActiveTab(3);
                                          if (selectedRefUcak) {
                                            ucakInventory(selectedRefUcak.realId);
                                          }
                                        }}
                                        id={'tabUcakMotor'}
                                      >
                                        <Translate contentKey="tk24AdysApp.refUcak.planeEngineInfo">Uçak Motor</Translate>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem className="custom-nav-item">
                                      <NavLink
                                        className={classnames('custom-nav-link', { active: activeTab === 4 })}
                                        onClick={() => {
                                          setActiveTab(4);
                                          if (selectedRefUcak) {
                                            ucakBakim(selectedRefUcak.realId);
                                          }
                                        }}
                                        id={'tabBakim'}
                                      >
                                        <Translate contentKey="tk24AdysApp.refUcak.planeAllEngineInfo">Uçak Bakım</Translate>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem className="custom-nav-item">
                                      <NavLink
                                        className={classnames('custom-nav-link', { active: activeTab === 5 })}
                                        onClick={() => {
                                          setActiveTab(5);
                                          if (selectedRefUcak) {
                                            ucakDefect(selectedRefUcak.realId);
                                          }
                                        }}
                                        id={'tabDefect'}
                                      >
                                        <Translate contentKey="tk24AdysApp.refUcak.planeDefectInfo">Uçak Defect</Translate>
                                      </NavLink>
                                    </NavItem>
                                  </div>
                                  <div>
                                    <ExcelSheetDownloadButton
                                      sheetData={ExcelDetailSheetData}
                                      grantedAuthorites={['kriz_ucak_export']}
                                      className={'download-button'}
                                      exportName={`${selectedRefUcak?.kuyrukKodu} ${translate('tk24AdysApp.krizUcak.home.title')}`}
                                    />
                                  </div>
                                </div>
                              </Nav>
                              <TabContent activeTab={activeTab} className="accordion-tab-content">
                                <TabPane key={1} tabId={1}>
                                  <Detay />
                                </TabPane>
                                <TabPane key={2} tabId={2}>
                                  {selectedRefUcak && <UcakAcMasterListe selectedKrizUcakItem={selectedRefUcak} />}
                                </TabPane>
                                <TabPane key={3} tabId={3}>
                                  {selectedRefUcak && <UcakListe selectedKrizUcakItem={selectedRefUcak} />}
                                </TabPane>
                                <TabPane key={4} tabId={4}>
                                  {selectedRefUcak && <UcakBakimListe selectedKrizUcakItem={selectedRefUcak} />}
                                </TabPane>
                                <TabPane key={5} tabId={5}>
                                  {selectedRefUcak && <UcakDefectListe selectedKrizUcakItem={selectedRefUcak} />}
                                </TabPane>
                              </TabContent>
                            </div>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            ) : (
              !loading && (
                <div className="alert alert-warning mt-3">
                  <Translate contentKey="tk24AdysApp.refUcak.home.notFound">No Ref Ucak found</Translate>
                </div>
              )
            )}
          </TableContainer>
        </div>
        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItem}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default RefUcak;
