import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { SERVER_URL } from 'app/config/constants';

export const ParameterNavDropdown = props => (
  <UncontrolledDropdown nav inNavbar id={props.id} data-cy={props['data-cy']}>
    <DropdownToggle nav caret className="d-flex align-items-center menu-parameter-toggle">
      <img src={`${SERVER_URL}/content/images/icon/settings.svg`} style={{ height: '20px' }} />{' '}
      <span className="menu-parameter-header-title">{props.name}</span>
    </DropdownToggle>
    <DropdownMenu right style={props.style} className="sistemYoneticisi">
      {props.children}
    </DropdownMenu>
  </UncontrolledDropdown>
);
