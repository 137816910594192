import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IRefAdtAltEkip, IRefAdtAltEkipParams, defaultValue } from 'app/shared/model/ref-adt-alt-ekip.model';

const initialState: EntityState<IRefAdtAltEkip> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

interface RefAdtAltEkipProp extends IQueryParams {
  adtEkipId: number;
}

const apiUrl = 'api/ref-adt-alt-ekips';

// Actions

export const getEntities = createAsyncThunk('refAdtAltEkip/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IRefAdtAltEkip[]>(requestUrl);
});

export const getEntitiesByEkipId = createAsyncThunk(
  'refAdtAltEkip/fetch_entity_by_ekip_id_list',
  async ({ page, size, sort, adtEkipId }: RefAdtAltEkipProp) => {
    const requestUrl = `${apiUrl}?ekipId.equals=${adtEkipId}${
      sort ? `&page=${page}&size=${size}&sort=${sort}&` : '&'
    }cacheBuster=${new Date().getTime()}`;
    return axios.get<IRefAdtAltEkip[]>(requestUrl);
  }
);

export const getTotalEntities = createAsyncThunk(
  'refAdtAltEkip/fetch_entity_total_list',
  async ({ size, sort, query, adt_ekip_id }: IRefAdtAltEkipParams) => {
    const queryParams = new URLSearchParams();
    if (sort) {
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
    }
    if (adt_ekip_id) {
      queryParams.set('ekipId.equals', adt_ekip_id.toString());
    }
    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }
    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `${apiUrl}/export${queryString ? `?${queryString}` : ''}`;

    return axios.get<IRefAdtAltEkip[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  'refAdtAltEkip/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IRefAdtAltEkip>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'refAdtAltEkip/create_entity',
  async (entity: IRefAdtAltEkip, thunkAPI) => {
    const result = await axios.post<IRefAdtAltEkip>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntitiesByEkipId({ adtEkipId: entity.ekip.id }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'refAdtAltEkip/update_entity',
  async (entity: IRefAdtAltEkip, thunkAPI) => {
    const result = await axios.put<IRefAdtAltEkip>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntitiesByEkipId({ adtEkipId: entity.ekip.id }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'refAdtAltEkip/partial_update_entity',
  async (entity: IRefAdtAltEkip, thunkAPI) => {
    const result = await axios.patch<IRefAdtAltEkip>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'refAdtAltEkip/delete_entity',
  async (entity: IRefAdtAltEkip, thunkAPI) => {
    const requestUrl = `${apiUrl}/${entity.id}`;
    const result = await axios.delete<IRefAdtAltEkip>(requestUrl);
    thunkAPI.dispatch(getEntitiesByEkipId({ adtEkipId: entity.ekip.id }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const RefAdtAltEkipSlice = createEntitySlice({
  name: 'refAdtAltEkip',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities, getEntitiesByEkipId), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getTotalEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          totalEntities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getTotalEntities, getEntitiesByEkipId, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = RefAdtAltEkipSlice.actions;

// Reducer
export default RefAdtAltEkipSlice.reducer;
