import React, { useEffect } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { useAppSelector } from 'app/config/store';

export const Login = (props: RouteComponentProps<any>) => {
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const oidcUrl = useAppSelector(state => state.authentication.oidcUrl);

  useEffect(() => {
    if (oidcUrl && oidcUrl != null) {
      window.location.replace(oidcUrl);
    }
  }, [oidcUrl]);

  const { location } = props;
  const { from } = { from: { pathname: '/', search: location?.search } };
  if (isAuthenticated) {
    return <Redirect to={from} />;
  } else {
    return <></>;
  }
};

export default Login;
