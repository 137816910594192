import React, { useEffect, useState } from 'react';
import { TextFormat, Translate } from 'app/component/jhipster';
import { IRefMurettebat } from 'app/shared/model/ref-murettebat.model';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { IKabinEgitimBilgileri } from 'app/shared/model/kabin-egitim-bilgileri.model';

interface EtkilenenKisiMurettebatEgitimTabProps {
  refMurettebat: IRefMurettebat;
  tab: number;
}

const EtkilenenKisiMurettebatEgitimTab: React.FC<EtkilenenKisiMurettebatEgitimTabProps> = ({ refMurettebat, tab }) => {
  const [egitimLoading, setEgitimLoading] = useState(false);
  const [kabinEgitimBilgileri, setKabinEgitimBilgileri] = useState<IKabinEgitimBilgileri[]>([]);

  useEffect(() => {
    if (tab === 5) {
      getKabinEgitimBilgileri(refMurettebat);
    }
  }, [tab]);

  const getKabinEgitimBilgileri = async (murettebat: IRefMurettebat) => {
    setEgitimLoading(true);
    const murettebatIdUrl = `api/kabin-egitims?refMurettebatId.equals=${murettebat?.id}&size=2000`;
    try {
      const response = await axios.get(murettebatIdUrl);
      setKabinEgitimBilgileri(response.data);
    } catch (error) {
      console.error('Error fetching kabinEgitimBilgileri:', error);
    } finally {
      setEgitimLoading(false);
    }
  };

  const isNullLike = (value: any): boolean => {
    if (typeof value === 'string') {
      const lowerValue = value.toLowerCase();
      return lowerValue === 'null' || lowerValue === 'undefined' || lowerValue.trim() === '';
    }
    return value === null || value === undefined;
  };

  const isValidDate = (date: any): boolean => {
    const parsedDate = Date.parse(date);
    return !isNaN(parsedDate);
  };

  const renderCellValue = (value: any) => {
    if (isNullLike(value)) {
      return '';
    }

    if (typeof value === 'boolean') {
      return value ? (
        <Translate contentKey="entity.action.evet">Evet</Translate>
      ) : (
        <Translate contentKey="entity.action.hayir">Hayır</Translate>
      );
    }

    if (typeof value === 'string') {
      if (isValidDate(value)) {
        return <TextFormat type="date" value={value} format={APP_LOCAL_DATE_FORMAT} />;
      }
    }

    return value?.toString() || '';
  };

  const mapEtkinlikBasariDurumu = (value: number | null | undefined) => {
    if (value === null || value === undefined) return '';
    switch (value) {
      case 0:
        return <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.basarisiz">Başarısız</Translate>;
      case 1:
        return <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.basarili">Başarılı</Translate>;
      case 2:
        return <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.belirsiz">Belirsiz</Translate>;
      default:
        return '';
    }
  };

  const mapEtkinlikTamamlamaDurumu = (value: number | null | undefined) => {
    if (value === null || value === undefined) return '';
    switch (value) {
      case 0:
        return <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.tamamlamamis">Tamamlamamış</Translate>;
      case 1:
        return <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.tamamlamis">Tamamlamış</Translate>;
      case 2:
        return <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.belirsiz">Belirsiz</Translate>;
      default:
        return '';
    }
  };

  const mapBirimTuru = (value: string | null | undefined) => {
    if (!value) return '';
    switch (value) {
      case '0':
        return <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.sinif_ici_egitim">Sınıf İçi Eğitim</Translate>;
      case '1':
        return <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.sinav">Sınav</Translate>;
      case '2':
        return <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.icerik_egitimi">İçerik Eğitimi</Translate>;
      case '4':
        return <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.gorev">Görev</Translate>;
      default:
        return '';
    }
  };

  const mapEgitimBirimiDersDurumu = (value: number | null | undefined) => {
    if (value === null || value === undefined) return '';
    switch (value) {
      case 0:
        return <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.kapali">Kapalı</Translate>;
      case 1:
        return <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.acik">Açık</Translate>;
      case 2:
        return <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.serbest">Serbest</Translate>;
      default:
        return '';
    }
  };

  const mapEgitimBirimiErisimDurumu = (value: number | null | undefined) => {
    if (value === null || value === undefined) return '';
    switch (value) {
      case 1:
        return <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.bitmis">Bitmiş</Translate>;
      case 5:
        return <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.baslamamis">Başlamamış</Translate>;
      case 6:
        return <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.devam_ediyor">Devam Ediyor</Translate>;
      case 7:
        return (
          <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.sinavsa_acip_kapatilmissa">Sınavsa ve Açıp Kapatılmışsa</Translate>
        );
      default:
        return '';
    }
  };

  const mapEgitimBirimiBasariDurumu = (value: number | null | undefined) => {
    if (value === null || value === undefined) return '';
    switch (value) {
      case 0:
        return <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.basarisiz">Başarısız</Translate>;
      case 1:
        return <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.basarili">Başarılı</Translate>;
      case 2:
        return <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.belirsiz">Belirsiz</Translate>;
      default:
        return '';
    }
  };

  return (
    <>
      {egitimLoading && <LoadingSpinner />}
      {!egitimLoading ? (
        <>
          <Typography className="accordion-typography mt-3" gutterBottom component="div">
            <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.egitimBilgileri"> </Translate>
          </Typography>
          <TableContainer className="content-table mb-4">
            {kabinEgitimBilgileri && kabinEgitimBilgileri.length > 0 ? (
              <Table className="accordion-table">
                <TableHead>
                  <TableRow className="accordion-table-title-row">
                    <TableCell className="accordion-table-title-cell" align="left">
                      <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.etkinlikAdi"></Translate>
                    </TableCell>
                    <TableCell className="accordion-table-title-cell" align="left">
                      <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.etkinlikDurum"></Translate>
                    </TableCell>
                    <TableCell className="accordion-table-title-cell" align="left">
                      <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.etkinlikBasariDurumu"></Translate>
                    </TableCell>
                    <TableCell className="accordion-table-title-cell" align="left">
                      <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.etkinlikPuani"></Translate>
                    </TableCell>
                    <TableCell className="accordion-table-title-cell" align="left">
                      <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.etkinlikTamamlamaDurumu"></Translate>
                    </TableCell>
                    <TableCell className="accordion-table-title-cell" align="left">
                      <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.etkinlikTamamlamaTarihi"></Translate>
                    </TableCell>
                    <TableCell className="accordion-table-title-cell" align="left">
                      <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.birimArayuzKodu"></Translate>
                    </TableCell>
                    <TableCell className="accordion-table-title-cell" align="left">
                      <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.birimAdi"></Translate>
                    </TableCell>
                    <TableCell className="accordion-table-title-cell" align="left">
                      <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.birimTuru"></Translate>
                    </TableCell>
                    <TableCell className="accordion-table-title-cell" align="left">
                      <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.egitimBirimiDersDurumu"></Translate>
                    </TableCell>
                    <TableCell className="accordion-table-title-cell" align="left">
                      <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.egitimBirimiErisimDurumu"></Translate>
                    </TableCell>
                    <TableCell className="accordion-table-title-cell" align="left">
                      <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.egitimBirimiBasariDurumu"></Translate>
                    </TableCell>
                    <TableCell className="accordion-table-title-cell" align="left">
                      <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.egitimBirimiPuan"></Translate>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {kabinEgitimBilgileri.map(kabinEgitim => (
                    <TableRow key={kabinEgitim.id} className="accordion-table-content-row">
                      <TableCell className="accordion-table-content-cell" component="th" scope="row">
                        {renderCellValue(kabinEgitim.etkinlikAdi)}
                      </TableCell>
                      <TableCell className="accordion-table-content-cell" align="left">
                        {renderCellValue(kabinEgitim.etkinlikDurum?.adi)}
                      </TableCell>
                      <TableCell className="accordion-table-content-cell" align="left">
                        {mapEtkinlikBasariDurumu(kabinEgitim.etkinlikBasariDurumu)}
                      </TableCell>
                      <TableCell className="accordion-table-content-cell" align="left">
                        {renderCellValue(kabinEgitim.etkinlikPuani)}
                      </TableCell>
                      <TableCell className="accordion-table-content-cell" align="left">
                        {mapEtkinlikTamamlamaDurumu(kabinEgitim.etkinlikTamamlamaDurumu)}
                      </TableCell>
                      <TableCell className="accordion-table-content-cell" align="left">
                        {renderCellValue(kabinEgitim.etkinlikTamamlanmaTarihi)}
                      </TableCell>
                      <TableCell className="accordion-table-content-cell" align="left">
                        {renderCellValue(kabinEgitim.birimArayuzKodu)}
                      </TableCell>
                      <TableCell className="accordion-table-content-cell" align="left">
                        {renderCellValue(kabinEgitim.birimAdi)}
                      </TableCell>
                      <TableCell className="accordion-table-content-cell" align="left">
                        {mapBirimTuru(kabinEgitim.birimTuru)}
                      </TableCell>
                      <TableCell className="accordion-table-content-cell" align="left">
                        {mapEgitimBirimiDersDurumu(kabinEgitim.egitimBirimiDersDurumu)}
                      </TableCell>
                      <TableCell className="accordion-table-content-cell" align="left">
                        {mapEgitimBirimiErisimDurumu(kabinEgitim.egitimBirimiErisimDurumu)}
                      </TableCell>
                      <TableCell className="accordion-table-content-cell" align="left">
                        {mapEgitimBirimiBasariDurumu(kabinEgitim.egitimBirimiBasariDurumu)}
                      </TableCell>
                      <TableCell className="accordion-table-content-cell" align="left">
                        {renderCellValue(kabinEgitim.egitimBirimiPuan)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              !egitimLoading &&
              kabinEgitimBilgileri.length === 0 && (
                <div className="alert alert-warning m-2">
                  <Translate contentKey="tk24AdysApp.refMurettebat.home.kabinEgitimNotFound">kabinEgitimNotFound</Translate>
                </div>
              )
            )}
          </TableContainer>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default EtkilenenKisiMurettebatEgitimTab;
