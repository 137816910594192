import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Input } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { getEntities, getTotalEntities, updateEntity } from './referans.reducer';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { IReferans } from 'app/shared/model/referans.model';
import ReferansUpdate from './referans-update';
import { CustomSaveEntityConfirm, isNullOrEmpty } from 'app/shared/util/confirm-utils';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import CustomPagination from 'app/component/CustomPagination';
import GrantedButton from 'app/component/GrantedButton';
import { SERVER_URL } from 'app/config/constants';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import SortIcon from 'app/component/SortIcon';
import { MenuProps } from 'app/shared/util/filter.constants';
import { customFilterValues } from 'app/shared/util/get-custom-filter';

export const Referans = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();
  const referansList = useAppSelector(state => state.referans.entities);
  const loading = useAppSelector(state => state.referans.loading);
  const updating = useAppSelector(state => state.referans.updating);
  const totalItem = useAppSelector(state => state.referans.totalItems);
  const [isEntityUpdateModal, setIsEntityUpdateModal] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [isViewing, setIsViewing] = useState(null);
  const [search, setSearch] = useState('');
  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const ExcelSampleData = [
    { key: 'tipi', titleContentKey: 'tk24AdysApp.referans.tipi' },
    { key: 'adiTr', titleContentKey: 'global.languageGlobal.adiTr' },
    { key: 'adiEn', titleContentKey: 'global.languageGlobal.adiEn' },
    { key: 'aciklamaTr', titleContentKey: 'global.languageGlobal.aciklamaTr' },
    { key: 'aciklamaEn', titleContentKey: 'global.languageGlobal.aciklamaEn' },
  ];

  const [tipiList, setTipiList] = useState<string[]>();
  const [filterLoading, setFilterLoading] = useState(false);

  const getFilterData = async () => {
    setFilterLoading(false);
    const response = await customFilterValues('ReferansTipi');
    setTipiList(response);
    setFilterLoading(false);
  };

  useEffect(() => {
    getFilterData();
  }, []);

  const getAllEntities = () => {
    dispatch(getEntities(pageObject));
  };

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${search}`,
      }));
    } else if (search.length === 0) {
      const keyToDelete = `globalFilter`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  }, [search]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleCloseUpdateForm = () => {
    if (isEntityUpdateModal) {
      setIsEntityUpdateModal(false);
    }
    setSelectedEntity(null);
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveNewRefEntity = async e => {
    e.preventDefault();

    setIsEntityUpdateModal(false);

    const isSave = await showSaveConfirmModal();
    if (isSave) {
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const entity = {
        ...selectedEntity,
        ...formDataObject,
        uygulama: {
          id: 1000,
        },
      };

      if (selectedEntity !== null) {
        await dispatch(updateEntity({ ...pageObject, entity }));
      }

      handleCloseUpdateForm();
    } else {
      setIsEntityUpdateModal(true);
    }
  };

  const fetchTotalData = async (): Promise<IReferans[]> => {
    try {
      const response = (await dispatch(getTotalEntities({ ...pageObject, size: totalItem }))) as {
        payload: { data: IReferans[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const RefEntityUpdateModal = () => {
    return (
      <ReferansUpdate
        saveNewRefEntity={saveNewRefEntity}
        handleCloseForm={handleCloseUpdateForm}
        formValues={selectedEntity}
        isOpen={isEntityUpdateModal}
        isViewing={isViewing}
      ></ReferansUpdate>
    );
  };

  const handleSearchChange = (elem, event) => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&${elem}.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = `${elem}.equals`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  return (
    <div>
      {(loading || updating || filterLoading) && <LoadingSpinner />}

      <CustomSaveEntityConfirm entity={selectedEntity} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

      <div style={{ display: 'flex', gap: 8, textAlign: 'center' }}>
        <p className="icon-text">
          <a className="link-a-text" href={`${SERVER_URL}`}>
            <img width={13} height={13} src={`${SERVER_URL}/content/images/icon/faHouse.svg`} />
          </a>
        </p>
        <p className="separator"> / </p>
        <p className="link-text">
          {' '}
          <Translate contentKey="tk24AdysApp.referans.home.title" />{' '}
        </p>
      </div>

      <h2 id="referans-heading" data-cy="ReferansHeading" className="title-frame">
        <a className="link-a-text" id={'goBack'} onClick={() => props.history.goBack()}>
          <img width={27.5} height={22.5} src={`${SERVER_URL}/content/images/icon/faArrowLeft.svg`} />{' '}
        </a>
        <p className="title-text">
          <Translate contentKey="tk24AdysApp.referans.home.title" />
        </p>
      </h2>

      <div className="content-area">
        <div className="search-frame">
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={translate('entity.action.search')}
              className="search-input"
              id={'global-search'}
            />
          </div>

          <div className="create-area">
            <Box sx={{ minWidth: 220, maxWidth: 220 }}>
              <FormControl fullWidth>
                <InputLabel id="tipi-label" className="select-input-label">
                  <Translate contentKey="tk24AdysApp.referans.tipi" />
                </InputLabel>
                <Select
                  labelId="tipi-label"
                  id="tipi"
                  label={translate('tk24AdysApp.referans.tipi')}
                  onChange={event => handleSearchChange('tipi', event)}
                  MenuProps={MenuProps}
                  className="select-input"
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {tipiList &&
                    tipiList.map(item => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
            <ExcelDownloadButton
              excelSampleData={ExcelSampleData}
              grantedAuthorites={['referans_export']}
              fetchData={fetchTotalData}
              exportName={translate('tk24AdysApp.referans.home.title')}
              className={'download-button'}
            />
          </div>
        </div>

        <RefEntityUpdateModal />
        <div className="table-responsive" style={{ marginTop: 5 }}>
          <TableContainer className="content-table">
            {referansList && referansList.length > 0 ? (
              <Table aria-label="a dense table">
                <TableHead className="content-table-title-area">
                  <TableRow className="table-head-row">
                    <TableCell align="left" className="table-title-cell hand" onClick={sort('tipi')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.referans.tipi">Tipi</Translate>&nbsp;
                        <SortIcon column="tipi" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell id={'global-sort'} align="left" className="table-title-cell hand" onClick={sort('adiTr')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="global.languageGlobal.adiTrTitle">Adi Tr</Translate>&nbsp;
                        <SortIcon column="adiTr" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell align="left" className="table-title-cell hand" onClick={sort('adiEn')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="global.languageGlobal.adiEnTitle">Adi En</Translate>&nbsp;
                        <SortIcon column="adiEn" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="sticky-right-header"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {referansList.map(referans => (
                    <TableRow key={referans.id} className="table-cell-row">
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {referans.tipi}
                      </TableCell>
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {referans.adiTr}
                      </TableCell>
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {referans.adiEn}
                      </TableCell>

                      <TableCell align="right" className="sticky-right">
                        <div className="btn-group flex-btn-group-container">
                          <GrantedButton
                            grantedAuthorites={['referans_edit']}
                            className="edit-button"
                            style={{ marginLeft: 2 }}
                            onClick={() => {
                              setSelectedEntity(referans);
                              setIsViewing(false);
                              setIsEntityUpdateModal(true);
                            }}
                            size="sm"
                            data-cy="entityEditButton"
                          >
                            <Tooltip title={<Translate contentKey="entity.action.edit">Edit</Translate>}>
                              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                            </Tooltip>
                            <span className="show-on-lg">
                              <Translate contentKey="entity.action.edit">Edit</Translate>
                            </span>
                          </GrantedButton>
                          <GrantedButton
                            grantedAuthorites={['referans_read']}
                            className="view-button"
                            onClick={() => {
                              setSelectedEntity(referans);
                              setIsViewing(true);
                              setIsEntityUpdateModal(true);
                            }}
                            style={{ marginLeft: 10 }}
                            size="sm"
                            data-cy="entityDetailsButton"
                          >
                            <Tooltip title={<Translate contentKey="entity.action.edit">Edit</Translate>}>
                              <img width={15.2} height={11.2} src={`${SERVER_URL}/content/images/icon/faEye.svg`} />
                            </Tooltip>
                            <span className="show-on-lg">
                              <Translate contentKey="entity.action.view">View</Translate>
                            </span>
                          </GrantedButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              !loading &&
              !updating && (
                <div className="alert alert-warning">
                  <Translate contentKey="tk24AdysApp.referans.home.notFound">No Ref Yakinlik found</Translate>
                </div>
              )
            )}
          </TableContainer>
        </div>
        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItem}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default Referans;
