import React, { useEffect, useState } from 'react';
import MenuItemHeader from 'app/shared/layout/menus/menu-item-header';
import { Translate } from 'app/component/jhipster';
import { Collapse } from 'reactstrap';
import './menu.scss';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';

export const EntitiesMenu = ({ menuOpen, toggleMenu }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setIsOpen(menuOpen);
    setExpanded(menuOpen);
  }, [menuOpen]);

  const toggle = () => setIsOpen(!isOpen);

  const handleMenuClick = menuId => {
    setActiveMenu(menuId === activeMenu ? null : menuId);
    setExpanded(!expanded);
    toggleMenu();
  };

  return (
    <div>
      <div onClick={toggle}>
        <MenuItemHeader to="/" className={activeMenu === 'entities' ? 'activeHeader' : ''} onClick={() => handleMenuClick('entities')}>
          {expanded && (
            <>
              <FolderOutlinedIcon style={{ color: '#34404F' }} />
              <span className="headerText">
                <Translate contentKey="global.menu.entities.main" />
              </span>
            </>
          )}
        </MenuItemHeader>
      </div>
      <Collapse isOpen={isOpen}></Collapse>
    </div>
  );
};

export default EntitiesMenu;
