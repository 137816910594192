import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import axios from 'axios';
import { APP_DATETIME_FORMAT } from 'app/config/constants';
import { FormGroup, Input, Label } from 'reactstrap';
import { KartLoadingSpinner } from 'app/component/LoadingSpinner';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { TextFormat, translate } from 'app/component/jhipster';
import { getEntities, reset } from '../kriz-katilimci-bildirim/kriz-katilimci-bildirim.reducer';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';

const Bildirimler = () => {
  const dispatch = useAppDispatch();
  const krizKatilimciEntity = useAppSelector(state => state.krizKatilimci.entity);
  const bildirimEntity = useAppSelector(state => state.krizKatilimciBildirim.entities);
  const bildirimLoading = useAppSelector(state => state.krizKatilimciBildirim.loading);
  const [selectedFilter, setSelectedFilter] = useState('all');

  const [pageObject, setPageObject] = useState<IQueryParams>({
    ...useAppSelector(state => state.paginate.pageObject),
    size: 999,
    page: 0,
    sort: 'cDate,desc',
    query: `gonderimDurumuId.in=${ReferansEnum.GonderimDurumu.Gonderildi},${ReferansEnum.GonderimDurumu.Gonderilecek}&bildirimTuruId.equals=64&krizKatilimciId.equals=${krizKatilimciEntity?.id}`,
  });

  useEffect(() => {
    if (krizKatilimciEntity) {
      dispatch(reset());
      dispatch(getEntities(pageObject));
    }
  }, [pageObject.query, pageObject.size, pageObject.sort, pageObject.page]);

  const handleMarkAsRead = async bildirimId => {
    try {
      await axios.put(`api/kriz-katilimci-bildirims/mark-as-read/${bildirimId}`);
      if (krizKatilimciEntity) {
        dispatch(reset());
        dispatch(getEntities(pageObject));
      }
    } catch (error) {
      console.error('API request error:', error);
    }
  };

  const handleMarkAsUnread = async bildirimId => {
    try {
      await axios.put(`api/kriz-katilimci-bildirims/mark-as-unread/${bildirimId}`);
      if (krizKatilimciEntity) {
        dispatch(reset());
        dispatch(getEntities(pageObject));
      }
    } catch (error) {
      console.error('API request error:', error);
    }
  };

  const handleMarkAsReadAll = async krizKatilimciId => {
    try {
      await axios.put(`api/kriz-katilimci-bildirims/mark-as-read?krizKatilimciId=${krizKatilimciId}`);
      if (krizKatilimciEntity) {
        dispatch(reset());
        dispatch(getEntities(pageObject));
      }
    } catch (error) {
      console.error('API request error:', error);
    }
  };

  const handleMarkAsUnreadAll = async krizKatilimciId => {
    try {
      await axios.put(`api/kriz-katilimci-bildirims/mark-as-unread?krizKatilimciId=${krizKatilimciId}`);
      if (krizKatilimciEntity) {
        dispatch(reset());
        dispatch(getEntities(pageObject));
      }
    } catch (error) {
      console.error('API request error:', error);
    }
  };

  const handleSelectedFilter = filter => {
    const params = new URLSearchParams(pageObject.query);
    setSelectedFilter(filter);

    if (filter === 'okunmadi') {
      params.set('gonderimDurumuId.equals', ReferansEnum.GonderimDurumu.Gonderilecek.toString());
    } else if (filter === 'okundu') {
      params.set('gonderimDurumuId.equals', ReferansEnum.GonderimDurumu.Gonderildi.toString());
    } else {
      params.delete('gonderimDurumuId.equals');
    }

    setPageObject(prevPageObject => ({
      ...prevPageObject,
      query: params.toString(),
    }));
  };

  return (
    <div className="dashboardBildirimler">
      <div className="bildirimHeader">
        <h3>{translate('dashboard.bildirimler')}</h3>
      </div>

      <div className="filterBildirim">
        <FormGroup check className="inputAreaBildirim">
          <Label check>
            <Input
              style={{ cursor: 'pointer' }}
              type="radio"
              name="filterAll"
              value="all"
              checked={selectedFilter === 'all'}
              onChange={event => handleSelectedFilter(event.target.value)}
            />{' '}
            {translate('entity.action.all')}
          </Label>
        </FormGroup>
        <FormGroup check className="inputAreaBildirim">
          <Label check>
            <Input
              type="radio"
              name="filterAll"
              value="okunmadi"
              checked={selectedFilter === 'okunmadi'}
              onChange={event => handleSelectedFilter(event.target.value)}
            />{' '}
            {translate('dashboard.okunmadi')}
          </Label>
        </FormGroup>
        <FormGroup check className="inputAreaBildirim">
          <Label check>
            <Input
              type="radio"
              id={'okundu'}
              name="filterAll"
              value="okundu"
              checked={selectedFilter === 'okundu'}
              onChange={event => handleSelectedFilter(event.target.value)}
            />{' '}
            {translate('dashboard.okundu')}
          </Label>
        </FormGroup>
      </div>

      <div className="markAllLinks">
        {selectedFilter === 'okunmadi' && (
          <a onClick={() => handleMarkAsReadAll(krizKatilimciEntity?.id)} className="markAllReadLink">
            {translate('dashboard.tumunuOkunduOlarakIsaretle')}
          </a>
        )}
        {selectedFilter === 'okundu' && (
          <a onClick={() => handleMarkAsUnreadAll(krizKatilimciEntity?.id)} className="markAllReadLink">
            {translate('dashboard.tumunuOkunmadiOlarakIsaretle')}
          </a>
        )}
      </div>

      <div className="bildirimDetay">
        {bildirimLoading && <KartLoadingSpinner />}
        {bildirimEntity &&
          bildirimEntity.map(item => (
            <div key={item.id} className="bildirimIcerik">
              <p>{item.icerik}</p>
              <div className="bildirimDurum">
                <p>
                  <TextFormat type="date" value={item.cDate} format={APP_DATETIME_FORMAT} />
                </p>
                {selectedFilter === 'all' && (
                  <a
                    className="gonderimDurumuText"
                    onClick={() => {
                      if (item.gonderimDurumu.id === ReferansEnum.GonderimDurumu.Gonderildi) {
                        handleMarkAsUnread(item.id);
                      } else if (item.gonderimDurumu.id === ReferansEnum.GonderimDurumu.Gonderilecek) {
                        handleMarkAsRead(item.id);
                      }
                    }}
                  >
                    {' '}
                    {item.gonderimDurumu.id === ReferansEnum.GonderimDurumu.Gonderildi
                      ? translate('dashboard.okunmadiOlarakIsaretle')
                      : translate('dashboard.okunduOlarakIsaretle')}
                  </a>
                )}
                {selectedFilter === 'all'
                  ? null
                  : item.gonderimDurumu && (
                      <a
                        onClick={() => {
                          if (item.gonderimDurumu.id === ReferansEnum.GonderimDurumu.Gonderildi) {
                            handleMarkAsUnread(item.id);
                          } else if (item.gonderimDurumu.id === ReferansEnum.GonderimDurumu.Gonderilecek) {
                            handleMarkAsRead(item.id);
                          }
                        }}
                      >
                        {item.gonderimDurumu.id === ReferansEnum.GonderimDurumu.Gonderildi
                          ? translate('dashboard.okunmadiOlarakIsaretle')
                          : translate('dashboard.okunduOlarakIsaretle')}
                      </a>
                    )}
              </div>
              <hr style={{ backgroundColor: '#DADEE3', height: '1px' }} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Bildirimler;
