import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IRefMurettebat, defaultValue } from 'app/shared/model/ref-murettebat.model';

const initialState: EntityState<IRefMurettebat> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/ref-murettebats';

interface IRefMurettebatQueryParams extends IQueryParams {
  ucusId: string | number;
  filterType?: string | number;
  kisiId?: number[];
}

// Actions

export const getTotalEntities = createAsyncThunk(
  'refMurettebats/fetch_entity_total_list',
  async ({ ucusId, size, sort, query, filterType }: IRefMurettebatQueryParams) => {
    const queryParams = new URLSearchParams();
    if (sort) {
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
      queryParams.set('refUcusId.equals', ucusId.toString());
      queryParams.set('filterType.equals', filterType.toString());
    }
    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }
    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `${apiUrl}/export${queryString ? `?${queryString}` : ''}`;
    return axios.get<IRefMurettebat[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getEntities = createAsyncThunk(
  'refMurettebats/fetch_entity_kriz_list',
  async ({ ucusId, page, size, sort, query }: IRefMurettebatQueryParams) => {
    const queryParams = new URLSearchParams();

    if (ucusId) {
      queryParams.set('refUcusId.equals', ucusId.toString());
    }
    if (sort) {
      queryParams.set('page', page.toString());
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
    }

    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }

    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `${apiUrl}${queryString ? `?${queryString}` : ''}`;

    return axios.get<IRefMurettebat[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getKisiEntities = createAsyncThunk(
  'refMurettebats/fetch_entity_kriz_list',
  async ({ ucusId, filterType, page, size, sort, query, query2 }: IRefMurettebatQueryParams) => {
    const queryParams = new URLSearchParams();

    if (sort) {
      queryParams.set('page', page.toString());
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
      queryParams.set('refUcusId.equals', ucusId.toString());
      queryParams.set('filterType.equals', filterType.toString());
    }

    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }

    if (query2) {
      const parsedQueryParams2 = new URLSearchParams(query2);
      parsedQueryParams2.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }

    queryParams.set('cacheBuster', new Date().getTime().toString());

    const queryString = queryParams.toString();
    const requestUrl = `${apiUrl}${queryString ? `?${queryString}` : ''}`;

    return axios.get<IRefMurettebat[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  'refMurettebats/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IRefMurettebat>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'refMurettebats/create_entity',
  async (entity: IRefMurettebat, thunkAPI) => {
    const result = await axios.post<IRefMurettebat>(apiUrl, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'refMurettebats/update_entity',
  async (entity: IRefMurettebat, thunkAPI) => {
    const result = await axios.patch<IRefMurettebat>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'refMurettebats/partial_update_entity',
  async (entity: IRefMurettebat, thunkAPI) => {
    const result = await axios.patch<IRefMurettebat>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'refMurettebats/delete_entity',
  async (id: number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IRefMurettebat>(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const RefMurettebatSlice = createEntitySlice({
  name: 'refMurettebats',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getTotalEntities), (state, action) => {
        return {
          ...state,
          totalEntities: action.payload.data,
          loading: false,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getEntities, getKisiEntities), (state, action) => {
        return {
          ...state,
          entities: action.payload.data,
          loading: false,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getTotalEntities, getKisiEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = RefMurettebatSlice.actions;

// Reducer
export default RefMurettebatSlice.reducer;
