import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Col, Input, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { TextFormat, Translate, translate, Storage } from 'app/component/jhipster';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { hasAnyAuthority, useAppDispatch, useAppSelector } from 'app/config/store';
import {
  deleteEntity,
  deleteUnitEntity,
  getEntities,
  getTotalEntities,
  getTotalUnitEntities,
  getUnitEntities,
} from '../kriz-ceride/kriz-ceride.reducer';
import GrantedButton from 'app/component/GrantedButton';
import CustomPagination from 'app/component/CustomPagination';
import { IKrizCeride } from 'app/shared/model/kriz-ceride.model';
import KrizCerideUpdate from '../kriz-ceride/kriz-ceride-update';
import { Card, FormControl, IconButton, InputLabel, Menu, MenuItem, Select } from '@mui/material';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { MoreVert, Search } from '@mui/icons-material';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { IKrizKatilimci } from 'app/shared/model/kriz-katilimci.model';
import { CustomDeleteEntityConfirm, isNullOrEmpty, isObjectEmpty } from 'app/shared/util/confirm-utils';
import KrizCerideUpdateUnit from '../kriz-ceride/kriz-ceride-update-unit';
import axios from 'axios';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import { toast } from 'react-toastify';
import GrantedMenuItem from 'app/component/GrantedMenuItem';
import styled from 'styled-components';

const StyledTab = styled(Tab)`
  &:hover {
    box-shadow: 0px -2px 4px 0px #0000000f;
  }
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface KrizYonetimMerkeziProps extends RouteComponentProps<{ id: string }> {
  krizKatilimci: IKrizKatilimci;
}

const KrizYonetimMerkezi: React.FC<KrizYonetimMerkeziProps> = ({ match, krizKatilimci }) => {
  const dispatch = useAppDispatch();
  const isAdmin = Storage.session.get('isAdmin');
  const [secondaryValue, setSecondaryValue] = useState(0);
  const krizCerideList = useAppSelector(state => state.krizCeride.entities);
  const krizCerideLoading = useAppSelector(state => state.krizCeride.loading);
  const krizCerideUpdating = useAppSelector(state => state.krizCeride.updating);
  const totalItem = useAppSelector(state => state.krizCeride.totalItems);
  const krizEntity = useAppSelector(state => state.kriz.entity);
  const [selectedCeride, setSelectedCeride] = useState<IKrizCeride>(null);
  const [icerikModal, setIcerikModal] = useState(false);
  const [isCerideUpdateModal, setIsCerideUpdateModal] = useState<boolean>(false);
  const [isCerideUpdateUnitModal, setIsCerideUpdateUnitModal] = useState<boolean>(false);
  const [editCeride, setEditCeride] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const sicilNo = useAppSelector(state => state.authentication.account.sicilNo);
  const [kayitSahibiList, setKayitSahibiList] = useState(null);
  const [katilimciList, setKatilimciList] = useState(null);
  const referans = useAppSelector(state => state.referans.entities);
  const rapordaYerAlacakMiList = referans.filter(res => res.tipi === 'EVET_HAYIR');
  const islemKoduList = useAppSelector(state => state.refIslemKodu.entities);
  const [search, setSearch] = useState('');
  const [openMoreBtn, setOpenMoreBtn] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const opens = Boolean(anchorEl);
  const ITEM_HEIGHT = 48;
  const [loading, setLoading] = useState(true);
  const [pageObject, setPageObject] = useState<IQueryParams>({
    ...useAppSelector(state => state.paginate.pageObject),
    query: `krizId.equals=${match.params.id}&kayitSahibiId.equals=${ReferansEnum.GorevSahibi.ADYM}`,
    sort: 'islemZamani,desc',
    sort2: 'islemKodu,desc',
  });

  const ExcelSampleData = [
    { key: 'index', titleContentKey: 'tk24AdysApp.krizCeride.siraNo' },
    { key: 'islemZamani', format: 'date', titleContentKey: 'tk24AdysApp.krizCeride.islemZamani' },
    { key: 'islemKodu.adi', titleContentKey: 'tk24AdysApp.krizCeride.islemKodu' },
    { key: 'icerik', titleContentKey: 'tk24AdysApp.krizCeride.icerik' },
    { key: 'cerideEkleyen', titleContentKey: 'tk24AdysApp.krizCeride.cerideEkleyen' },
    { key: 'krizKatilimciFullName', titleContentKey: 'tk24AdysApp.krizCeride.cerideKatilimciAdiSoyadi' },
    { key: 'krizKatilimci.unvanAdi', titleContentKey: 'tk24AdysApp.krizCeride.cerideKatilimciUnvan' },
    { key: 'rapordaYerAlacakMi.adi', titleContentKey: 'tk24AdysApp.krizCeride.rapordaYerAlacakMi' },
  ];

  useEffect(() => {
    setLoading(true);
    const kayitSahibiPromise = axios.get(`api/ref-ceride-ekleyens?kayitSahibiId.equals=${ReferansEnum.GorevSahibi.ADYM}`);
    const katilimciPromise = axios.get(`api/kriz-katilimcis?krizId.equals=${match.params.id}&katilimciRoluId.in=51,52,53,54,55`);

    Promise.all([kayitSahibiPromise, katilimciPromise])
      .then(([kayitSahibiResp, katilimciResp]) => {
        setKayitSahibiList(kayitSahibiResp.data);
        setKatilimciList(katilimciResp.data);
      })
      .catch(ex => {
        toast.error(translate(ex.response?.data?.message));
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (secondaryValue === 0) {
      dispatch(
        getEntities({
          ...pageObject,
        })
      );
    } else {
      dispatch(
        getUnitEntities({
          ...pageObject,
        })
      );
    }
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort, secondaryValue]);

  useEffect(() => {
    document.body.style.paddingRight = '0px';
  }, [openMoreBtn, anchorEl, opens]);

  const handleSecondaryChange = (event, newValue) => {
    setSecondaryValue(newValue);
    setSearch('');
  };

  useEffect(() => {
    if (search.length > 2) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${search}`,
      }));
    } else if (search.length === 0 && !isNullOrEmpty(pageObject.query)) {
      const keyToDelete = 'globalFilter';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  }, [search]);

  function a11yProps(index: number, type: string) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleCloseModal = () => {
    setIsCerideUpdateModal(false);
    setSelectedCeride(null);
    setEditCeride(false);
    setIcerikModal(false);
  };

  const handleCloseUnitModal = () => {
    setIsCerideUpdateUnitModal(false);
    setSelectedCeride(null);
    setEditCeride(false);
  };

  const KrizCerideUpdateModal = () => {
    return (
      <KrizCerideUpdate
        kriz={krizEntity}
        katilimci={krizKatilimci}
        handleCloseForm={handleCloseModal}
        formValues={selectedCeride}
        isOpen={isCerideUpdateModal}
        gorevSahibiId={ReferansEnum.GorevSahibi.ADYM}
        pageObject={pageObject}
        editCeride={editCeride}
        kayitSahibiList={kayitSahibiList}
        adtEkipList={null}
        krizIstasyonList={null}
        katilimciList={katilimciList}
      ></KrizCerideUpdate>
    );
  };

  const KrizCerideUpdateUnitModal = () => {
    return (
      <KrizCerideUpdateUnit
        kriz={krizEntity}
        katilimci={krizKatilimci}
        handleCloseForm={handleCloseUnitModal}
        formValues={selectedCeride}
        isOpen={isCerideUpdateUnitModal}
        gorevSahibiId={ReferansEnum.GorevSahibi.ADYM}
        kayitSahibiList={kayitSahibiList}
        pageObject={pageObject}
        adtEkipList={null}
        krizIstasyonList={null}
      ></KrizCerideUpdateUnit>
    );
  };

  const calculateStartIndex = (currentPage, totalItemCount, itemsPerPage) => {
    if (currentPage === 0) {
      return totalItemCount;
    } else {
      return totalItemCount - itemsPerPage * currentPage;
    }
  };

  function capitalizeWords(sentence) {
    if (!sentence) {
      return '';
    }
    const words = sentence.split(' ');
    const capitalizedWords = words.map(word => {
      if (word.length > 1) {
        return word.charAt(0).toLocaleUpperCase('tr-TR') + word.slice(1).toLocaleLowerCase('tr-TR');
      } else {
        return word.toLocaleUpperCase('tr-TR');
      }
    });
    return capitalizedWords.join(' ');
  }

  const confirmKrizCerideDelete = ceride => {
    setSelectedCeride(ceride);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedCeride) {
      const entity = { ...pageObject, entity: selectedCeride };

      if (krizCerideList.length === 1) {
        entity.page = pageObject.page - 1;
        setPageObject({ ...pageObject, page: pageObject.page - 1 });
      }
      if (secondaryValue === 0) {
        await dispatch(deleteEntity(entity));
      } else {
        await dispatch(deleteUnitEntity(entity));
      }

      handleDeleteCancel();
    }
  }, [selectedCeride, dispatch]);

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setSelectedCeride(null);
  };

  const fetchTotalCerideData = async (): Promise<IKrizCeride[]> => {
    try {
      let response;
      if (secondaryValue === 0) {
        response = (await dispatch(getTotalEntities({ ...pageObject, size: totalItem, sort: 'id,asc' }))) as {
          payload: { data: IKrizCeride[] };
        };
      } else {
        response = (await dispatch(getTotalUnitEntities({ ...pageObject, size: totalItem, sort: 'id,asc' }))) as {
          payload: { data: IKrizCeride[] };
        };
      }
      const data = response.payload.data || [];

      const transformedData = data.map(item => ({
        ...item,
        krizKatilimciFullName: `${item.krizKatilimci?.adi || ''} ${item.krizKatilimci?.soyadi || ''}`.trim(),
        cerideEkleyen: item.cerideEkleyen?.realId === 4 ? item.cerideEkleyenDiger : item.cerideEkleyen?.adi,
      }));

      transformedData.sort((a, b) => a.islemZamani - b.islemZamani);

      transformedData.forEach((item, index) => {
        item.index = index + 1;
      });

      return transformedData;
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const IcerikDevamModal = () => {
    return (
      <Modal
        isOpen={icerikModal}
        onClose={handleCloseModal}
        className="modal-lg"
        toggle={handleCloseModal}
        centered
        style={{ maxWidth: '1000px' }}
      >
        <ModalHeader toggle={handleCloseModal} data-cy="contactDialogHeading">
          <Translate contentKey="tk24AdysApp.krizCeride.detail.cerideDetay">Sorumlu Katılımcı</Translate>
        </ModalHeader>
        <ModalBody style={{ maxHeight: '600px', minHeight: '400px', overflowY: 'auto' }}>
          <div className="krizCerideDetail">
            <h3>
              <Translate contentKey="tk24AdysApp.krizCeride.cerideYayinlanmaTarihi"></Translate>:
            </h3>
            <p>
              <TextFormat value={selectedCeride?.islemZamani} type="date" format={APP_DATE_FORMAT} />
            </p>
          </div>
          <div className="krizCerideDetail">
            <h3>
              <Translate contentKey="tk24AdysApp.krizCeride.cerideYayinlayanKisi"></Translate>:
            </h3>
            <p>
              {(selectedCeride?.cerideEkleyen.realId === 1 ||
                selectedCeride?.cerideEkleyen.realId === 2 ||
                selectedCeride?.cerideEkleyen.realId === 3) && <>{capitalizeWords(selectedCeride.cerideEkleyen?.adi)}</>}
              {selectedCeride?.cerideEkleyen.realId === 4 && <>{capitalizeWords(selectedCeride?.cerideEkleyenDiger)}</>}
              {selectedCeride?.cerideEkleyen.realId === 5 && (
                <>
                  {capitalizeWords(selectedCeride?.krizKatilimci?.adi)} {capitalizeWords(selectedCeride?.krizKatilimci?.soyadi)} (
                  {capitalizeWords(selectedCeride?.krizKatilimci?.unvanAdi)})
                </>
              )}
              {!(
                selectedCeride?.cerideEkleyen.realId === 1 ||
                selectedCeride?.cerideEkleyen.realId === 2 ||
                selectedCeride?.cerideEkleyen.realId === 3 ||
                selectedCeride?.cerideEkleyen.realId === 4 ||
                selectedCeride?.cerideEkleyen.realId === 5
              ) &&
                selectedCeride?.krizKatilimci && (
                  <>
                    {capitalizeWords(selectedCeride?.krizKatilimci?.adi)} {capitalizeWords(selectedCeride?.krizKatilimci?.soyadi)} (
                    {capitalizeWords(selectedCeride?.krizKatilimci?.unvanAdi)})
                  </>
                )}
            </p>
          </div>
          <div className="krizCerideDetail">
            <h3>
              <Translate contentKey="tk24AdysApp.krizCeride.cerideBaslik"></Translate>:
            </h3>
            <p>{selectedCeride?.baslik}</p>
          </div>
          <div className="krizCerideDetail">
            <h3>
              <Translate contentKey="tk24AdysApp.krizCeride.cerideAciklamasi"></Translate>:
            </h3>
            <p>{renderTextWithLineBreaks(selectedCeride?.icerik)}</p>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  const renderTextWithLineBreaks = text => {
    if (isNullOrEmpty(text)) {
      return <></>;
    }

    const lines = text.split('\n');
    return lines.map((line, indexkey) => (
      <React.Fragment key={indexkey}>
        <span>{line}</span>
        {indexkey !== lines.length - 1 && <br />}
      </React.Fragment>
    ));
  };

  const handleCloseMenu = event => {
    event.stopPropagation();
    setOpenMoreBtn(false);
    setSelectedCeride(null);
    setAnchorEl(null);
  };

  const handleSearchChange = (elem, event) => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&${elem}.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = `${elem}.equals`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  return (
    !isObjectEmpty(krizEntity) && (
      <>
        <>
          {selectedCeride && (
            <CustomDeleteEntityConfirm
              entity={selectedCeride}
              entityName="krizCeride"
              isOpen={isDeleteModalOpen}
              onConfirm={handleDeleteConfirm}
              onCancel={handleDeleteCancel}
            />
          )}
          {KrizCerideUpdateModal()}
          {icerikModal && IcerikDevamModal()}
          {KrizCerideUpdateUnitModal()}
          <Box className="krizCerides" sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: '10px' }}>
            <Tabs value={secondaryValue} onChange={handleSecondaryChange} aria-label="basic tabs example" style={{ flexBasis: '23%' }}>
              <StyledTab label={translate('tk24AdysApp.krizCeride.tumKayitlar')} {...a11yProps(0, 'tumu')} className="tabAltBirim" />
              <StyledTab
                label={translate('tk24AdysApp.krizCeride.birimKayitlari')}
                {...a11yProps(0, 'benimBirimim')}
                className="tabAltBirim"
              />
            </Tabs>
            <div className="d-flex justify-end flex-wrap" style={{ gap: '5px' }}>
              <div className="seachiconposition">
                <Search className="seachicon" style={{ color: 'grey' }} />
                <Input
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  placeholder={translate('entity.action.search')}
                  className="search-input"
                />
              </div>
              <Box className="filter-box" sx={{ minWidth: 200 }}>
                <FormControl fullWidth>
                  <InputLabel id="cerideEkleyenId-label" className="select-input-label">
                    {translate('tk24AdysApp.krizCeride.kayitSahibi')}
                  </InputLabel>
                  <Select
                    labelId="cerideEkleyenId-label"
                    id="cerideEkleyenId"
                    label={translate('tk24AdysApp.krizCeride.kayitSahibi')}
                    onChange={event => handleSearchChange('cerideEkleyenId', event)}
                    className="select-input"
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          overflowY: 'auto',
                        },
                      },
                    }}
                  >
                    <MenuItem value="">
                      <Translate contentKey="entity.action.all" />
                    </MenuItem>
                    {kayitSahibiList &&
                      kayitSahibiList.length > 0 &&
                      kayitSahibiList.map(item => (
                        <MenuItem key={item?.id} value={item?.id}>
                          {item?.adi}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <Box className="filter-box" sx={{ minWidth: 200 }}>
                <FormControl fullWidth>
                  <InputLabel id="rapordaYerAlacakMiId-label" className="select-input-label">
                    {translate('tk24AdysApp.krizCeride.rapordaYerAlacakMi')}
                  </InputLabel>
                  <Select
                    labelId="rapordaYerAlacakMiId-label"
                    id="rapordaYerAlacakMiId"
                    label={translate('tk24AdysApp.krizCeride.rapordaYerAlacakMi')}
                    onChange={event => handleSearchChange('rapordaYerAlacakMiId', event)}
                    className="select-input"
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          overflowY: 'auto',
                        },
                      },
                    }}
                  >
                    <MenuItem value="">
                      <Translate contentKey="entity.action.all" />
                    </MenuItem>
                    {rapordaYerAlacakMiList &&
                      rapordaYerAlacakMiList.length > 0 &&
                      rapordaYerAlacakMiList.map(item => (
                        <MenuItem key={item?.id} value={item?.id}>
                          {item?.adi}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <Box className="filter-box" sx={{ minWidth: 200 }}>
                <FormControl fullWidth>
                  <InputLabel id="islemKoduId-label" className="select-input-label">
                    <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.islemKodu">İşlem Kodu</Translate>
                  </InputLabel>
                  <Select
                    labelId="islemKoduId-label"
                    id="islemKoduId"
                    label={translate('tk24AdysApp.krizKatilimciBildirim.islemKodu')}
                    onChange={event => handleSearchChange('islemKoduId', event)}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          overflowY: 'auto',
                        },
                      },
                    }}
                    className="select-input"
                  >
                    <MenuItem value="">
                      <Translate contentKey="entity.action.all" />
                    </MenuItem>
                    {islemKoduList &&
                      islemKoduList.length > 0 &&
                      islemKoduList.map(item => (
                        <MenuItem key={item?.id} value={item?.id}>
                          {item?.adi}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>

              <ExcelDownloadButton
                excelSampleData={ExcelSampleData}
                grantedAuthorites={['kriz_ceride_export']}
                fetchData={fetchTotalCerideData}
                className={'download-button'}
                exportName={translate('tk24AdysApp.krizCeride.krizYonetimMerkeziExcel')}
              />

              {secondaryValue === 0 ? (
                <GrantedButton
                  id={`create-button`}
                  grantedAuthorites={['kriz_ceride_edit']}
                  className="create-button"
                  size="sm"
                  data-cy="entityCreateButton"
                  onClick={event => {
                    event.stopPropagation();
                    setEditCeride(false);
                    setIsCerideUpdateModal(true);
                  }}
                  comparison={[
                    {
                      value: krizEntity.krizDurumu?.id,
                      checkValue: ReferansEnum.KrizDurumu.Aktif,
                      contentKey: 'error.comparison.crisisIsPassive',
                    },
                  ]}
                >
                  <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.newRecord">Yeni Kayıt</Translate>
                  </span>
                </GrantedButton>
              ) : (
                <GrantedButton
                  id={`create-button`}
                  grantedAuthorites={['kriz_ceride_edit']}
                  className="create-button"
                  size="sm"
                  data-cy="entityCreateButton"
                  onClick={event => {
                    event.stopPropagation();
                    setIsCerideUpdateUnitModal(true);
                  }}
                  comparison={[
                    {
                      value: krizEntity.krizDurumu?.id,
                      checkValue: ReferansEnum.KrizDurumu.Aktif,
                      contentKey: 'error.comparison.crisisIsPassive',
                    },
                  ]}
                >
                  <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.newRecord">Yeni Kayıt</Translate>
                  </span>
                </GrantedButton>
              )}
            </div>
          </Box>
          {loading || krizCerideLoading || krizCerideUpdating ? (
            <LoadingSpinner />
          ) : (
            <>
              <CustomTabPanel value={secondaryValue} index={0}>
                {krizCerideList &&
                  krizCerideList.map((krizCeride, index) => {
                    const islemZamani = krizCeride.islemZamani;
                    const [tarihStr, saatTam] = islemZamani.split('T');
                    const [yil, ay, gun] = tarihStr.split('-');
                    const tarih = `${gun}.${ay}.${yil}`;
                    const saat = saatTam.slice(0, 5);

                    return (
                      <Card
                        className="cerideCard"
                        key={index}
                        onClick={event => {
                          event.stopPropagation();
                          setSelectedCeride(krizCeride);
                          setIcerikModal(true);
                        }}
                      >
                        <Row className="align-items-center">
                          <Col md={12}>
                            <div className="cerideBefore">
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="toplamCeride">
                                  <span className="cerideVeri">
                                    {calculateStartIndex(pageObject.page, totalItem, pageObject.size) - index}
                                  </span>
                                  <span className="altLine"></span>
                                  <span className="toplamCerideVeri">{totalItem}</span>
                                </div>
                                <div className="align-items-center d-flex">
                                  <div>
                                    {krizCeride.rapordaYerAlacakMi.id === ReferansEnum.EvetHayir.Hayir && (
                                      <img src={`${SERVER_URL}/content/images/icon/cerideEyes.svg`} />
                                    )}
                                  </div>
                                  <div>
                                    {(hasAnyAuthority(['kriz_ceride_edit']) || hasAnyAuthority(['kriz_ceride_delete'])) && (
                                      <IconButton
                                        sx={{
                                          visibility:
                                            isAdmin || (krizCeride.krizKatilimci && krizCeride.krizKatilimci?.id === krizKatilimci?.id)
                                              ? 'visible'
                                              : 'hidden',
                                        }}
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={opens ? 'long-menu' : undefined}
                                        aria-expanded={opens ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={e => {
                                          e.stopPropagation();
                                          setOpenMoreBtn(!openMoreBtn);
                                          setSelectedCeride(krizCeride);
                                          setAnchorEl(e.currentTarget);
                                        }}
                                      >
                                        <MoreVert />
                                      </IconButton>
                                    )}
                                    {openMoreBtn && selectedCeride && selectedCeride?.id === krizCeride.id && (
                                      <Menu
                                        id={`long-menu-${krizCeride.id}`}
                                        MenuListProps={{
                                          'aria-labelledby': 'long-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={opens}
                                        onClose={handleCloseMenu}
                                        PaperProps={{
                                          style: {
                                            maxHeight: ITEM_HEIGHT * 4.5,
                                            width: '20ch',
                                          },
                                        }}
                                      >
                                        <GrantedMenuItem
                                          id={`update-button-${krizCeride.id}`}
                                          grantedAuthorites={['kriz_ceride_edit']}
                                          data-cy="entityEditButton"
                                          onClick={event => {
                                            event.stopPropagation();
                                            setSelectedCeride(krizCeride);
                                            setEditCeride(true);
                                            setIsCerideUpdateModal(true);
                                            setOpenMoreBtn(false);
                                            setAnchorEl(null);
                                          }}
                                          comparison={[
                                            {
                                              value: krizEntity.krizDurumu?.id,
                                              checkValue: ReferansEnum.KrizDurumu.Aktif,
                                              contentKey: 'error.comparison.crisisIsPassive',
                                            },
                                          ]}
                                        >
                                          <span className="d-none d-md-inline">
                                            <Translate contentKey="entity.action.edit">Edit</Translate>
                                          </span>
                                        </GrantedMenuItem>
                                        <GrantedMenuItem
                                          id={`delete-button-${krizCeride.id}`}
                                          grantedAuthorites={['kriz_ceride_delete']}
                                          onClick={event => {
                                            event.stopPropagation();
                                            confirmKrizCerideDelete(krizCeride);
                                            setOpenMoreBtn(false);
                                            setAnchorEl(null);
                                          }}
                                          comparison={[
                                            {
                                              value: krizEntity.krizDurumu?.id,
                                              checkValue: ReferansEnum.KrizDurumu.Aktif,
                                              contentKey: 'error.comparison.crisisIsPassive',
                                            },
                                          ]}
                                          data-cy="entityDeleteButton"
                                        >
                                          <span>
                                            <Translate contentKey="entity.action.delete">Delete</Translate>
                                          </span>
                                        </GrantedMenuItem>
                                      </Menu>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div>
                                {krizCeride?.baslik && <h2 className="cerideBaslik">{krizCeride?.baslik}</h2>}

                                <p className="cerideicerik" style={{ WebkitLineClamp: krizCeride?.baslik ? 2 : 3 }}>
                                  {renderTextWithLineBreaks(krizCeride?.icerik)}
                                </p>
                              </div>
                              <div className="bottomLine"></div>
                              <div className="cerideislemler">
                                <div className="d-flex align-items-center">
                                  <>
                                    {(krizCeride.cerideEkleyen.realId === 1 ||
                                      krizCeride.cerideEkleyen.realId === 2 ||
                                      krizCeride.cerideEkleyen.realId === 3) && (
                                      <div className="cerideKatilimci">
                                        <p>{capitalizeWords(krizCeride.cerideEkleyen?.adi)}</p>
                                      </div>
                                    )}
                                    {krizCeride.cerideEkleyen.realId === 4 && (
                                      <div className="cerideKatilimci">
                                        <p>{capitalizeWords(krizCeride?.cerideEkleyenDiger)}</p>
                                      </div>
                                    )}
                                    {krizCeride.cerideEkleyen.realId === 5 && (
                                      <>
                                        <div className="cerideKatilimci">
                                          <p>
                                            {capitalizeWords(krizCeride?.krizKatilimci?.adi)}{' '}
                                            {capitalizeWords(krizCeride?.krizKatilimci?.soyadi)}
                                          </p>
                                          <span>{capitalizeWords(krizCeride?.krizKatilimci?.unvanAdi)}</span>
                                        </div>
                                      </>
                                    )}
                                    {!(
                                      krizCeride.cerideEkleyen.realId === 1 ||
                                      krizCeride.cerideEkleyen.realId === 2 ||
                                      krizCeride.cerideEkleyen.realId === 3 ||
                                      krizCeride.cerideEkleyen.realId === 4 ||
                                      krizCeride.cerideEkleyen.realId === 5
                                    ) &&
                                      krizCeride?.krizKatilimci && (
                                        <>
                                          <div className="cerideKatilimci">
                                            <p>
                                              {capitalizeWords(krizCeride?.krizKatilimci?.adi)}{' '}
                                              {capitalizeWords(krizCeride?.krizKatilimci?.soyadi)}
                                            </p>
                                            <span>{capitalizeWords(krizCeride?.krizKatilimci?.unvanAdi)}</span>
                                          </div>
                                        </>
                                      )}
                                  </>
                                </div>
                                <div className="islemCerideZamani">
                                  <p style={{ marginTop: '10px', marginBottom: '10px' }}> {tarih}</p>
                                  <span>{saat}</span>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Card>
                    );
                  })}

                <CustomPagination
                  currentPage={pageObject.page + 1}
                  currentPageSize={pageObject.size}
                  totalItems={totalItem}
                  handlePageChange={handlePageChange}
                />
              </CustomTabPanel>
              <CustomTabPanel value={secondaryValue} index={1}>
                {krizCerideList &&
                  krizCerideList.map((krizCeride, index) => {
                    const islemZamani = krizCeride.islemZamani;
                    const [tarihStr, saatTam] = islemZamani.split('T');
                    const [yil, ay, gun] = tarihStr.split('-');
                    const tarih = `${gun}.${ay}.${yil}`;
                    const saat = saatTam.slice(0, 5);
                    return (
                      <Card
                        className="cerideCard"
                        key={index}
                        onClick={event => {
                          event.stopPropagation();
                          setSelectedCeride(krizCeride);
                          setIcerikModal(true);
                        }}
                      >
                        <Row className="align-items-center">
                          <Col md={12}>
                            <div className="cerideBefore">
                              <div className="d-flex align-items-center justify-content-between" style={{ padding: '8px 0px' }}>
                                <div className="toplamCeride">
                                  <span className="cerideVeri">
                                    {calculateStartIndex(pageObject.page, totalItem, pageObject.size) - index}
                                  </span>
                                  <span className="altLine"></span>
                                  <span className="toplamCerideVeri">{totalItem}</span>
                                </div>
                                {krizCeride.islemKodu.realId === 1 &&
                                  krizCeride?.krizKatilimci?.sicil &&
                                  krizCeride?.krizKatilimci?.sicil === sicilNo && (
                                    <>
                                      <div className="align-items-center d-flex">
                                        <div>
                                          {krizCeride.rapordaYerAlacakMi.id === ReferansEnum.EvetHayir.Hayir && (
                                            <img src={`${SERVER_URL}/content/images/icon/cerideEyes.svg`} />
                                          )}
                                        </div>
                                        <div>
                                          {(hasAnyAuthority(['kriz_ceride_edit']) || hasAnyAuthority(['kriz_ceride_delete'])) && (
                                            <IconButton
                                              aria-label="more"
                                              id="long-button"
                                              aria-controls={opens ? 'long-menu' : undefined}
                                              aria-expanded={opens ? 'true' : undefined}
                                              aria-haspopup="true"
                                              onClick={e => {
                                                e.stopPropagation();
                                                setOpenMoreBtn(!openMoreBtn);
                                                setSelectedCeride(krizCeride);
                                                setAnchorEl(e.currentTarget);
                                              }}
                                            >
                                              <MoreVert />
                                            </IconButton>
                                          )}
                                          {openMoreBtn && selectedCeride && selectedCeride?.id === krizCeride.id && (
                                            <Menu
                                              id={`long-menu-${krizCeride.id}`}
                                              MenuListProps={{
                                                'aria-labelledby': 'long-button',
                                              }}
                                              anchorEl={anchorEl}
                                              open={opens}
                                              onClose={handleCloseMenu}
                                              PaperProps={{
                                                style: {
                                                  maxHeight: ITEM_HEIGHT * 4.5,
                                                  width: '20ch',
                                                },
                                              }}
                                            >
                                              <GrantedMenuItem
                                                id={`update-button-${krizCeride.id}`}
                                                grantedAuthorites={['kriz_ceride_edit']}
                                                style={{ marginLeft: 2 }}
                                                data-cy="entityEditButton"
                                                onClick={event => {
                                                  event.stopPropagation();
                                                  setSelectedCeride(krizCeride);
                                                  setEditCeride(true);
                                                  setIsCerideUpdateModal(true);
                                                  setOpenMoreBtn(false);
                                                  setAnchorEl(null);
                                                }}
                                                comparison={[
                                                  {
                                                    value: krizEntity.krizDurumu?.id,
                                                    checkValue: ReferansEnum.KrizDurumu.Aktif,
                                                    contentKey: 'error.comparison.crisisIsPassive',
                                                  },
                                                ]}
                                              >
                                                <span className="d-none d-md-inline">
                                                  <Translate contentKey="entity.action.edit">Edit</Translate>
                                                </span>
                                              </GrantedMenuItem>
                                              <GrantedMenuItem
                                                id={`delete-button-${krizCeride.id}`}
                                                grantedAuthorites={['kriz_ceride_delete']}
                                                onClick={event => {
                                                  event.stopPropagation();
                                                  confirmKrizCerideDelete(krizCeride);
                                                  setOpenMoreBtn(false);
                                                  setAnchorEl(null);
                                                }}
                                                comparison={[
                                                  {
                                                    value: krizEntity.krizDurumu?.id,
                                                    checkValue: ReferansEnum.KrizDurumu.Aktif,
                                                    contentKey: 'error.comparison.crisisIsPassive',
                                                  },
                                                ]}
                                                data-cy="entityDeleteButton"
                                              >
                                                <span>
                                                  <Translate contentKey="entity.action.delete">Delete</Translate>
                                                </span>
                                              </GrantedMenuItem>
                                            </Menu>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  )}
                              </div>
                              <div>
                                {krizCeride?.baslik && <h2 className="cerideBaslik">{krizCeride?.baslik}</h2>}
                                <p className="cerideicerik" style={{ WebkitLineClamp: krizCeride?.baslik ? 2 : 3 }}>
                                  {renderTextWithLineBreaks(krizCeride?.icerik)}
                                </p>
                              </div>
                              <div className="bottomLine"></div>
                              <div className="cerideislemler">
                                <div className="d-flex align-items-center">
                                  <>
                                    {(krizCeride.cerideEkleyen.realId === 1 ||
                                      krizCeride.cerideEkleyen.realId === 2 ||
                                      krizCeride.cerideEkleyen.realId === 3) && (
                                      <div className="cerideKatilimci">
                                        <p>{capitalizeWords(krizCeride?.cerideEkleyen?.adi)}</p>
                                      </div>
                                    )}
                                    {krizCeride.cerideEkleyen.realId === 4 && (
                                      <div className="cerideKatilimci">
                                        <p>{capitalizeWords(krizCeride?.cerideEkleyenDiger)}</p>
                                      </div>
                                    )}
                                    {krizCeride.cerideEkleyen.realId === 5 && (
                                      <div className="cerideKatilimci">
                                        <p>
                                          {capitalizeWords(krizCeride?.krizKatilimci?.adi)}{' '}
                                          {capitalizeWords(krizCeride?.krizKatilimci?.soyadi)}{' '}
                                        </p>
                                        <span>{capitalizeWords(krizCeride?.krizKatilimci?.unvanAdi)}</span>
                                      </div>
                                    )}
                                    {!(
                                      krizCeride.cerideEkleyen.realId === 1 ||
                                      krizCeride.cerideEkleyen.realId === 2 ||
                                      krizCeride.cerideEkleyen.realId === 3 ||
                                      krizCeride.cerideEkleyen.realId === 4 ||
                                      krizCeride.cerideEkleyen.realId === 5
                                    ) &&
                                      krizCeride.krizKatilimci && (
                                        <>
                                          <div className="cerideKatilimci">
                                            <p>
                                              {capitalizeWords(krizCeride?.krizKatilimci?.adi)}{' '}
                                              {capitalizeWords(krizCeride?.krizKatilimci?.soyadi)}
                                            </p>
                                            <span>{capitalizeWords(krizCeride?.krizKatilimci?.unvanAdi)}</span>
                                          </div>
                                        </>
                                      )}
                                  </>
                                </div>

                                <div className="islemCerideZamani">
                                  <p style={{ marginTop: '10px', marginBottom: '10px' }}> {tarih}</p>
                                  <span>{saat}</span>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Card>
                    );
                  })}
                <CustomPagination
                  currentPage={pageObject.page + 1}
                  currentPageSize={pageObject.size}
                  totalItems={totalItem}
                  handlePageChange={handlePageChange}
                />
              </CustomTabPanel>
            </>
          )}
        </>
      </>
    )
  );
};

export default KrizYonetimMerkezi;
