import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { TextFormat, Translate, translate } from 'app/component/jhipster';
import InfoTable from 'app/entities/infoTable';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities } from './kriz-ozet-rapor.reducer';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import KrizOzetRaporUpdate from './kriz-ozet-rapor-update';
import { getEntities as getRefEkDosyaTipis } from 'app/entities/ref-ek-dosya-tipi/ref-ek-dosya-tipi.reducer';
import TableNameEnum from 'app/constants/TableNameEnum';
import {
  uploadEntity as uploadEkDosyaEntity,
  getEntities as getFilterKrizOzetRaporuEkDosyaEntities,
  deleteEntity as deleteEkDosyaEntity,
} from 'app/entities/ek-dosya/ek-dosya.reducer';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { deleteEntityConfirm, isObjectEmpty, saveEntityConfirm } from 'app/shared/util/confirm-utils';
import axios from 'axios';
import { RefEkDosyaTipiCodeEnum } from 'app/constants/RefEkDosyaTipiCodeEnum';
import { GetBase64PromiseString } from 'app/shared/util/util';
import { toast } from 'react-toastify';
import { KrizMain } from 'app/component/KrizMain';
import { getEntity as getKrizEntity } from '../kriz/kriz.reducer';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import GrantedButton from 'app/component/GrantedButton';
import { TableBody, TableCell, TableContainer, TableHead, TableRow, Table } from '@mui/material';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import SortIcon from 'app/component/SortIcon';
import { FormItem } from 'app/component/FormItem';

export const KrizOzetRapor = (props: RouteComponentProps<{ id: string }>) => {
  const { match } = props;
  const dispatch = useAppDispatch();
  const kriz = useAppSelector(state => state.kriz.entity);
  const isDrawer = useAppSelector(state => state.krizChat.isDrawer);
  const krizOzetRaporus = useAppSelector(state => state.krizOzetRaporu.entities);
  const loading = useAppSelector(state => state.krizOzetRaporu.loading);
  const updating = useAppSelector(state => state.krizOzetRaporu.updating);
  const [isRaporModal, setIsRaporModal] = useState(false);
  const [isRaporEkDosyaModal, setIsRaporEkDosyaModal] = useState(false);
  const [selectedRapor, setSelectedRapor] = useState(null);
  const krizLoading = useAppSelector(state => state.kriz.loading);
  const refEkDosyaTipis = useAppSelector(state => state.refEkDosyaTipi.entities);
  const krizOzetRaporEkDosyaList = useAppSelector(state => state.ekDosya.entities);
  const krizOzetRaporEkDosyaLoading = useAppSelector(state => state.ekDosya.loading);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null);
  const [ekDosyaLoading, setEkDosyaLoading] = useState(false);
  const [fileNames, setFileNames] = useState({});
  const [ekDosyaAciklama, setEkDosyaAciklama] = useState('');
  const [pageObjectEkDosya, setPageObjectEkDosya] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  useEffect(() => {
    dispatch(getKrizEntity(match.params.id));
    dispatch(getEntities({ krizId: match.params.id }));
    dispatch(getRefEkDosyaTipis({ size: 2000, page: 0, sort: 'id,desc', hasParent: true }));
  }, []);

  useEffect(() => {
    if (!krizLoading && krizOzetRaporus.length > 0) {
      GetKrizOzetRaporuEkDosyaEntities();
    }
  }, [krizLoading, krizOzetRaporus, pageObjectEkDosya.sort]);

  const sortEkDosya = p => () => {
    const orderBy = pageObjectEkDosya.sort.split(',')[1];
    const newSortOrder = orderBy === ASC ? DESC : ASC;
    setPageObjectEkDosya({ ...pageObjectEkDosya, sort: `${p},${newSortOrder}` });
  };

  const handleCloseModal = () => {
    setIsRaporModal(false);
    setSelectedRapor(null);
  };

  const handleCloseEkDosyaModal = () => {
    setSelectedFiles([]);
    setFileNames({});
    setEkDosyaAciklama('');
    setIsRaporEkDosyaModal(false);
  };

  const handleOpenEkDosyaModal = () => {
    setIsRaporEkDosyaModal(true);
  };

  const handleFileChange = e => {
    const files = e.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleDrop = event => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const getFileNameAndExtension = filename => {
    const dotIndex = filename.lastIndexOf('.');
    if (dotIndex === -1) {
      return { name: filename, extension: '' };
    }
    return { name: filename.substring(0, dotIndex), extension: filename.substring(dotIndex) };
  };

  const handleFileNameChange = (index, newName) => {
    setFileNames(prevNames => ({
      ...prevNames,
      [index]: newName,
    }));

    const updatedFiles = selectedFiles.map((file, fileIndex) => {
      if (fileIndex === index) {
        const { extension } = getFileNameAndExtension(file.name);
        return new File([file], `${newName}${extension}`, { type: file.type });
      }
      return file;
    });

    setSelectedFiles(updatedFiles);
  };

  const handleRemoveFile = index => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };

  const handleDownloadSavedFile = async file => {
    setEkDosyaLoading(true);
    try {
      const response = await axios.get(`api/ek-dosyas/download/${file.id}`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${file.dosyaAdi}`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Dosya indirme hatası:', error);
    }
    setEkDosyaLoading(false);
  };

  const GetKrizOzetRaporuEkDosyaEntities = () => {
    dispatch(
      getFilterKrizOzetRaporuEkDosyaEntities({
        ...pageObjectEkDosya,
        query: `ilgiliTabloKayitId.equals=${kriz && kriz.realId}&ilgiliTablo.equals=${TableNameEnum.Kriz}&ekDosyaTipiCode.in=${
          RefEkDosyaTipiCodeEnum.KrizOzetDefaultRaporu
        },${RefEkDosyaTipiCodeEnum.KrizOzetHariciRaporu}`,
        page: 0,
        size: 999,
      })
    );
  };

  const handleRemoveSavedFile = async file => {
    const isDel = await deleteEntityConfirm(file.id, 'ekDosya');

    if (isDel) {
      setEkDosyaLoading(true);
      await dispatch(deleteEkDosyaEntity(file.id));
      GetKrizOzetRaporuEkDosyaEntities();
      setEkDosyaLoading(false);
    }
  };

  const handleEkDosyaAciklamaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEkDosyaAciklama(event.target.value);
  };

  const saveEntity = async values => {
    values.preventDefault();

    const isSave = await saveEntityConfirm();
    setEkDosyaLoading(true);

    if (isSave) {
      const formDataObject = {};
      for (const field of values.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const dosya = {
        ilgiliTablo: TableNameEnum.Kriz,
        ilgiliTabloKayitId: kriz && kriz.realId,
        onaylandiMi: ReferansEnum.EvetHayir.Hayir,
        ekDosyaTipi: refEkDosyaTipis.find(res => res.code === RefEkDosyaTipiCodeEnum.KrizOzetHariciRaporu),
      };

      const fileInfoArray = await GetBase64PromiseString(selectedFiles);

      const entity = {
        ...dosya,
        fileList: fileInfoArray.map((file, index) => {
          const { extension } = getFileNameAndExtension(file.fileName);
          const updatedName = fileNames[index] || getFileNameAndExtension(file.fileName).name;
          return {
            ...file,
            fileName: `${updatedName}${extension}`,
            aciklama: ekDosyaAciklama,
          };
        }),
      };
      await dispatch(uploadEkDosyaEntity(entity));

      GetKrizOzetRaporuEkDosyaEntities();
    }

    handleCloseEkDosyaModal();
    setEkDosyaLoading(false);
  };

  const uploadKrizOzetRaporEkDosya = () => {
    return (
      <Modal isOpen={isRaporEkDosyaModal} toggle={handleCloseEkDosyaModal} className="modal-style" centered>
        <ModalHeader toggle={handleCloseEkDosyaModal}>
          <Translate contentKey="tk24AdysApp.krizOzetRaporu.dosyaYukle" />
        </ModalHeader>
        <Form onSubmit={saveEntity}>
          <ModalBody style={{ maxHeight: '550px', overflowY: 'auto' }}>
            <FormItem
              label={translate('tk24AdysApp.ekDosya.ekDosyaAciklama')}
              id="ek-dosya-aciklama"
              name="aciklama"
              defaultValue={ekDosyaAciklama}
              onChange={handleEkDosyaAciklamaChange}
              type="textarea"
              validation={{
                maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
              }}
            />

            <div className="modal-file-area">
              <div className="modal-dashed-file-area" onDrop={handleDrop} onDragOver={e => e.preventDefault()}>
                <img width={16} height={20} style={{ marginBottom: '20px' }} src={`${SERVER_URL}/content/images/icon/downloadIcon.svg`} />
                <span className="modal-file-text">
                  <span className="modal-upload-file-text" onClick={() => fileInputRef.current.click()}>
                    <Translate contentKey="tk24AdysApp.ekDosya.yukle" />
                  </span>
                  <span style={{ color: 'rgba(73, 88, 106, 1)' }}>
                    {' '}
                    <Translate contentKey="tk24AdysApp.ekDosya.surukle" />
                  </span>
                  <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} multiple />
                </span>
              </div>
              <div className="modal-file-list-area">
                {selectedFiles.length > 0 &&
                  selectedFiles.map((file, index) => {
                    const { name, extension } = getFileNameAndExtension(file.name);
                    return (
                      <div key={file.id} className="modal-file-list-row">
                        <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', padding: '0px', width: '50px' }}>
                          <img width={16} height={20} src={`${SERVER_URL}/content/images/icon/downloadIcon.svg`} />
                        </div>
                        <div style={{ width: '100%' }}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'start',
                              justifyContent: 'center',
                              margin: 'auto',
                            }}
                          >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <input
                                type="text"
                                value={fileNames[index] || name}
                                onChange={e => handleFileNameChange(index, e.target.value)}
                                className="form-control"
                                style={{ width: '300px' }}
                                maxLength={950}
                              />
                              <span className="ml-2">{extension}</span>
                            </div>
                            <span className="modal-file-size">{`${(file.size / 1024).toFixed(2)}kb`}</span>
                          </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', padding: '0px', width: '50px' }}>
                          <Button className="delete-file-button" onClick={() => handleRemoveFile(index)}>
                            <img width={12} height={15} src={`${SERVER_URL}/content/images/icon/deleteFileIcon.svg`} />
                          </Button>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="modal-footer">
            <Button className="cancel-button" onClick={handleCloseEkDosyaModal} replace>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button
              className="save-button"
              disabled={selectedFiles.length < 1}
              id="jhi-confirm-delete-refAdtForm"
              type="submit"
              data-cy="entityConfirmButton"
            >
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  };

  const ozetRaporModal = () => {
    return (
      <KrizOzetRaporUpdate
        krizId={match.params.id}
        krizOzetRapor={selectedRapor}
        isOpen={isRaporModal}
        handleCloseModal={handleCloseModal}
      />
    );
  };

  const createOzetRapor = async () => {
    setEkDosyaLoading(true);

    await axios
      .post(`api/report/kriz-ozet-raporu/${match.params.id}`)
      .then(() => {
        toast.success(translate('tk24AdysApp.krizOzetRaporu.generateReport'));
        GetKrizOzetRaporuEkDosyaEntities();
      })
      .catch(() => {
        toast.error(translate('tk24AdysApp.krizOzetRaporu.error.generateReport'));
      });
    setEkDosyaLoading(false);
  };

  return (
    !isObjectEmpty(kriz) && (
      <KrizMain isDrawer={isDrawer}>
        {(loading || updating || ekDosyaLoading || krizOzetRaporEkDosyaLoading) && <LoadingSpinner />}
        {isRaporModal && ozetRaporModal()}
        {isRaporEkDosyaModal && uploadKrizOzetRaporEkDosya()}

        <div style={{ marginBottom: 5 }}>
          <InfoTable id={match.params.id} titleContentKey={'tk24AdysApp.krizDetay.acilDurumOzet'} />
        </div>

        {krizOzetRaporus?.length === 0 && (
          <div style={{ justifyContent: 'right', display: 'flex', gap: '10px', paddingRight: '24px' }}>
            <GrantedButton
              id="create-button"
              grantedAuthorites={['kriz_ozet_raporu_edit']}
              comparison={[
                {
                  value: kriz.krizDurumu?.id,
                  checkValue: ReferansEnum.KrizDurumu.Aktif,
                  contentKey: 'error.comparison.crisisIsPassive',
                },
              ]}
              style={{ marginLeft: 15 }}
              onClick={() => {
                setIsRaporModal(true);
              }}
              className="create-button"
              size="sm"
              data-cy="entityCreateButton"
            >
              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.addInformation">Add Information</Translate>
              </span>
            </GrantedButton>
          </div>
        )}

        <div className="content-area kriz-ozet-rapor">
          {krizOzetRaporus &&
            krizOzetRaporus.length > 0 &&
            krizOzetRaporus.map(rapor => (
              <>
                <Row className="align-items-center">
                  <Col sm={10}>
                    <p className="modal-content-title">
                      <Translate contentKey="tk24AdysApp.krizOzetRaporu.header" />
                    </p>
                  </Col>
                  <Col sm={2} style={{ textAlign: 'right' }}>
                    <GrantedButton
                      id={`update-button-${rapor.id}`}
                      grantedAuthorites={['kriz_ozet_raporu_edit']}
                      comparison={[
                        {
                          value: kriz.krizDurumu?.id,
                          checkValue: ReferansEnum.KrizDurumu.Aktif,
                          contentKey: 'error.comparison.crisisIsPassive',
                        },
                      ]}
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', float: 'right' }}
                      onClick={() => {
                        setSelectedRapor(rapor);
                        setIsRaporModal(true);
                      }}
                      className="edit-button"
                      size="sm"
                      data-cy="entityEditButton"
                    >
                      <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.edit">Edit</Translate>
                      </span>
                    </GrantedButton>
                  </Col>
                </Row>
                <hr style={{ marginBottom: 10, marginTop: 10, borderBottom: '1px solid #dadee3' }} />
                <Row className="align-items-center">
                  <Col sm={3}>
                    <p className="modal-content-title">
                      <Translate contentKey="tk24AdysApp.krizOzetRaporu.hasarBilgisi" />:
                    </p>
                  </Col>
                  <Col sm={9}>
                    <p style={{ margin: '0px 0px 0px 20px' }}> {rapor.hasarBilgisi}</p>
                  </Col>
                </Row>
                <hr style={{ marginBottom: 10, marginTop: 10, borderBottom: '1px solid #dadee3' }} />

                <p className="modal-content-title">
                  <Translate contentKey="tk24AdysApp.krizOzetRaporu.depIletisim" />
                </p>

                <Row style={{ margin: '5px 0px 5px 20px' }}>
                  <Col sm={3}>
                    <p className="modal-content-title">
                      <Translate contentKey="tk24AdysApp.krizOzetRaporu.depIletisimAdSoyad" />:
                    </p>
                  </Col>
                  <Col sm={9}>
                    <p> {rapor.depIletisimAdSoyad}</p>
                  </Col>
                </Row>
                <Row style={{ margin: '5px 0px 5px 20px' }}>
                  <Col sm={3}>
                    <p className="modal-content-title">
                      <Translate contentKey="tk24AdysApp.krizOzetRaporu.depIletisimTelNo" />:
                    </p>
                  </Col>
                  <Col sm={9}>
                    <p> {rapor.depIletisimTelNo}</p>
                  </Col>
                </Row>
                <hr style={{ marginBottom: 10, marginTop: 10, borderBottom: '1px solid #dadee3' }} />

                <p className="modal-content-title">
                  <Translate contentKey="tk24AdysApp.krizOzetRaporu.arrIletisim" />
                </p>

                <Row style={{ margin: '5px 0px 5px 20px' }}>
                  <Col sm={3}>
                    <p className="modal-content-title">
                      <Translate contentKey="tk24AdysApp.krizOzetRaporu.arrIletisimAdSoyad" />:
                    </p>
                  </Col>
                  <Col sm={9}>
                    <p> {rapor.arrIletisimAdSoyad}</p>
                  </Col>
                </Row>
                <Row style={{ margin: '5px 0px 5px 20px' }}>
                  <Col sm={3}>
                    <p className="modal-content-title">
                      <Translate contentKey="tk24AdysApp.krizOzetRaporu.arrIletisimTelNo" />:
                    </p>
                  </Col>
                  <Col sm={9}>
                    <p> {rapor.arrIletisimTelNo}</p>
                  </Col>
                </Row>
                <hr style={{ marginBottom: 10, marginTop: 10, borderBottom: '1px solid #dadee3' }} />

                <p className="modal-content-title">
                  <Translate contentKey="tk24AdysApp.krizOzetRaporu.enYakinIstIletisim" />
                </p>

                <Row style={{ margin: '5px 0px 5px 20px' }}>
                  <Col sm={3}>
                    <p className="modal-content-title">
                      <Translate contentKey="tk24AdysApp.krizOzetRaporu.enYakinIstIletisimAdSoyad" />:
                    </p>
                  </Col>
                  <Col sm={9}>
                    <p> {rapor.enYakinIstIletisimAdSoyad}</p>
                  </Col>
                </Row>
                <Row style={{ margin: '5px 0px 5px 20px' }}>
                  <Col sm={3}>
                    <p className="modal-content-title">
                      <Translate contentKey="tk24AdysApp.krizOzetRaporu.enYakinIstIletisimTelNo" />:
                    </p>
                  </Col>
                  <Col sm={9}>
                    <p> {rapor.enYakinIstIletisimTelNo}</p>
                  </Col>
                </Row>
                <hr style={{ marginBottom: 10, marginTop: 10, borderBottom: '1px solid #dadee3' }} />

                <div className="d-flex align-items-center justify-content-between">
                  <p className="modal-content-title">
                    <Translate contentKey="tk24AdysApp.krizOzetRaporu.uretilenRaporlar" />
                  </p>
                  <div className="btn-group flex-btn-group-container mb-3">
                    {krizOzetRaporus?.length > 0 && (
                      <div className="mb-2" style={{ justifyContent: 'right', display: 'flex', gap: '10px' }}>
                        <GrantedButton
                          id="create-button"
                          grantedAuthorites={['kriz_ozet_raporu_edit']}
                          comparison={[
                            {
                              value: kriz.krizDurumu?.id,
                              checkValue: ReferansEnum.KrizDurumu.Aktif,
                              contentKey: 'error.comparison.crisisIsPassive',
                            },
                          ]}
                          style={{ marginRight: 10 }}
                          onClick={() => {
                            createOzetRapor();
                          }}
                          className="create-button"
                          size="sm"
                          data-cy="entityCreateButton"
                        >
                          <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.generateSummaryReport">Generate Summary Report</Translate>
                          </span>
                        </GrantedButton>
                      </div>
                    )}
                    <GrantedButton
                      id={`create-button`}
                      grantedAuthorites={['ref_ek_dosya_tipi_edit']}
                      onClick={handleOpenEkDosyaModal}
                      comparison={[
                        {
                          value: kriz.krizDurumu?.id,
                          checkValue: ReferansEnum.KrizDurumu.Aktif,
                          contentKey: 'error.comparison.crisisIsPassive',
                        },
                      ]}
                      className="create-button"
                      data-cy="entityCreateButton"
                    >
                      <Translate contentKey="entity.action.uploadExternalReport">Upload External Report</Translate>
                    </GrantedButton>
                  </div>
                </div>
                <Row>
                  <Col sm={12}>
                    <div className="table-responsive" style={{ marginTop: 5 }}>
                      <TableContainer className="content-table" style={{ border: 'none' }}>
                        {krizOzetRaporEkDosyaList && krizOzetRaporEkDosyaList.length > 0 ? (
                          <Table aria-label="a dense table">
                            <TableHead className="content-table-title-area">
                              <TableRow className="table-head-row">
                                <TableCell id={'global-sort'} className="table-title-cell" align="left" onClick={sortEkDosya('dosyaAdi')}>
                                  <div className="tableHeaderContainer">
                                    <Translate contentKey="tk24AdysApp.ekDosya.dosyaAdi">Dosya Adi</Translate>
                                    <SortIcon column="dosyaAdi" activeSortColumn={pageObjectEkDosya.sort} />
                                  </div>
                                </TableCell>
                                <TableCell className="table-title-cell" align="left" onClick={sortEkDosya('ekDosyaTipiId')}>
                                  <div className="tableHeaderContainer">
                                    <Translate contentKey="tk24AdysApp.ekDosya.ekDosyaTipiTitle">Ek Dosya Tipi</Translate>
                                    <SortIcon column="ekDosyaTipiId" activeSortColumn={pageObjectEkDosya.sort} />
                                  </div>
                                </TableCell>
                                <TableCell className="table-title-cell hand" align="left" onClick={sortEkDosya('aciklama')}>
                                  <Translate contentKey="tk24AdysApp.ekDosya.aciklamaTitle">aciklama</Translate>
                                  <SortIcon column="aciklama" activeSortColumn={pageObjectEkDosya.sort} />
                                </TableCell>
                                <TableCell className="table-title-cell hand" align="left" onClick={sortEkDosya('dilId')}>
                                  <Translate contentKey="tk24AdysApp.ekDosya.fileLanguageTitle">dil</Translate>
                                  <SortIcon column="dilId" activeSortColumn={pageObjectEkDosya.sort} />
                                </TableCell>
                                <TableCell className="accordion-table-title-cell" align="left" onClick={sortEkDosya('fileExtention')}>
                                  <div className="tableHeaderContainer">
                                    <Translate contentKey="tk24AdysApp.ekDosya.fileExtentionTitle">fileExtention</Translate>&nbsp;
                                    <SortIcon column="fileExtention" activeSortColumn={pageObjectEkDosya.sort} />
                                  </div>
                                </TableCell>
                                <TableCell className="table-title-cell hand" align="left" onClick={sortEkDosya('ekleyenKullanici.adi')}>
                                  <Translate contentKey="tk24AdysApp.ekDosya.ekleyenKullanici">ekleyenKullanici</Translate>
                                  <SortIcon column="ekleyenKullanici.adi" activeSortColumn={pageObjectEkDosya.sort} />
                                </TableCell>
                                <TableCell className="table-title-cell hand" align="left" onClick={sortEkDosya('cDate')}>
                                  <Translate contentKey="tk24AdysApp.ekDosya.cDateTitle">cDate</Translate>
                                  <SortIcon column="cDate" activeSortColumn={pageObjectEkDosya.sort} />
                                </TableCell>
                                <TableCell align="right" className="sticky-right-header krizOzetRaporu"></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {krizOzetRaporEkDosyaList.map(file => (
                                <TableRow key={file.id} className="table-cell-row">
                                  <TableCell className="table-content-cell" component="th" scope="row">
                                    {file.dosyaAdi}
                                  </TableCell>
                                  <TableCell className="table-content-cell" component="th" scope="row">
                                    {file.ekDosyaTipi && file.ekDosyaTipi.adi}
                                  </TableCell>
                                  <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                    {file?.aciklama}
                                  </TableCell>
                                  <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                    {file?.dil?.adi}
                                  </TableCell>
                                  <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                    {file?.fileExtention}
                                  </TableCell>
                                  <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                    {file.ekleyenKullanici?.adi} {file.ekleyenKullanici?.soyadi}
                                  </TableCell>
                                  <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                    <TextFormat type="date" value={file.cDate} format={APP_DATE_FORMAT} />
                                  </TableCell>

                                  <TableCell className="table-content-cell sticky-right" align="right">
                                    <div className="btn-group flex-btn-group-container">
                                      <GrantedButton
                                        grantedAuthorites={['ref_ek_dosya_tipi_read']}
                                        style={{ marginLeft: 5 }}
                                        onClick={() => handleDownloadSavedFile(file)}
                                        className="ek-dosya-download-button"
                                      >
                                        <img src={`${SERVER_URL}/content/images/icon/faArrowDownToLine.svg`} />
                                        <span className="d-none d-md-inline">
                                          <Translate contentKey="entity.action.download">İndir</Translate>
                                        </span>
                                      </GrantedButton>
                                      <GrantedButton
                                        id={`delete-button-${file.id}`}
                                        grantedAuthorites={['ref_ek_dosya_tipi_delete']}
                                        style={{ marginLeft: 15 }}
                                        onClick={() => handleRemoveSavedFile(file)}
                                        comparison={[
                                          {
                                            value: kriz.krizDurumu?.id,
                                            checkValue: ReferansEnum.KrizDurumu.Aktif,
                                            contentKey: 'error.comparison.crisisIsPassive',
                                          },
                                        ]}
                                        className="delete-button"
                                        data-cy="entityDeleteButton"
                                      >
                                        <Translate contentKey="entity.action.delete">Sil</Translate>
                                      </GrantedButton>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        ) : (
                          !loading &&
                          !updating && (
                            <div>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'end',
                                  alignItems: 'center',
                                  margin: '15px 0px',
                                }}
                              ></div>

                              <div className="alert alert-warning">
                                <Translate contentKey="tk24AdysApp.ekDosya.home.notFound">No ekDosya found</Translate>
                              </div>
                            </div>
                          )
                        )}
                      </TableContainer>
                    </div>
                  </Col>
                </Row>
              </>
            ))}
        </div>
      </KrizMain>
    )
  );
};

export default KrizOzetRapor;
